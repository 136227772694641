import { StyleSheet, ViewStyle } from 'react-native'
import { THEME } from '../../constants'

export const navigationMenuStyle = (
  useMobileDesign: boolean,
  menuIsOpen: boolean
): {
  navigationMenuContainer: ViewStyle
  openCloseMenuButton: ViewStyle
  logoContainer: ViewStyle
} =>
  StyleSheet.create({
    navigationMenuContainer: {
      alignItems: 'flex-end',
      height: menuIsOpen ? '100%' : '10%',
      backgroundColor:
        menuIsOpen && useMobileDesign ? THEME.color.background : 'transparent',
      width: menuIsOpen && useMobileDesign ? '100%' : 'fit-content',
      paddingBottom: THEME.spacing.marginMedium
    },
    logoContainer: {
      position: 'absolute',
      top: 0,
      left: THEME.spacing.marginMedium * 2
    },
    openCloseMenuButton: {
      marginBottom: THEME.spacing.marginSmall,
      display: 'flex'
    }
  })
