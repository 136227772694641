import React from 'react'
import { Text } from 'react-native'
import { textStyle } from '../../../themes/global-styles.style'
import { Anchor } from '../../../util/anchor'
import { NavigationAnchor } from '../../../util/navigation-anchor'
import { ArticlePage } from '../article-page'

const termsOfServiceCopy = (
  <>
    <Text style={textStyle.regularText}>
      <Text style={textStyle.boldText}>Last Updated:</Text> May 31, 2022
      {'\n\n'}Welcome to Walkthrough, your personal financial coach and
      cheerleader! By accessing or using the website located at{' '}
      <Anchor
        href="https://www.walkthrough.co"
        text="https://www.walkthrough.co"
      />{' '}
      (the “Site”) and related mobile applications (the “Apps”) provided by The
      Company, Inc., doing business as Walkthrough, and our affiliates and
      subsidiaries (collectively, “Walkthrough”, “we”, “our”, and “us”), you
      agree to be bound by the terms and conditions contained in these Terms of
      Use (“Terms”) and all other terms incorporated by reference. Please read
      on to learn the rules and restrictions that govern your use of our
      website(s), products, services and applications (the "Service"). If you
      have any questions, comments, or concerns regarding these terms or the
      Service, please contact us at{' '}
      <Anchor
        href="mailto:support@walkthrough.co"
        text="support@walkthrough.co"
      />
      .{'\n\n'}These Terms are a binding contract between you and Walkthrough.
      You must agree to and accept all of the Terms, or you don't have the right
      to use the Service. Your using the Service in any way means that you agree
      to all of these Terms, and these Terms will remain in effect while you use
      the Service. These Terms include the provisions in this document, as well
      as those in the{' '}
      <NavigationAnchor
        text="Privacy Policy"
        handlePress={(nav) => {
          nav.navigate('PrivacyPolicyScreen')
        }}
      />
      . THESE TERMS CAN BE CHANGED, MODIFIED, SUPPLEMENTED, AND/OR UPDATED BY
      WALKTHROUGH AT ANY TIME. WE WILL TAKE REASONABLE EFFORTS TO PROVIDE YOU
      WITH PRIOR NOTICE OF ANY MATERIAL CHANGES TO THESE TERMS. YOUR CONTINUED
      USE OF THE SERVICE AFTER THE MODIFICATION OF THESE TERMS MEANS THAT YOU
      ACCEPT ALL SUCH CHANGES. YOU SHOULD CONSULT THESE TERMS EACH TIME YOU
      ACCESS THE SERVICE TO VIEW ANY CHANGES. THESE TERMS WERE LAST MODIFIED AS
      OF THE DATE INDICATED ABOVE.
      {'\n\n'}
      <Text style={textStyle.boldText}>1. Use of the Service.</Text>
      {'\n'}
      <Text style={textStyle.boldText}>A. Service.</Text> The “Service” includes
      Walkthrough's website located at{' '}
      <Anchor
        href="https://www.walkthrough.co"
        text="https://www.walkthrough.co"
      />{' '}
      (“Site”), and Walkthrough's related mobile applications (the “Apps”) as
      each may be updated, relocated, or otherwise modified from time to time,
      all content and services Walkthrough makes available through the Site or
      Apps, and all intellectual property contained therein. The Service is a
      personal finance information management tool that allows you to track and
      organize your finances with relevant financial insights, including through
      the aggregation of your financial account information (“Account Data”)
      from financial institutions and other third-party data sources you select
      and with whom you have a contractual relationship (“Third-Party Data
      Sources”). Any person who accesses and/or uses the Service, whether on his
      or her own behalf or on behalf of any third party, will be referred to
      herein as a “Walkthrough Member.”
      {'\n'}
      <Text style={textStyle.boldText}>B. Service Restrictions.</Text> Your
      Walkthrough account is personal to you. You may not use the Service for
      commercial purposes. To use the Service, you must have access to the
      Internet.
      {'\n'}
      <Text style={textStyle.boldText}>C. License.</Text> Subject to your
      compliance with these Terms, Walkthrough hereby grants you a limited,
      revocable, non-exclusive, non-transferable license to download the Apps
      and access and use the Service, solely for your personal use and not for
      resale.
      {'\n'}
      <Text style={textStyle.boldText}>D. Authorization.</Text> By accessing and
      using the Service, you authorize and direct Walkthrough, as your agent and
      on your behalf, to electronically retrieve your Account Data from
      Third-Party Data Sources. Subject to our{' '}
      <NavigationAnchor
        text="Privacy Policy"
        handlePress={(nav) => {
          nav.navigate('PrivacyPolicyScreen')
        }}
      />
      , Walkthrough may work with one or more third-party financial service
      technology providers to access and retrieve your Account Data. FOR
      PURPOSES OF THIS AGREEMENT AND SOLELY TO OBTAIN AND PROVIDE THE ACCOUNT
      DATA TO YOU AS PART OF THE SERVICE, YOU GRANT WALKTHROUGH A LIMITED POWER
      OF ATTORNEY, AND APPOINT WALKTHROUGH AS YOUR ATTORNEY-IN-FACT AND AGENT,
      WITH FULL POWER OF SUBSTITUTION AND RE-SUBSTITUTION, FOR YOU AND IN YOUR
      NAME, PLACE AND STEAD, IN ALL CAPACITIES, TO ACCESS SITES, SERVERS, OR
      DOCUMENTS RELATED TO OR MAINTAINED BY THE THIRD-PARTY DATA SOURCES,
      RETRIEVE INFORMATION, AND USE YOUR INFORMATION WITH THE FULL POWER AND
      AUTHORITY TO PERFORM EVERY ACT AND THING REQUISITE AND NECESSARY TO BE
      DONE IN CONNECTION WITH SUCH ACTIVITIES, AS YOU COULD DO IN PERSON. YOU
      ACKNOWLEDGE AND AGREE THAT WHEN WALKTHROUGH IS ACCESSING AND RETRIEVING
      ACCOUNT DATA FROM THIRD-PARTY DATA SOURCES, WALKTHROUGH IS ACTING AS YOUR
      AGENT, AND NOT AS THE AGENT OF OR ON BEHALF OF ANY THIRD PARTY. YOU AGREE
      THAT THIRD-PARTY DATA SOURCES WILL BE ENTITLED TO RELY ON THE FOREGOING
      AUTHORIZATION, AGENCY, AND POWER OF ATTORNEY GRANTED BY YOU. You
      understand that the Service is not endorsed or sponsored by any
      Third-Party Data Sources accessible through the Service. The overall
      integrity and quality of the data presented by Walkthrough to you is an
      important element in providing you with a high quality and accurate
      picture of your personal finances. Walkthrough, however, is not
      responsible for and cannot guarantee the accuracy or timeliness of Account
      Data we retrieve for you directly, from third party technology providers,
      from third party financial institutions where your accounts are held, or
      from third party research/market data providers used to provide
      Walkthrough's Service. Walkthrough may not be able to foresee or
      anticipate technical or other difficulties that may result in failure to
      obtain data, personalization settings or other service interruptions.
      Walkthrough assumes and has no responsibility for the timeliness,
      accuracy, deletion, non-delivery or failure to store any member data, loss
      of user data, communications or personalization settings.
      {'\n'}
      <Text style={textStyle.boldText}>E. Deactivation.</Text> Walkthrough may,
      at any time and without notice to you: (1) restrict, deactivate, and/or
      terminate your access to the Service (or any portion thereof); or (2)
      terminate or modify the Service (or any portion thereof). Walkthrough will
      not be liable to you or any third party for any termination of or
      modification to the Service regardless of the reason for such termination
      or modification. If you are dissatisfied with any termination or
      modification of the Service, your only right is to terminate your use of
      the Service.
      {'\n\n'}
      <Text style={textStyle.boldText}>
        2. Registration; Eligibility; Restrictions.
      </Text>
      {'\n'}
      <Text style={textStyle.boldText}>A. Walkthrough Members.</Text> To become
      a Walkthrough Member, you must complete the registration process by
      providing us with current, complete, and accurate information, as prompted
      by the applicable registration and onboarding form(s).
      {'\n'}
      <Text style={textStyle.boldText}>B. Accuracy of Information.</Text> You
      acknowledge that if you provide any information to us that is untrue,
      inaccurate, not current, or incomplete, Walkthrough may terminate these
      Terms and your continued access and use of the Service. You will keep all
      your registration information accurate and current. You are responsible
      for all your activity in connection with the Service.
      {'\n'}
      <Text style={textStyle.boldText}>C. Eligibility.</Text> You represent and
      warrant that you are at least 18 years of age and that you have not been
      previously suspended or removed from the Service. THE SERVICE IS NOT FOR
      PERSONS UNDER THE AGE OF 18 OR ANY MEMBERS PREVIOUSLY SUSPENDED OR REMOVED
      FROM THE SERVICE BY WALKTHROUGH. IF YOU ARE UNDER 18 YEARS OF AGE, PLEASE
      DO NOT USE OR ACCESS THE SERVICE AT ANY TIME OR IN ANY MANNER.
      {'\n'}
      <Text style={textStyle.boldText}>D. Credentials.</Text> As part of the
      registration process, you may be asked to select a username and/or
      password or other login credentials. You are entirely responsible for
      maintaining the security and confidentiality of your login credentials.
      You agree to notify Walkthrough immediately of any unauthorized use of
      your account or any other breach of security. To notify us, contact us at{' '}
      <Anchor
        href="mailto:support@walkthrough.co"
        text="support@walkthrough.co"
      />
      . You are responsible for all use of the Service occurring under your
      account and all content posted with your account on the Service. You are
      responsible for keeping your login credentials confidential and for
      notifying us if your login credentials have been hacked or stolen.
      Walkthrough will not be liable for any loss that you may incur as a result
      of someone else using your login credentials or account, either with or
      without your knowledge. You may be held liable for any losses incurred by
      Walkthrough or another party due to someone else using your account or
      login credentials.
      {'\n'}
      <Text style={textStyle.boldText}>E. Your Responsibilities.</Text> You may
      use the Service solely for lawful purposes, as intended through the
      provided functionality of the Service. You may not use the Service in any
      manner that could damage, disable, overburden, or impair our servers or
      networks, or interfere with any other party's use and enjoyment of the
      Service. You may not attempt to gain unauthorized access to the Service,
      Member accounts, or computer systems or networks, through hacking,
      password mining, or any other means. Without limiting any of the
      foregoing, you may not (and you may not allow or assist any third party
      to):
      {'\n'}(1) use, copy, install, transfer, or distribute the Service, except
      as specifically described in these Terms;
      {'\n'}(2) modify, adapt, translate, reverse engineer, decompile, or
      disassemble any portion of the Service;
      {'\n'}(3) remove or alter any copyright, trademark, or other proprietary
      rights notices contained in or on the Service or in or on any content or
      other material obtained through the Service or the use of the Service;
      {'\n'}(4) probe, scan, or test the vulnerability of any system or network
      or breach any security or authentication measures;
      {'\n'}(5) reformat, mirror, or frame any portion of the web pages that are
      part of the Service;
      {'\n'}(6) express or imply that any statements you make are endorsed by
      us, without our prior written consent in each instance;
      {'\n'}(7) transmit any software or other materials that contain any virus,
      worm, time bomb, Trojan horse, or other harmful or disruptive component;
      {'\n'}(8) use any robot, spider, site search/retrieval application, or
      other manual or automatic device or process to retrieve, index, “data
      mine,” or in any way reproduce or circumvent the navigational structure or
      presentation of the Service or its contents;
      {'\n'}(9) harvest or collect information about other Walkthrough Members;
      {'\n'}(10) undertake, cause, permit, or authorize the translation, reverse
      engineering, disassembling, or hacking of any aspect of the Service, or
      attempt to do any of the foregoing, except and solely to the extent
      described in these Terms, the Service's authorized features, or by law, or
      otherwise attempt to use or access any portion of the Service other than
      as intended by Walkthrough;
      {'\n'}(11) access, tamper with, or use non-public areas of the Service,
      Walkthrough's (and its service providers') computer systems and
      infrastructure, or the technical delivery systems of Walkthrough's service
      providers;
      {'\n'}(12) harass, abuse, harm, or advocate or incite harassment, abuse,
      or harm of another person or group, including Walkthrough employees, and
      other Walkthrough Members;
      {'\n'}(13) solicit, or attempt to solicit, personal information from other
      Walkthrough Members, except as permitted through the Service's
      functionality;
      {'\n'}(14) restrict, discourage, or inhibit any person from using the
      Service, disclose personal information about a third person on the Service
      or obtained from the Service without the consent of such person, or
      collect information about Walkthrough Members;
      {'\n'}(15) gain unauthorized access to the Service, to other Walkthrough
      Members' accounts, names, or personally identifiable information, or to
      other computers or websites connected or linked to the Service;
      {'\n'}(16) violate any applicable federal, state, or local laws,
      regulations, or these Terms;
      {'\n'}(17) use the Service for any illegal, inappropriate, and/or
      unauthorized conduct, including using the Service to contact other
      Walkthrough Members for sexual or other inappropriate purposes, or using
      the Service in violation of Walkthrough's or any third party's
      intellectual property or other proprietary or legal rights; or
      {'\n'}(18) use or access the Service to build a competing service.
      {'\n'}We may take any legal action and implement any technical remedies to
      prevent the violation of these provisions and to enforce these Terms.
      {'\n\n'}
      <Text style={textStyle.boldText}>
        3. Consent to Electronic Communications.
      </Text>
      {'\n'}
      <Text style={textStyle.boldText}>
        A. Consent to Electronic Communications.
      </Text>{' '}
      By using the Service or providing personal information to us, you agree
      that we may communicate with you electronically regarding security,
      privacy, and administrative issues relating to your use of the Service. If
      we learn of a security system's breach, we may attempt to notify you
      electronically by posting a notice on the Service or sending an email to
      you, if we have your email address. You may have a legal right to receive
      this notice in writing. To receive free written notice of a security
      breach (or to withdraw your consent from receiving electronic notice),
      please write to us at{' '}
      <Anchor
        href="mailto:support@walkthrough.co"
        text="support@walkthrough.co"
      />
      .{'\n'}
      <Text style={textStyle.boldText}>B. Push Notifications.</Text> When you
      register with Walkthrough, Walkthrough may send you automatic and
      voluntary push notifications based on the notification preferences you
      have selected (“Notifications”). Some Notifications may be turned on by
      default. By using the Service, you agree to receive Notifications
      regarding your use of the Service and your Account Data. While
      Notifications are intended to enhance your use of the Service, you may
      disable Notifications on your device. Depending on which Notifications you
      elect to receive, Notifications may contain sensitive information.
      {'\n'}
      <Text style={textStyle.boldText}>C. Opt Out.</Text> If you wish to remove
      yourself from any list (except as described in Section 3.B (Push
      Notifications)), please email us at{' '}
      <Anchor
        href="mailto:support@walkthrough.co"
        text="support@walkthrough.co"
      />{' '}
      with "OPT-OUT," "UNSUBSCRIBE," "STOP," or "REMOVE" in the subject line, or
      click the “unsubscribe” link in an email you have received from us.
      {'\n'}
      <Text style={textStyle.boldText}>D. Message Delivery.</Text> Walkthrough
      cannot control certain factors relating to message delivery. We may not be
      able to transmit a Notification to you in a timely and accurate manner. We
      have no liability for transmission delays or Notification failures, for
      any erroneous content in a Notification, or for any actions taken or not
      taken in reliance on a Notification.
      {'\n\n'}
      <Text style={textStyle.boldText}>
        4. Material Submitted to the Service.
      </Text>
      {'\n'}
      <Text style={textStyle.boldText}>A. Materials.</Text> By sending or
      transmitting to us information, opinions, content, creative suggestions,
      ideas, notes, concepts, or other materials (collectively, “Materials”), or
      by posting such Materials to any area of the Service, you grant
      Walkthrough and its designees a worldwide, non-exclusive, sublicenseable
      (through multiple tiers), assignable, royalty-free, perpetual, irrevocable
      license to use, reproduce, distribute (through multiple tiers), create
      derivative works of, publicly perform, publicly display, digitally
      perform, make, have made, sell, offer for sale, and import such Materials
      in any media now known or hereafter developed, for the purpose of
      providing, enhancing, and developing the Service, without compensation to
      you. We will never use your name in connection with any of your Materials
      that we use in our own advertising and marketing materials without
      obtaining your prior consent. You hereby waive any moral rights or other
      rights with respect to attribution of authorship regarding Materials that
      you may have under applicable law. None of the Materials will be subject
      to any obligation, whether of confidentiality, attribution, or otherwise,
      on our part and we will not be liable for any use or disclosure of any
      Materials. Walkthrough may remove or alter any Materials at any time for
      any reason. We neither endorse nor are responsible for any opinion,
      advice, information, or statement made or displayed on the Service by any
      Walkthrough Member. We are not responsible for any errors or omissions in
      articles or postings, for hyperlinks embedded in messages, or for any
      results obtained from the use of such information. Under no circumstances
      will Walkthrough and/or its affiliates, suppliers, or agents be liable for
      any loss or damage caused by your reliance on such information obtained
      through the Service. We cannot and do not take responsibility for the
      veracity, reliability, or completeness of any such opinion, advice,
      information, or statement available on the Service.
      {'\n'}
      <Text style={textStyle.boldText}>B. Monitoring.</Text> We have the right
      (but not the obligation) to: (1) monitor the Service and Materials; (2)
      alter or remove any Materials; and (3) disclose Materials and the
      circumstances surrounding their transmission to any third party in order
      to operate the Service, protect ourselves, our sponsors, and our members
      and visitors, and to comply with legal obligations or governmental
      requests. If you believe any content or Material violates our member
      policies, please contact Walkthrough immediately at{' '}
      <Anchor
        href="mailto:support@walkthrough.co"
        text="support@walkthrough.co"
      />{' '}
      so that we can consider its editing or removal.
      {'\n'}
      <Text style={textStyle.boldText}>C. Rights in Materials.</Text> You are
      solely responsible for your Materials and the consequences of posting them
      on the Service. By posting Materials, you represent, warrant, and covenant
      that: (1) you are the creator and owner of the Materials or otherwise have
      sufficient rights and authority to grant the rights granted herein; (2)
      your Materials do not and will not: (a) infringe, violate, or
      misappropriate any third-party right, including any copyright, trademark,
      patent, trade secret, moral right, privacy right, right of publicity, or
      any other intellectual property or proprietary right; or (b) slander,
      defame, or libel any other person; (3) your Materials do not contain any
      viruses, adware, spyware, worms, or other harmful or malicious code; and
      (4) unless you have received prior written authorization, your Materials
      do not contain any confidential information of any third party. We reserve
      all rights and remedies against any Walkthrough Members who breach these
      representations and warranties.
      {'\n'}
      <Text style={textStyle.boldText}>D. Feedback.</Text> If you provide any
      feedback to Walkthrough concerning the functionality and performance of
      the Service (including identifying potential errors and improvements), you
      hereby assign to Walkthrough all right, title, and interest in and to such
      feedback, and Walkthrough is free to use such feedback without payment or
      restriction.
      {'\n\n'}
      <Text style={textStyle.boldText}>
        5. Payment Terms and Order Processing.
      </Text>
      {'\n'}
      <Text style={textStyle.boldText}>A. Free Trial.</Text> Walkthrough may
      offer a free trial period during which you can use the Service for a
      limited period of time. You can manage or cancel the trial subscription
      through the Site, your Apple App Store, Google Play App Store and/or any
      other applicable app store (the “App Stores”) account(s), or otherwise via
      any methods described in the Service.
      {'\n'}
      <Text style={textStyle.boldText}>B. Fees.</Text> Access to the Service, or
      certain features of the Service, may require you to pay fees on a
      subscription basis, and may provide you the option to activate recurring
      automatic payments for those fees. Before you pay any fees, including
      before activating or updating any recurring payments, you will have an
      opportunity to review the fees that you will be charged before you accept
      them. If you activate or update recurring payments through the Service,
      you authorize Walkthrough or its third-party service providers to
      periodically charge, on a going-forward basis and until cancellation, all
      accrued sums on or before the payment due date for the accrued sums.
      Subscriptions automatically renew unless they are cancelled via the Sites,
      the App Stores, or other method described in the Service at least 24 hours
      before the end of the current subscription period.
      {'\n'}
      <Text style={textStyle.boldText}>C. Payment Authorization.</Text> All fees
      are in U.S. Dollars and are non-refundable. Walkthrough (or its
      third-party service providers) may change the fees for the Service or any
      feature of the Service. If Walkthrough changes the fees for the Service,
      Walkthrough will provide you advance notice of those changes and the
      opportunity to accept the changes. If you do not accept the changes,
      Walkthrough may discontinue providing the Service to you.
      {'\n'}
      <Text style={textStyle.boldText}>D. Cancellation.</Text> You may cancel
      recurring charges at any time by modifying your subscription preferences
      through the Site, the App Stores, or otherwise via any methods described
      in the Service. It may take up to three business days for the update or
      cancellation to take effect. Any cancellation of the current subscription
      is permitted without expectation of refunds.
      {'\n'}
      <Text style={textStyle.boldText}>
        E. App Store Subscription Management.
      </Text>{' '}
      If you purchased your subscription through the App Stores, your
      subscription is managed by the App Stores directly. Walkthrough does not
      have the ability to manage your subscription on your behalf, including
      initiating, canceling, or refunding your subscriptions. You may manage
      your subscription or turn off auto-renewal by going to the Account
      Settings screen in the respective App Store app on your mobile device
      after purchase.
      {'\n\n'}
      <Text style={textStyle.boldText}>6. Term and Termination.</Text>
      {'\n'}
      <Text style={textStyle.boldText}>A. Term.</Text> The term of these Terms
      will commence on the date on which you first access or utilize the Service
      in any way and will continue so long as you continue to access or utilize
      the Service, unless earlier terminated by Walkthrough.
      {'\n'}
      <Text style={textStyle.boldText}>
        B. Termination and Other Remedies.
      </Text>{' '}
      Walkthrough may take whatever lawful actions it may deem appropriate in
      response to actual or suspected violations of these Terms including
      termination of these Terms, the suspension or termination of your access
      and/or account, or blocking you from access to the Service.
      {'\n'}
      <Text style={textStyle.boldText}>C. Effect of Termination.</Text> Any and
      all Sections of these Terms will survive termination or expiration of
      these Terms if by their nature or language therein that it is intended for
      these Sections to survive post-termination. Payments by you, which accrue
      or are due before termination or expiration of these Terms, will continue
      to be payable by you, and amounts owed to Walkthrough at the time of such
      termination or expiration, will continue to be owed by you after such
      expiration or termination.
      {'\n\n'}
      <Text style={textStyle.boldText}>7. Ownership.</Text>
      {'\n'}
      <Text style={textStyle.boldText}>A. Proprietary Information.</Text> You
      acknowledge and agree that: (1) the Service, including any Material,
      content, modifications, enhancements and updates, and any originals and
      copies thereof, in whole or in part, and all intellectual property rights
      therein (collectively, “Proprietary Information”), is owned by Walkthrough
      and its licensors, as applicable; (2) the Proprietary Information contains
      valuable copyrighted and proprietary material of Walkthrough; (3) the
      Proprietary Information is licensed, rather than sold, to you pursuant to
      these Terms; and (4) you have no rights in the Proprietary Information,
      other than the rights and licenses specifically granted to you pursuant to
      these Terms.
      {'\n\n'}
      <Text style={textStyle.boldText}>8. Disclaimer of Warranty.</Text>
      {'\n'}
      <Text style={textStyle.boldText}>A. Disclaimers.</Text> You access the
      Service and all Material and content at your own risk. You understand and
      acknowledge that the investment results you could obtain by relying on
      investment information and/or financial planning insights provided by
      Walkthrough cannot be guaranteed and that Walkthrough cannot be held
      responsible. All investments entail a risk of loss and that you may lose
      money. You agree and acknowledge that your access and use of Walkthrough
      is for educational purposes only and is not intended as legal or tax
      planning advice. You agree as a Member that you are responsible for your
      own investment research and investment decisions, that Walkthrough is only
      one of many tools you may use as part of a comprehensive investment
      education process, that you will not rely on Walkthrough as the sole basis
      of your financial decisions and, except as otherwise provided in this
      Agreement, Walkthrough will not be liable for decisions/actions you take
      or authorize third parties to take on your behalf based on information you
      obtain from your access and use of Walkthrough or information you
      otherwise see on our website. You also agree and acknowledge that while
      Walkthrough may provide significant assistance in helping you manage your
      financial and retirement plans, you should consult with an investment
      advisor before making investment decisions or deciding on significant
      changes to your personal financial strategy. You also agree that your
      access and use of Walkthrough may be interrupted from time to time for any
      of several reasons, including the malfunction of equipment, periodic
      updating, maintenance or repair of Walkthrough or other actions that
      Walkthrough, in its sole discretion, may elect to take. In no event will
      Walkthrough be liable to any party for any loss, cost, or damage that
      results from any scheduled or unscheduled downtime. Without limiting any
      of the foregoing, some content may contain forward-looking statements
      based on assumptions; Walkthrough is not responsible for forward-looking
      statements, and there can be no assurance that actual results may not
      differ from those expressed or implied by forward-looking statements.
      {'\n'}
      <Text style={textStyle.boldText}>B. Third-Party Information.</Text>{' '}
      NEITHER WALKTHROUGH NOR ANY ENTITY WHOSE INFORMATION IS MADE AVAILABLE
      THROUGH THE SERVICE IS RESPONSIBLE FOR THE ACCURACY OF INFORMATION, DATA,
      OR CONTENT, INCLUDING, BUT NOT LIMITED TO PRICES, QUOTES, EQUITY SALES,
      OTHER MARKET INFORMATION, AND INFORMATION DERIVED FROM ANY SUCH
      INFORMATION (“MARKET DATA”) THAT ARE DISPLAYED OR REPORTED THROUGH THE
      SERVICE. Walkthrough does not guarantee or make any warranty of any kind,
      express, or implied, regarding the timeliness, sequence, accuracy,
      completeness, usefulness, reliability, or content of Market Data. You
      agree to use Market Data and the Service at your own risk. You agree that
      neither Walkthrough nor any entity whose information is made available
      through the Service will be held liable for any loss arising out of
      relating to: (i) any inaccuracy, defect, or omission in Market Data, (ii)
      any error or delay in the transmission of Market Data, or (iii)
      interruption in any Market Data service. You also acknowledge that any
      information you obtain from another Walkthrough Member comes from those
      individuals, and not from Walkthrough, and that Walkthrough, to the
      fullest extent permitted by law, is not in any way responsible for any of
      the information these third parties may supply or for any statements,
      claims, or representations they may make. To the fullest extent permitted
      by law, Walkthrough disclaims any such statements, claims, or
      representations and the same do not expand or otherwise modify these
      Terms. If you are dissatisfied with the Service, your sole and exclusive
      remedy is to stop accessing and using the Service.
      {'\n'}
      <Text style={textStyle.boldText}>C. Account Data.</Text> WALKTHROUGH IS
      NOT RESPONSIBLE FOR THE ACCURACY OF THE ACCOUNT DATA OBTAINED FROM
      THIRD-PARTY DATA SOURCES THAT ARE DISPLAYED OR REPORTED THROUGH THE
      SERVICE. WALKTHROUGH DOES NOT REPRESENT OR WARRANT THAT IT WILL BE ABLE TO
      RETRIEVE YOUR ACCOUNT DATA FROM ANY THIRD-PARTY DATA SOURCE. If you
      believe any Account Data is inaccurate or outdated, you should contact the
      applicable Third-Party Data Source.
      {'\n\n'}
      <Text style={textStyle.boldText}>9. Limitation of Liability.</Text>
      {'\n'}
      <Text style={textStyle.boldText}>A. LIMITATION.</Text> TO THE FULLEST
      EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO
      LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT
      LIABILITY, OR OTHERWISE) SHALL WALKTHROUGH (OR ITS LICENSORS OR SUPPLIERS)
      BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL,
      INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR
      LOST PROFITS, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR
      COMPUTER FAILURE OR MALFUNCTION, OR (B) ANY AMOUNT, IN THE AGGREGATE, IN
      EXCESS OF THE GREATER OF (I) $100 OR (II) THE AMOUNTS PAID BY YOU TO
      WALKTHROUGH IN CONNECTION WITH THE SERVICE IN THE TWELVE (12) MONTH PERIOD
      PRECEDING THIS APPLICABLE CLAIM, OR (C) ANY MATTER BEYOND OUR REASONABLE
      CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN
      DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
      {'\n'}
      <Text style={textStyle.boldText}>B. INDEPENDENT INVESTIGATION.</Text> YOU
      REPRESENT THAT YOU HAVE INDEPENDENTLY INVESTIGATED THE ADVISABILITY OF
      USING THE SERVICE AND THE POSSIBLE RISKS INVOLVED IN USING THE SERVICE.
      YOU AGREE TO MAINTAIN YOUR OWN INSURANCE COVERING SUCH RISKS AND WILL LOOK
      SOLELY TO SUCH INSURANCE FOR REIMBURSEMENT OF ANY RESULTING DAMAGES.
      {'\n\n'}
      <Text style={textStyle.boldText}>10. Third-Party Disputes.</Text>
      {'\n'}WALKTHROUGH IS NOT AFFILIATED WITH ANY OTHER WALKTHROUGH MEMBER,
      CARRIER, SERVICE PROVIDER, OR THIRD-PARTY SERVICE, AND ANY DISPUTE YOU
      HAVE WITH ANY OTHER WALKTHROUGH MEMBER, CARRIER, SERVICE PROVIDER,
      THIRD-PARTY SERVICE, OR OTHER THIRD PARTY ARISING FROM YOUR USE OF THE
      SERVICE, INCLUDING YOUR EMPLOYER, IS DIRECTLY BETWEEN YOU AND SUCH THIRD
      PARTY, AND YOU IRREVOCABLY RELEASE WALKTHROUGH (AND OUR OFFICERS,
      DIRECTORS, AGENTS, SUBSIDIARIES, JOINT VENTURERS, AND EMPLOYEES) FROM ALL
      CLAIMS, DEMANDS, AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND AND
      NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH
      SUCH DISPUTES.
      {'\n\n'}
      <Text style={textStyle.boldText}>11. Force Majeure.</Text>
      {'\n'}Any computer system, service, or electronic device, whether it is
      yours, an internet service provider's, a mobile network operator's, or
      ours, can experience unanticipated outages, slowdowns, or capacity
      limitations. As a result of high internet traffic volume, transmission
      problems, systems capacity limitations, and other problems, you may
      experience difficulty accessing the Service or communicating with us
      through the internet or other electronic and wireless services. The
      Service may be unavailable during system maintenance, for security
      precautions, or when interrupted by circumstances beyond our control.
      Walkthrough will not be liable for any delay or failure to perform any
      obligation herein if the delay or failure is due to any of the foregoing
      or for any other unforeseen events that are beyond Walkthrough's
      reasonable control, such as strikes, blockade, war, terrorism, riots,
      natural disasters, epidemic, or governmental action.
      {'\n\n'}
      <Text style={textStyle.boldText}>12. Indemnification.</Text>
      {'\n'}
      <Text style={textStyle.boldText}>A. Indemnity.</Text> You agree to
      indemnify and hold Walkthrough, its affiliates, officers, agents,
      employees, third-party providers, and partners harmless from and against
      any and all claims, liabilities, damages (actual and consequential),
      losses and expenses (including attorneys' fees) arising from or in any way
      related to any third party claims relating to (a) your use of the Service
      (including any actions taken by a third party using your account), and (b)
      your violation of these Terms. In the event of such a claim, suit, or
      action ("Claim"), we will attempt to provide notice of the Claim to the
      contact information we have for your account (provided that failure to
      deliver such notice shall not eliminate or reduce your indemnification
      obligations hereunder).
      {'\n'}
      <Text style={textStyle.boldText}>B. Procedure.</Text> We reserve the
      right, at our own expense, to assume the exclusive defense and control of
      any matter otherwise subject to indemnification by you, and in such case,
      you agree to cooperate with our defense of such claim. You will not, in
      any event, settle any such claim or matter without our written consent.
      {'\n\n'}
      <Text style={textStyle.boldText}>13. Additional Service Features.</Text>
      {'\n'}
      <Text style={textStyle.boldText}>
        A. Access to Third-Party Services.
      </Text>{' '}
      The Service may contain information on products and services provided by
      third parties and links (including advertisements) to third-party websites
      (collectively, “Third-Party Services”). Third-Party Services are provided
      only as a convenience to Walkthrough Members. Walkthrough does review but
      does not control Third-Party Services, and Walkthrough does not make any
      representations or warranties, express or implied, regarding Third-Party
      Services. Inclusion of any Third-Party Services in the Service does not
      constitute or imply an endorsement, authorization, sponsorship, or
      affiliation by or with Walkthrough with respect to any Third-Party
      Services. Walkthrough is under no obligation to maintain any link on the
      Service and may remove a link at any time in its sole discretion for any
      reason whatsoever.
      {'\n'}
      <Text style={textStyle.boldText}>B. Third-Party Integrations.</Text> The
      Service may allow you to connect, integrate, or sync with your Walkthrough
      account certain accounts provided by or retrieve information maintained by
      third-parties with whom you have a customer relationship, maintain an
      account, or engage in financial transactions (“Third-Party Integrations”).
      Walkthrough may use third-party data sources to assist in facilitating
      Third-Party Integrations and collect and sync data from Third-Party
      Integrations (“Third-Party Integration Data”). Walkthrough does not review
      Third-Party Integration Data for accuracy, legality or non-infringement.
      Walkthrough is not responsible for Third-Party Integration Data or
      products and services offered by or on third-party sites. As stated
      earlier, Walkthrough cannot always foresee or anticipate technical or
      other difficulties which may result in failure to obtain data or loss of
      data, and personalization settings, or from device operating environment
      malfunctions or other service interruptions. Walkthrough cannot assume
      responsibility for the timeliness, accuracy, deletion, non-delivery or
      failure to store any user data, communications or personalization
      settings. You are responsible for connecting, integrating, and syncing
      with Third-Party Integrations. For example, you may be required to provide
      valid credentials for the Third-Party Integration. You may also be
      required to generate authentication codes, keys, or other tools to connect
      with a Third-Party Integration. We may provide tools to facilitate such
      connections and/or guides detailing a process for facilitating such
      connections, but you are entirely responsible for arranging such
      connections in an accurate, complete, and secure manner. You agree that
      Walkthrough disclaims any responsibility or liability for and that
      Walkthrough will not be held liable for any loss arising out of relating
      to your connection to a Third-Party Integration.
      {'\n'}
      <Text style={textStyle.boldText}>
        C. Disclaimer of Liability for Third-Party Services and Third-Party
        Integrations.
      </Text>{' '}
      Third-Party Services and Third-Party Integrations may have their own terms
      of use and privacy policies, and your use of the Service may incur
      third-party fees, such as fees charged by your carrier for data usage. You
      are solely responsible for reviewing and complying with any terms of use,
      privacy policies, or other terms governing your use of Third-Party
      Services and Third-Party Integrations, and you are solely responsible for
      all of Third-Party Services and Third-Party Integrations fees incurred by
      you for use of the Service. You are solely responsible for taking the
      precautions necessary to protect yourself from fraud when using
      Third-Party Services and Third-Party Integrations, and to protect your
      computer systems from viruses, worms, Trojan horses, and other harmful or
      destructive content that may be included on or with any Third-Party
      Services and Third-Party Integrations. Walkthrough disclaims any
      responsibility or liability for any harm resulting from your use of
      Third-Party Services and Third-Party Integrations, and you hereby
      irrevocably waive any claim against Walkthrough with respect to any
      Third-Party Services and Third-Party Integrations.
      {'\n\n'}
      <Text style={textStyle.boldText}>14. Assignment.</Text>
      {'\n'}You may not assign, delegate or transfer these Terms or your rights
      or obligations hereunder, or your Service account, in any way (by
      operation of law or otherwise) without Walkthrough's prior written
      consent. We may transfer, assign, or delegate these Terms and our rights
      and obligations without consent.
      {'\n\n'}
      <Text style={textStyle.boldText}>15. Choice of Law; Arbitration.</Text>
      {'\n'}You agree that: (1) Any claim, dispute, or controversy (whether in
      contract, tort, or otherwise) arising out of, relating to, or connected in
      any way with the Service or the App will be resolved exclusively by final
      and binding arbitration conducted pursuant to the American Arbitration
      Association (“AAA”) Procedures for Consumer-Related Disputes in
      conjunction with the AAA Commercial Arbitration Rules (if and as
      applicable depending on the amount in controversy); (2) This arbitration
      agreement is made pursuant to a transaction governed by the Federal
      Arbitration Act (“FAA”), 9 U.S.C. §§ 1-16; (3) The arbitration will be
      held at the AAA regional office nearest you; (4) The arbitrator's decision
      will be controlled by the terms and conditions of this Agreement; (5) The
      arbitrator will apply Delaware law consistent with the FAA and applicable
      statutes of limitations and will honor claims of privilege recognized at
      law; (6) There will be no authority for any claims to be arbitrated on a
      class or representative basis; arbitration can decide only your individual
      claims and the arbitrator may not consolidate or join the claims of other
      persons or parties who may be similarly situated; (7) The arbitrator will
      not have the power to award punitive damages against any party; (8) In the
      event that the administrative fees and deposits you are required to pay
      under the AAA rules exceed $100, and you are unable to pay the additional
      fees and deposits, Walkthrough retains the right to forward them to the
      AAA on your behalf, subject to ultimate allocation by the arbitrator. In
      addition, if you are able to demonstrate that the costs of arbitration
      will be prohibitive as compared to the costs of litigation, Walkthrough
      retains the right to pay as much of your filing and hearing fees in
      connection with the arbitration as the arbitrator deems necessary to
      prevent the arbitration from being cost-prohibitive; and (9) If any part
      of this Arbitration Provision is deemed to be invalid or otherwise
      unenforceable or illegal, the balance of this Arbitration Provision will
      remain in full force and effect and will be construed in accordance with
      its terms as if the invalid or illegal provision were not contained in
      this Agreement. You understand that, in the absence of this provision, you
      would have had a right to litigate disputes through a court, including the
      right to litigate claims on a class-wide or class-action basis, and that
      you have expressly and knowingly waived those rights and agreed to resolve
      any disputes through binding arbitration in accordance with the provisions
      of this paragraph.
      {'\n\n'}
      <Text style={textStyle.boldText}>16. Cooperation with Authorities.</Text>
      {'\n'}Walkthrough may cooperate with legal authorities and/or third
      parties in the investigation of any suspected or alleged crime or civil
      wrong. Except as may be expressly limited by the{' '}
      <NavigationAnchor
        text="Privacy Policy"
        handlePress={(nav) => {
          nav.navigate('PrivacyPolicyScreen')
        }}
      />
      , Walkthrough may disclose any information as Walkthrough deems necessary
      to satisfy any applicable law, regulation, legal process, or governmental
      request, or to edit, refuse to post, or to remove any information or
      materials, in whole or in part, in Walkthrough's sole discretion.
      {'\n\n'}
      <Text style={textStyle.boldText}>
        17. Protected Activity Not Prohibited.
      </Text>
      {'\n'}To the extent permitted by applicable law, nothing in these Terms
      will in any way limit or prohibit you from filing a charge or complaint
      with, or otherwise communicating or cooperating with or participating in,
      any investigation or proceeding (“Protected Activity”) that may be
      conducted by any federal, state, or local government agency or commission
      (“Government Agencies”). In connection with such Protected Activity, you
      are permitted to disclose documents or other information as permitted by
      law, and without giving notice to, or receiving authorization from,
      Walkthrough. In making any such disclosures or communications, you agree
      to take all reasonable precautions to prevent any unauthorized use or
      disclosure of any information that may constitute Walkthrough confidential
      information to any parties other than the Government Agencies.
      {'\n\n'}
      <Text style={textStyle.boldText}>18. Entire Agreement; Variation.</Text>
      {'\n'}These Terms and the{' '}
      <NavigationAnchor
        text="Privacy Policy"
        handlePress={(nav) => {
          nav.navigate('PrivacyPolicyScreen')
        }}
      />{' '}
      set forth the entire agreement between Walkthrough and you with respect to
      the Service. These Terms supersede and govern all previous oral and
      written communications regarding these matters, all of which are merged
      into these Terms. No usage of trade or other regular practice or method of
      dealing between the parties will be used to modify, interpret, supplement,
      or alter these Terms.
      {'\n\n'}
      <Text style={textStyle.boldText}>19. Severability.</Text>
      {'\n'}If any provision of these Terms is held invalid, illegal, or
      unenforceable, such provisions will be modified, or if not possible,
      severed, to reflect the fullest valid, legal, and enforceable expression
      of the intent of the parties, and the remainder of these Terms will not be
      affected thereby.
      {'\n\n'}
      <Text style={textStyle.boldText}>20. Relationship of Parties.</Text>
      {'\n'}Nothing in these Terms will be deemed to create an employer-employee
      relationship between Walkthrough and you, nor any agency, joint venture,
      or partnership relationship between the parties. Neither party will have
      the right to bind the other to any obligation, nor have the right to incur
      any liability on behalf of the other.
      {'\n\n'}
      <Text style={textStyle.boldText}>21. Waiver.</Text>
      {'\n'}No delay, omission, or failure to exercise any right or remedy
      provided under these Terms will be deemed to be a waiver thereof or an
      acquiescence to the event giving rise to such right or remedy, or a waiver
      of or acquiescence to any other right, remedy, or event.
      {'\n\n'}
      <Text style={textStyle.boldText}>22. Third-Party Beneficiaries.</Text>
      {'\n'}The provisions of these Terms relating to the rights of Walkthrough
      service providers are intended for the benefit of such service providers,
      and such service providers, as third-party beneficiaries, will be entitled
      to enforce such provisions in accordance with these Terms, irrespective of
      the fact that they are not parties to these Terms.
      {'\n\n'}
      <Text style={textStyle.boldText}>23. Export Controls.</Text>
      {'\n'}You hereby represent and warrant that: (A) you are not located in a
      country that is subject to a U.S. Government embargo, or that has been
      designated by the U.S. Government as a “terrorist supporting” country; and
      (B) you are not listed on any U.S. Government list of prohibited or
      restricted parties.
      {'\n\n'}
      <Text style={textStyle.boldText}>24. Interpretation.</Text>
      {'\n'}If Walkthrough provides a translation of the English language
      version of these Terms, the translation is provided solely for
      convenience, and the English version will prevail. Any heading, caption,
      or section title contained in these Terms is for convenience only, and in
      no way defines or explains any section or provision. Any use of the term
      “including” or variations thereof in this Agreement shall be construed as
      if followed by the phrase “without limitation.”
      {'\n\n'}
      <Text style={textStyle.boldText}>25. NOTICE FOR CALIFORNIA USERS.</Text>
      {'\n'}Under California Civil Code Section 1789.3, Walkthrough Members
      located in California are entitled to the following notice: The Complaint
      Assistance Unit of the Division of Consumer Services of the California
      Department of Consumer Affairs may be contacted in writing at 400 R
      Street, Suite 1080, Sacramento, California 95814, or by telephone at (916)
      445-1254 or (800) 952-5210.
      {'\n\n'}
      <Text style={textStyle.boldText}>26. NOTICE REGARDING APP STORES.</Text>
      {'\n'}You acknowledge that these Terms are between you and Walkthrough
      only, not with Apple or Google and/or any other applicable app store (the
      “App Stores”), and the App Stores are not responsible for the Service or
      the content thereof. The App Stores have no obligation whatsoever to
      furnish any maintenance and support services with respect to the Service.
      In the event of any failure of the Service to conform to any applicable
      warranty, then you may notify the App Stores and the App Stores will
      refund the purchase price for the relevant Service to you; and, to the
      maximum extent permitted by applicable law, the App Stores have no other
      warranty obligation whatsoever with respect to the Service. The App Stores
      are not responsible for addressing any claims by you or any third party
      relating to the Service or your possession and/or use of the Service,
      including, but not limited to: (i) product liability claims; (ii) any
      claim that the Service fails to conform to any applicable legal or
      regulatory requirement; and (iii) claims arising under consumer protection
      or similar legislation. The App Stores are not responsible for the
      investigation, defense, settlement and discharge of any third-party claim
      that the Service or your possession and use of the Service infringes that
      third party's intellectual property rights. You agree to comply with any
      applicable third-party terms when using the Service. The App Stores, and
      their subsidiaries, are third-party beneficiaries of these Terms, and upon
      your acceptance of these Terms, the App Stores will have the right (and
      will be deemed to have accepted the right) to enforce these Terms against
      you as a third-party beneficiary of these Terms. You hereby represent and
      warrant that (i) you are not located in a country that is subject to a
      U.S. Government embargo, or that has been designated by the U.S.
      Government as a “terrorist supporting” country; and (ii) you are not
      listed on any U.S. Government list of prohibited or restricted parties. If
      Walkthrough provides a translation of the English language version of
      these Terms, the translation is provided solely for convenience, and the
      English version will prevail.
      {'\n\n'}
      <Text style={textStyle.boldText}>27. Miscellaneous.</Text>
      {'\n'}You will be responsible for paying, withholding, filing, and
      reporting all taxes, duties, and other governmental assessments associated
      with your activity in connection with the Service, provided that
      Walkthrough may, in its sole discretion, do any of the foregoing on your
      behalf or for itself as it sees fit. The failure of either you or us to
      exercise, in any way, any right herein shall not be deemed a waiver of any
      further rights hereunder. As stated earlier, if any provision of these
      Terms is found to be unenforceable or invalid, that provision will be
      limited or eliminated, to the minimum extent necessary, so that these
      Terms shall otherwise remain in full force and effect and enforceable. You
      and Walkthrough agree that these Terms are the complete and exclusive
      statement of the mutual understanding between you and Walkthrough, and
      that it supersedes and cancels all previous written and oral agreements,
      communications and other understandings relating to the subject matter of
      these Terms. You hereby acknowledge and agree that you are not an
      employee, agent, partner, or joint venture of Walkthrough, and you do not
      have any authority of any kind to bind Walkthrough in any respect
      whatsoever.
    </Text>
  </>
)

export function TermsOfServiceScreen (): JSX.Element {
  return <ArticlePage>{termsOfServiceCopy}</ArticlePage>
}
