import * as React from 'react'
import { Pressable, Text, TextStyle, ViewStyle } from 'react-native'
import { useNavigation, ParamListBase } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { buttonStyle, textStyle } from '../themes/global-styles.style'
import { THEME } from '../constants'

// TODO: consider using Link elements (https://reactnavigation.org/docs/link/) in order to ensure that internal
// navigation links are rendered as Anchors (which might improve search engine crawlability).  Googlebot crawler does
// not render urls- it just sends them to the indexer. The indexer will render them and add newly detected url's back
// to the crawler queue, but this can be very slow/require unnecessary iterations between the indexer and crawler.

// An inline anchor that surfaces a navigation object with which to navigate to a different place in the app.
export function NavigationAnchor (props: {
  // Text styles nest, so only specify extra styles you want to add to indicate this is an anchor.  By default,
  // we just underline the text.
  style: TextStyle[]
  handlePress: (
    navigation: NativeStackNavigationProp<ParamListBase, string>
  ) => void
  text: string
}): JSX.Element {
  const navigation =
    useNavigation<NativeStackNavigationProp<ParamListBase, string>>()
  return (
    <Text
      style={props.style}
      onPress={() => {
        props.handlePress(navigation)
      }}
    >
      {props.text}
    </Text>
  )
}

NavigationAnchor.defaultProps = {
  style: { textDecorationLine: 'underline' }
}

export function NavigationButton (props: {
  buttonStyle: ViewStyle[]
  handlePress: (
    navigation: NativeStackNavigationProp<ParamListBase, string>
  ) => void
  text: string
  textStyle?: TextStyle[]
}): JSX.Element {
  const navigation =
    useNavigation<NativeStackNavigationProp<ParamListBase, string>>()
  return (
    <Pressable
      style={props.buttonStyle}
      onPress={() => props.handlePress(navigation)}
    >
      <Text
        style={[
          textStyle.largeText,
          textStyle.boldText,
          ...(props.textStyle ?? [])
        ]}
      >
        {props.text}
      </Text>
    </Pressable>
  )
}

NavigationButton.defaultProps = {
  buttonStyle: [buttonStyle(THEME.color.highlight).inlineButton]
}
