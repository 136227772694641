import * as React from 'react'
import { FlatList, Pressable, useWindowDimensions, View } from 'react-native'
import CloseIcon from '../../assets/close-icon'
import MenuIcon from '../../assets/menu-icon'
import { shouldUseMobileDesign } from '../../util/use-mobile-design'
import {
  NavigationMenuButton,
  NavigationMenuButtonProps
} from './navigation-menu-button'
import { navigationMenuStyle } from './navigation-menu.style'
import OneLineLogo from '../../assets/one-line-logo'

export function NavigationMenu (props: {
  navigationMenuButtons: NavigationMenuButtonProps[]
  menuIsOpen: boolean
  setMenuIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}): JSX.Element {
  const useMobileDesign = shouldUseMobileDesign(useWindowDimensions().width)

  const style = navigationMenuStyle(useMobileDesign, props.menuIsOpen)

  return (
    <View style={style.navigationMenuContainer}>
      {props.menuIsOpen && useMobileDesign ? (
        <View style={style.logoContainer}>
          <OneLineLogo />
        </View>
      ) : null}
      <Pressable
        style={[style.openCloseMenuButton]}
        onPress={() => {
          props.setMenuIsOpen(!props.menuIsOpen)
        }}
      >
        {props.menuIsOpen ? <CloseIcon /> : <MenuIcon />}
      </Pressable>
      <FlatList
        style={{ display: props.menuIsOpen ? 'flex' : 'none' }}
        data={props.navigationMenuButtons}
        renderItem={({ item }) => (
          <NavigationMenuButton
            key={item.key}
            handleOnPress={item.handleOnPress}
            text={item.text}
            isSelected={item.isSelected}
            style={item.style}
          />
        )}
        contentContainerStyle={{
          alignItems: 'flex-end'
        }}
        keyExtractor={(item) => item.key}
      />
    </View>
  )
}
