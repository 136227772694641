import React from 'react'
import {
  Platform,
  useWindowDimensions,
  View,
  Text,
  Pressable
} from 'react-native'
import LandscapeVertical from '../assets/landscape-vertical'
import LandscapeHorizontal from '../assets/landscape-horizontal'
import { shouldUseMobileDesign } from '../util/use-mobile-design'
import { WebView } from 'react-native-webview'
import {
  purchaseConfirmationStyle,
  screenWidthSpecificStyle
} from './financial-plan-purchase-confirmation.style'
import { Anchor } from '../util/anchor'
import { textStyle } from '../themes/global-styles.style'
import OneLineLogo from '../assets/one-line-logo'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { ParamListBase } from '@react-navigation/native'

export function FinancialPlanPurchaseConfirmationScreen (props: {
  route: {
    params: {
      sessionId: string
    }
  }
  navigation: NativeStackNavigationProp<ParamListBase, string>
}): JSX.Element {
  if (Platform.OS !== 'web') {
    throw new Error('Landing site should never be deployed as a native app.')
  }

  const useMobileDesign = shouldUseMobileDesign(useWindowDimensions().width)
  const screenWidthStyle = screenWidthSpecificStyle(useMobileDesign)

  // TODO: Once we add tiktok (and other) conversions, there is a chance that Tiktok will count the same conversions
  // that Google counts. This might happen if someone first sees both a Tiktok ad and a Google ad before converting.
  // If we see this happening, we can use the utm_source param passed through from the stripe purchase page to only
  //  send Tiktok conversion pings for the 'tiktok' utm_source, and only send Google conversion pings for the 'google'
  // utm_source.
  // TODO: This page is vulnerable to being spammed with conversion events with fake sessionIds. To mitigate this,
  // we can check if the sessionId is valid by calling the Stripe API to retrieve it (this would require us to set
  // up a backend for the landing site, though). See https://stripe.com/docs/api/checkout/sessions/retrieve and
  // https://stripe.com/docs/payments/checkout/custom-success-page.

  // Duplicate conversions:
  // From Google's documentation (https://support.google.com/google-ads/answer/6386790?hl=en): If there are
  // 2 or more conversions for this conversion action with the same transaction_id, Google Ads won't count the
  // duplicate conversions.
  // Meta does the same thing using their eventID.

  // Why scripts are in the <head> tag:
  // From Meta's documentation (https://developers.facebook.com/docs/meta-pixel/get-started/#installing-the-pixel):
  // Placing the code within your <head> tags reduces the chances of browsers or third-party code blocking the Pixel's
  // execution. It also executes the code sooner, increasing the chance that your visitors are tracked before they
  // leave your page.
  // Google & Tiktok similarly recommend putting the tag in the <head> tag.

  // Where each of the tag scripts came from:
  // Google: https://ads.google.com/aw/conversions/detail?ocid=1196097329&ascid=1196097329&ctId=6474434763&euid=813499872&__u=3186351328&uscid=1196097329&__c=6069977321&authuser=1&sourceid=emp&workflowSessionId=a224122B1-9D7E-4E20-861D-190C1AD92DEB--1
  // Tiktok: https://ads.tiktok.com/i18n/events_manager/pixel/detail/CG3VP2RC77UAN2JP9J3G?start_time=2023-03-11&end_time=2023-03-17&aadvid=7207608743570194433&type=S2SSetting
  // Meta: https://business.facebook.com/adsmanager/manage/adsets/edit?act=936461127536942&business_id=6745043915524599&global_scope_id=6745043915524599&nav_entry_point=lep_237&selected_campaign_ids=23853667030200247&selected_adset_ids=23853667030250247&selected_ad_ids=23853667030310247
  const tagHTML = `
  <html>
  <head>
  <!-- Google tag (gtag.js) -->
  <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11107802748"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'AW-11107802748');
  </script>
  <!-- Google event snippet for Purchase conversion page -->
  <script>
    gtag('event', 'conversion', {
        'send_to': 'AW-11107802748/-XvrCMuRoI8YEPy8zrAp',
        'transaction_id': '${props.route.params.sessionId}'
    });
  </script>
  <!-- Tiktok tag -->
  <script>
    !function (w, d, t) {
      w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
  )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
      ttq.load('CG3VP2RC77UAN2JP9J3G');
      ttq.page();
    }(window, document, 'ttq');
  </script>
  <!-- Meta Pixel Code -->
  <script>
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '3474087119482680');
  fbq('track', 'PageView');
  </script>
  <noscript><img height="1" width="1" style="display:none"
  src="https://www.facebook.com/tr?id=3474087119482680&ev=PageView&noscript=1"
  /></noscript>
  <!-- End Meta Pixel Code -->
  <!-- Meta event snippet for Purchase conversion page -->
  <script>
  fbq('track', 'Purchase', {value: 100, currency: 'USD'}, {eventID: '${props.route.params.sessionId}'});
  </script>
  </head>
  </html>
  `

  return (
    <View style={purchaseConfirmationStyle.outerContainer}>
      <WebView source={{ html: tagHTML }} />
      <View style={purchaseConfirmationStyle.backgroundImage}>
        {useMobileDesign ? <LandscapeVertical /> : <LandscapeHorizontal />}
      </View>
      <View style={screenWidthStyle.header}>
        <Pressable
          onPress={() => {
            props.navigation.navigate('EntryScreen')
          }}
        >
          <OneLineLogo />
        </Pressable>
      </View>
      <View style={screenWidthStyle.textContainer}>
        <Text style={[textStyle.extraLargeText, textStyle.boldText]}>
          Thanks for purchasing a Walkthrough financial plan!{'\n\n'}
        </Text>
        <Text style={textStyle.extraLargeText}>
          If you're feeling eager, you can start filling out the financial plan
          questionnaire{' '}
          <Anchor
            text={'here'}
            href={'https://walkthrough.typeform.com/financialPlan'}
          />
          ! We'll send you an email in the next day or so with this link as well
          😊
        </Text>
      </View>
    </View>
  )
}
