import * as React from "react"
import Svg, { SvgProps, G, Path, Defs, ClipPath } from "react-native-svg"

const LandscapeHorizontal = (props: SvgProps): JSX.Element => (
  <Svg
    width='100%'
    height='100%'
    viewBox='0 0 1280 832'
    fill="none"
    preserveAspectRatio='xMidYMid slice'
    {...props}
  >
    <G clipPath="url(#a)">
      <Path
        d="M777.466 410c-18.554 0-51.022 19.184-83.608 32.067-36.531 14.443-82.585 52.167-185.196 52.167-110.72 0-164.663-16.872-171.676-18.719-7.013-1.846-31.986-12.153-41.986-12.153-21 0-37 30.872-66 12.153-22.087-14.256-92 18.719-133 18.719-44.224 0-97-16.717-97-16.717v96.025h1282V498s-20.06-26.927-75-29c-33.65-1.269-90.41 59-141 59-44 0-68.632-29.69-97-45.638-17.55-9.865-20.59-26.862-44-26.862-23.411 0-40.699-18.942-58.759-18.942-35.938 0-38.446-26.558-87.775-26.558Z"
        fill="#C3EC81"
        stroke="#202232"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <Path
        d="M862.464 504.817c24.367 0 27.728 26.139 57.136 27.205 29.408 1.067 34.5 12.92 57.4 18.34 40.85 9.668 72-39.362 112-39.362 64.89 0 87.88 33.23 119 34 70.45 1.743 78-16.712 78-16.712v63.481H-4v-59.747S34.087 504.33 82 507c46.051 2.566 58.468 17.554 109 13.287 18.225-1.54 52.374 9.764 80 11.735 24.805 1.77 41.547-11.735 64.999-11.735 23.453 0 28.613-23.097 50.319-23.097 27.626 0 56.012 41.378 159.837 41.378 76.958 0 71.778-56.156 93.648-56.156 19.325 0 15.124 49.61 48.733 49.61 26.531 0 40.166 28.54 60.331 28.54 20.166 0 23.692-32.274 53.1-32.274s36.13-23.471 60.497-23.471Z"
        fill="#78DE82"
        stroke="#202232"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <Path
        d="M721.334 587.713c-29.916 0-27.362-45.697-59.832-45.697-32.469 0-34.771 28.749-103.508 34.975-42.403 3.84-66.71-46.305-114.45-46.305-43.413 0-69.598 38.751-105.571 38.751s-40.122.42-69.973-11.829c-38-15.592-78.287 13.568-98 19.383-56.517 16.669-68-46.305-102-46.305-37.844 0-74 46.305-74 46.305v79.308h1300v-98.691s-89.96 37.659-128 19.383c-22.46-10.789-97.55-8.594-120-19.383-22.46-10.788-37.12-51.563-62-50.246-30.804 1.63-37.014 50.784-60.947 50.246-23.933-.537-17.095-15.592-29.061-15.592-19.659 0-16.24 24.195-42.737 27.421-26.497 3.226-43.592-11.829-64.106-11.829-20.514 0-35.899 30.105-65.815 30.105Z"
        fill="#22BEAB"
        stroke="#202232"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <Path
        d="M715.858 599.126v-11.791s2.984.552 5.155.786c2.026.218 5.698-.262 5.698-.262v11.791s-3.733-1.048-5.844-1.048-5.009.524-5.009.524ZM745.458 580.439v-19.528s2.374.241 5.042 0c2.498-.225 6.304-1.654 6.304-1.654v13.85s-1.681 1.357-5.043 3.53c-3.362 2.172-6.303 3.802-6.303 3.802Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <Path
        d="M624.593 589.937c18.46 0 50.71 15.501 62.249 15.859 8.943-2.329 28.04-6.989 32.887-6.989 6.058 0 35.483 6.452 36.348 11.828.865 5.376 3.462 6.451 8.654 9.677 4.154 2.58 6.324 10.548 6.324 13.953 1.731 1.075 5.416 2.043 7.839-.538 3.029-3.226-2.047-9.652 3.146-10.459 5.193-.806 5.193-14.784 8.222-15.053 3.029-.268 17.308-15.859 22.934-14.515 5.625 1.344 27.261 5.107 35.05 5.107 7.788 0 20.77-9.408 29.424-8.87 8.654.537 30.243-.325 49.33-3.226 22.319-3.391 33.452-15.473 56-14.349 20.91 1.042 76.98 20.315 97 26.445 30.72 9.408 54-3.763 92-3.763 87.29 0 136 15.591 136 15.591v72.314H769l-783 .16V581.362s1.09 10.323 50 0c31.849-6.722 64 8.575 100 8.575s60 3.763 134.999-8.575c25.969-4.272 62.913 13.852 65.987 13.682 3.075-.17 24.634 3.821 51.306 3.763 19.128-.041 39.448-12.165 64.132-12.096 28.146.08 59.104 18.8 86.825 19.085 57.206.588 70.649-15.859 85.344-15.859Z"
        fill="#009898"
      />
      <Path
        d="M771.055 634.265c0-3.405-2.17-11.373-6.324-13.953-5.192-3.226-7.789-4.301-8.654-9.677-.865-5.376-30.29-11.828-36.348-11.828-4.847 0-23.944 4.66-32.887 6.989-11.539-.358-43.789-15.859-62.249-15.859-14.695 0-28.138 16.447-85.344 15.859-27.721-.285-58.679-19.005-86.825-19.085-24.684-.069-45.004 12.055-64.132 12.096-26.672.058-48.231-3.933-51.306-3.763-3.074.17-40.018-17.954-65.987-13.682C196 593.7 172 589.937 136 589.937s-68.151-15.297-100-8.575c-48.91 10.323-50 0-50 0v101.747l783-.16m2.055-48.684c1.731 1.075 5.416 2.043 7.839-.538m-7.839.538L769 682.949m9.894-49.222c3.029-3.226-2.047-9.652 3.146-10.459 5.193-.806 5.193-14.784 8.222-15.053 3.029-.268 17.308-15.859 22.934-14.515 5.625 1.344 27.261 5.107 35.05 5.107 7.788 0 20.77-9.408 29.424-8.87 8.654.537 30.243-.325 49.33-3.226 22.319-3.391 33.452-15.473 56-14.349 20.91 1.042 76.98 20.315 97 26.445 30.72 9.408 54-3.763 92-3.763 87.29 0 136 15.591 136 15.591v72.314H784m-5.106-49.222L784 682.949m-15 0h15"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="m769.137 682.899 2.066-48.147s.827.802 3.72.802c2.663 0 4.96-2.407 4.96-2.407l4.547 48.415s-2.067.535-6.613.802c-4.547.268-8.68.535-8.68.535Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <Path d="m770 684.362.5-9 12.5 2 1 7h-14Z" fill="#C8F5FF" />
      <Path
        d="M607.948 670.731c-36.282 15.616-30.576 54.168-34.612 76.409l55.545 129.054 343.62-133.742-40.72-94.609c-6.861-6.664-76.506 29.662-107.097 31.942-42.802 3.189-72.061-35.894-119.632-7.3-30.272 18.196-74.6-11.439-97.104-1.754Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M581.306 764.394c4.037-22.241-1.669-60.792 34.613-76.408 22.504-9.686 66.831 19.95 97.104 1.754 47.571-28.594 76.829 10.489 119.631 7.299 30.591-2.279 100.237-38.606 107.097-31.942"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M589.609 783.683c4.036-22.24-1.67-60.792 34.612-76.408 22.504-9.686 66.832 19.95 97.104 1.754 47.571-28.594 76.83 10.489 119.632 7.299 30.591-2.279 100.236-38.606 107.096-31.941"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M598.701 804.81c4.037-22.241-1.669-60.793 34.613-76.409 22.504-9.685 66.831 19.95 97.104 1.754 47.571-28.594 76.829 10.489 119.631 7.3 30.591-2.28 100.237-38.606 107.097-31.942"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M607.399 825.017c4.036-22.24-1.67-60.792 34.612-76.408 22.504-9.686 66.832 19.95 97.104 1.754 47.571-28.594 76.83 10.489 119.632 7.299 30.591-2.279 100.236-38.605 107.096-31.941"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M614.515 841.551c4.036-22.241-1.67-60.792 34.612-76.408 22.504-9.686 66.832 19.95 97.104 1.754 47.571-28.594 76.83 10.488 119.632 7.299 30.591-2.279 100.237-38.606 107.097-31.942"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M656.499 846.188c-30.4 5.6-41.833 53.667-43.5 76l295 44.674 11-88.174c-12.333-28-42.761-88.706-80-82.5-39 6.5-79-49.5-111.5-37-31.747 12.211-33 80-71 87Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M616.499 940.014c1.667-22.333 13.1-70.4 43.5-76 38-7 39.253-74.789 71-87 32.5-12.5 72.5 43.5 111.5 37 37.239-6.206 67.667 54.5 80 82.5"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M621.499 961.014c1.667-22.333 13.1-70.4 43.5-76 38-7 39.253-74.789 71-87 32.5-12.5 72.5 43.5 111.5 37 37.239-6.206 67.667 54.5 80 82.5"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M628.499 981.014c1.667-22.333 13.1-70.4 43.5-76 38-7 39.253-74.789 71-87 32.5-12.5 72.5 43.5 111.5 37 37.239-6.206 67.667 54.5 80 82.5"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M789.619 750.128c-51.28 11.62-65.232 63.697-65.487 87.198l509.118-9.982 8.55-93.626c-24.31-26.708-83.34-83.908-146.23-70.353-65.87 14.197-140.698-36.29-194.765-17.106-52.814 18.739-47.085 89.344-111.186 103.869Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M732.18 855.161c.255-23.501 14.206-75.578 65.487-87.198 64.1-14.525 58.371-85.13 111.186-103.869 54.067-19.184 128.897 31.303 194.767 17.106 62.89-13.556 121.91 43.645 146.23 70.353"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M743.159 876.004c.255-23.501 14.206-75.578 65.487-87.198 64.1-14.525 58.371-85.13 111.186-103.869 54.067-19.184 128.898 31.303 194.768 17.106 62.89-13.555 121.91 43.645 146.23 70.353"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M757.438 895.426c.255-23.5 14.206-75.577 65.487-87.197 64.1-14.525 58.371-85.13 111.186-103.869 54.067-19.184 128.899 31.303 194.759 17.106 62.9-13.556 121.92 43.645 146.24 70.353"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M770.126 916.078c.254-23.5 14.206-75.578 65.486-87.198 64.101-14.525 58.372-85.129 111.187-103.869 54.071-19.183 128.901 31.304 194.761 17.107 62.89-13.556 121.92 43.645 146.24 70.353"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M780.64 932.769c.254-23.5 14.206-75.578 65.486-87.198 64.101-14.525 58.372-85.129 111.187-103.869 54.067-19.183 128.897 31.304 194.767 17.107 62.89-13.556 121.91 43.645 146.23 70.353"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M797.871 947.658c.255-23.5 14.206-75.578 65.487-87.198 64.1-14.525 58.371-85.13 111.186-103.869 54.066-19.183 128.896 31.303 194.766 17.107 62.89-13.556 121.91 43.645 146.23 70.353"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M625.55 648.393c37.917 11.069 36.961 50.029 43.683 71.61L629.86 854.874l-357.378-90.787 28.864-98.873c5.995-7.452 79.555 20.099 110.196 18.627 42.871-2.061 67.138-44.423 117.845-21.852 32.268 14.364 72.645-20.462 96.163-13.596Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M663.428 738.102c-6.721-21.582-5.765-60.542-43.683-71.611-23.518-6.865-63.895 27.961-96.163 13.597-50.706-22.572-74.974 19.791-117.845 21.852-30.641 1.472-104.201-26.079-110.196-18.627"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M657.543 758.26c-6.721-21.581-5.765-60.541-43.682-71.61-23.519-6.866-63.896 27.96-96.164 13.597-50.706-22.572-74.974 19.791-117.845 21.851-30.64 1.473-104.2-26.078-110.195-18.627"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M651.098 780.339c-6.721-21.582-5.765-60.541-43.683-71.611-23.518-6.865-63.895 27.961-96.163 13.597-50.706-22.571-74.974 19.791-117.845 21.852-30.641 1.472-104.201-26.079-110.196-18.627"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M644.933 801.457c-6.721-21.581-5.765-60.541-43.683-71.61-23.518-6.866-63.895 27.96-96.163 13.597-50.706-22.572-74.974 19.791-117.845 21.851-30.641 1.473-104.201-26.079-110.196-18.627"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M639.889 818.736c-6.721-21.582-5.765-60.542-43.683-71.611-23.518-6.866-63.895 27.961-96.163 13.597-50.706-22.572-74.974 19.791-117.845 21.851-30.641 1.473-104.201-26.078-110.196-18.626"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M582.5 781.386c-30.4 5.761-41.833 55.212-43.5 78.189l295 45.961 11-90.714c-12.333-28.807-42.761-91.262-80-84.877-39 6.687-79-50.926-111.5-38.066-31.747 12.562-33 82.305-71 89.507Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M542.5 877.915c1.667-22.977 13.1-72.428 43.5-78.19 38-7.201 39.253-76.944 71-89.506 32.5-12.861 72.5 44.753 111.5 38.066 37.239-6.385 67.667 56.07 80 84.877"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M547.5 899.52c1.667-22.977 13.1-72.428 43.5-78.19 38-7.201 39.253-76.944 71-89.506 32.5-12.861 72.5 44.753 111.5 38.066 37.239-6.385 67.667 56.07 80 84.877"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M554.5 920.096c1.667-22.976 13.1-72.428 43.5-78.189 38-7.202 39.253-76.945 71-89.507 32.5-12.86 72.5 44.753 111.5 38.066 37.239-6.385 67.667 56.07 80 84.877"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M560.5 941.702c1.667-22.977 13.1-72.429 43.5-78.19 38-7.202 39.253-76.945 71-89.507 32.5-12.86 72.5 44.753 111.5 38.066 37.239-6.385 67.667 56.07 80 84.877"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M565.5 959.191c1.667-22.977 13.1-72.428 43.5-78.19 38-7.201 39.253-76.944 71-89.506 32.5-12.86 72.5 44.753 111.5 38.066 37.239-6.385 67.667 56.07 80 84.877"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M574.5 975.652c1.667-22.976 13.1-72.428 43.5-78.189 38-7.202 39.253-76.945 71-89.507 32.5-12.86 72.5 44.753 111.5 38.066 37.239-6.385 67.667 56.07 80 84.877"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M548.546 739.283c-33.578-4.18-90.199 39.853-114.312 62.392l435.881 107.744 34.315-138.822c-4.843 2.924-20.109 8.009-42.431 4.963-27.902-3.806-77.968-42.964-112.894-43.357-34.927-.393-59.032 55.455-94.689 53.851-35.657-1.603-63.897-41.547-105.87-46.771Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M438.412 822.28c24.113-22.539 80.733-66.572 114.312-62.393 41.972 5.225 70.212 45.169 105.869 46.772 35.658 1.603 59.763-54.244 94.69-53.852 34.926.393 84.992 39.551 112.894 43.358 22.321 3.045 37.588-2.04 42.431-4.963"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M441.139 844.586c24.113-22.539 80.734-66.572 114.312-62.392 41.972 5.224 70.212 45.168 105.869 46.771 35.658 1.603 59.763-54.244 94.69-53.851 34.926.392 84.992 39.55 112.894 43.357 22.321 3.045 37.588-2.04 42.431-4.963"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M445.807 867.372c24.113-22.539 80.734-66.572 114.312-62.392 41.973 5.224 70.213 45.168 105.87 46.771 35.657 1.604 59.763-54.244 94.689-53.851 34.926.392 84.993 39.551 112.894 43.357 22.322 3.046 37.588-2.04 42.431-4.963"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M448.534 889.678c24.113-22.539 80.734-66.572 114.312-62.392 41.973 5.224 70.213 45.168 105.87 46.771 35.657 1.604 59.763-54.244 94.689-53.851 34.926.392 84.993 39.551 112.894 43.357 22.322 3.046 37.588-2.04 42.431-4.963"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M453.934 913.675c24.113-22.539 80.733-66.572 114.312-62.392 41.972 5.224 70.212 45.168 105.869 46.771 35.658 1.603 59.763-54.244 94.689-53.851 34.927.392 84.993 39.55 112.895 43.357 22.321 3.045 37.588-2.04 42.431-4.963"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M456.42 936.952c24.114-22.539 80.734-66.572 114.312-62.393 41.973 5.225 70.213 45.169 105.87 46.772 35.657 1.603 59.763-54.244 94.689-53.851 34.927.392 84.993 39.55 112.895 43.357 22.321 3.045 37.587-2.04 42.43-4.963"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M359.999 737.362c-39.5 0-49.5 37.667-62 56.5v140.5l368.5 13v-103c-3.667-8.833-82-3-111-13-40.576-13.992-52-61.458-107-54-35 4.746-64-40-88.5-40Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M298.499 812.862c12.5-18.833 22.5-56.5 62-56.5 24.5 0 53.5 44.746 88.5 40 55-7.458 66.424 40.008 107 54 29 10 107.333 4.167 111 13"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M298.499 833.862c12.5-18.833 22.5-56.5 62-56.5 24.5 0 53.5 44.746 88.5 40 55-7.458 66.424 40.008 107 54 29 10 107.333 4.167 111 13"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M298.499 856.862c12.5-18.833 22.5-56.5 62-56.5 24.5 0 53.5 44.746 88.5 40 55-7.458 66.424 40.008 107 54 29 10 107.333 4.167 111 13"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M298.499 878.862c12.5-18.833 22.5-56.5 62-56.5 24.5 0 53.5 44.746 88.5 40 55-7.458 66.424 40.008 107 54 29 10 107.333 4.167 111 13"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M1094.78 687.498c-31.08 24.382-15.7 60.189-13.9 82.722l86.72 110.54 297.95-217.232-63.58-81.036c-8.34-4.686-66.37 48.255-95.36 58.288-40.56 14.037-78.84-16.255-117.51 23.561-24.61 25.338-75.05 8.034-94.32 23.157Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M1093 784.86c-1.79-22.533-17.18-58.34 13.9-82.722 19.28-15.123 69.71 2.181 94.32-23.157 38.67-39.816 76.95-9.524 117.51-23.561 28.99-10.033 87.02-62.975 95.36-58.288"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M1105.96 801.382c-1.79-22.533-17.17-58.34 13.9-82.722 19.28-15.123 69.71 2.181 94.32-23.157 38.67-39.817 76.96-9.524 117.52-23.561 28.99-10.033 87.01-62.975 95.35-58.288"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M1120.16 819.477c-1.79-22.533-17.18-58.34 13.9-82.721 19.28-15.123 69.71 2.18 94.32-23.157 38.67-39.817 76.95-9.524 117.51-23.562 28.99-10.033 87.02-62.974 95.36-58.288"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M1133.74 836.786c-1.8-22.533-17.18-58.34 13.9-82.722 19.28-15.123 69.71 2.181 94.32-23.157 38.67-39.816 76.95-9.523 117.51-23.561 28.99-10.033 87.02-62.974 95.36-58.288"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M1144.85 850.948c-1.79-22.533-17.18-58.34 13.9-82.722 19.28-15.123 69.71 2.181 94.32-23.157 38.67-39.816 76.95-9.524 117.51-23.561 28.99-10.033 87.02-62.975 95.36-58.288"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M343.499 819.362c-26.5-8-26.5-33-57.5-38l-9.5 232.998 269 3.5 41.5-98.998c-13-17-49.565-6.398-86-20-37.5-14-39.571-45.063-62-65-31.5-28-72.504-7.558-95.5-14.5Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M276.999 794.362c31 5 31 30 57.5 38 22.996 6.942 64-13.5 95.5 14.5 22.429 19.937 24.5 51 62 65 36.435 13.602 73 3 86 20"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M265.999 810.362c31 5 31 30 57.5 38 22.996 6.942 64-13.5 95.5 14.5 22.429 19.937 24.5 51 62 65 36.435 13.602 73 3 86 20"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M256.999 826.362c31 5 31 30 57.5 38 22.996 6.942 64-13.5 95.5 14.5 22.429 19.937 24.5 51 62 65 36.435 13.602 73 3 86 20"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M931.378 748.286c-50.072 19.141-48.204 62.032-56.778 87.113l54.25 141.912L1401 811.869l-39.77-104.035c-8.06-7.146-105.11 36.706-145.73 40.659-56.84 5.53-89.65-36.877-156.49-2.692-42.54 21.755-96.574-9.388-127.632 2.485Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M882.571 854.347c8.573-25.08 6.706-67.971 56.778-87.113 31.057-11.872 85.101 19.27 127.631-2.484 66.84-34.185 99.65 8.222 156.49 2.691 40.62-3.952 137.67-47.804 145.73-40.659"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M890.679 875.558c8.574-25.08 6.706-67.971 56.778-87.112 31.058-11.873 85.093 19.269 127.633-2.485 66.84-34.185 99.65 8.222 156.49 2.692 40.62-3.953 137.67-47.805 145.73-40.66"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M899.56 898.79c8.573-25.08 6.706-67.972 56.778-87.113 31.057-11.873 85.092 19.27 127.632-2.484 66.84-34.186 99.65 8.221 156.49 2.691 40.62-3.953 137.67-47.805 145.73-40.659"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M908.054 921.011c8.574-25.08 6.706-67.972 56.778-87.113 31.058-11.873 85.098 19.27 127.628-2.484 66.85-34.186 99.65 8.222 156.49 2.691 40.63-3.953 137.67-47.804 145.73-40.659"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M915.004 939.192c8.574-25.08 6.707-67.972 56.779-87.113 31.057-11.873 85.097 19.27 127.627-2.484 66.85-34.186 99.65 8.222 156.49 2.691 40.63-3.953 137.67-47.804 145.73-40.659"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M17.669 622.695c-39.29 4.078-45.347 42.576-55.836 62.599l14.506 139.749 367.873-25.114-10.634-102.449c-4.559-8.408-81.872 5.482-111.749-1.471-41.804-9.728-58.067-55.761-112.004-42.665-34.323 8.334-67.787-33.178-92.156-30.649Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-35.708 704.141c10.489-20.023 16.547-58.521 55.836-62.599 24.369-2.53 57.833 38.983 92.156 30.649 53.937-13.096 70.2 32.937 112.004 42.665 29.877 6.952 107.19-6.937 111.749 1.471"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-33.54 725.029c10.489-20.024 16.547-58.521 55.836-62.599 24.369-2.53 57.833 38.983 92.156 30.649 53.937-13.096 70.2 32.937 112.004 42.665 29.877 6.952 107.19-6.937 111.749 1.47"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-31.165 747.906c10.488-20.023 16.546-58.521 55.835-62.599 24.37-2.53 57.834 38.983 92.157 30.649 53.936-13.096 70.199 32.937 112.003 42.665 29.878 6.952 107.19-6.937 111.749 1.47"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-28.894 769.788c10.489-20.023 16.547-58.521 55.836-62.599 24.369-2.529 57.833 38.983 92.156 30.65 53.937-13.097 70.2 32.936 112.004 42.664 29.877 6.953 107.19-6.937 111.749 1.471"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-27.035 787.692c10.488-20.023 16.546-58.521 55.835-62.599 24.37-2.529 57.834 38.983 92.157 30.649 53.936-13.096 70.199 32.937 112.003 42.665 29.877 6.953 107.19-6.937 111.749 1.471"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M401.974 755.128c51.28 11.62 65.232 63.697 65.486 87.198l-509.116-9.982-8.556-93.626c24.319-26.708 83.343-83.908 146.236-70.353 65.867 14.197 140.697-36.29 194.763-17.106 52.815 18.739 47.086 89.344 111.187 103.869Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M459.412 860.161c-.254-23.501-14.206-75.578-65.486-87.198-64.101-14.525-58.372-85.13-111.186-103.869-54.067-19.184-128.897 31.303-194.764 17.106-62.892-13.556-121.917 43.645-146.236 70.353"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M448.433 881.004c-.254-23.501-14.206-75.578-65.486-87.198-64.101-14.525-58.372-85.13-111.186-103.869-54.067-19.184-128.897 31.303-194.764 17.106-62.892-13.555-121.917 43.645-146.236 70.353"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M434.155 900.426c-.255-23.5-14.206-75.577-65.487-87.197-64.101-14.525-58.371-85.13-111.186-103.869-54.067-19.184-128.897 31.303-194.764 17.106C-.174 712.91-59.199 770.111-83.517 796.819"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M421.467 921.078c-.255-23.5-14.206-75.578-65.486-87.198-64.101-14.525-58.372-85.129-111.187-103.869-54.067-19.183-128.896 31.304-194.763 17.107-62.893-13.556-121.917 43.645-146.236 70.353"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M410.953 937.769c-.255-23.5-14.206-75.578-65.487-87.198-64.1-14.525-58.371-85.129-111.186-103.869-54.067-19.183-128.897 31.304-194.763 17.107-62.893-13.556-121.917 43.645-146.236 70.353"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M393.722 952.658c-.255-23.5-14.207-75.578-65.487-87.198-64.101-14.525-58.372-85.13-111.186-103.869-54.067-19.183-128.897 31.303-194.764 17.107-62.892-13.556-121.917 43.645-146.235 70.353"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M260.254 747.77c50.072 19.142 48.204 62.033 56.778 87.113l-54.25 141.913-472.148-165.442 39.771-104.036c8.058-7.145 105.105 36.707 145.728 40.659 56.839 5.531 89.648-36.876 156.489-2.691 42.535 21.754 96.574-9.388 127.632 2.484Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M309.061 853.832c-8.574-25.08-6.706-67.971-56.778-87.113-31.058-11.872-85.097 19.27-127.632-2.484-66.84-34.185-99.65 8.222-156.489 2.691-40.623-3.952-137.669-47.804-145.728-40.659"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M300.953 875.043c-8.574-25.08-6.707-67.971-56.779-87.113-31.057-11.872-85.096 19.27-127.631-2.484-66.841-34.185-99.65 8.222-156.49 2.691-40.622-3.952-137.669-47.804-145.728-40.659"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M292.072 898.275c-8.573-25.081-6.706-67.972-56.778-87.113-31.057-11.873-85.096 19.27-127.632-2.485-66.84-34.185-99.65 8.222-156.489 2.692-40.623-3.953-137.669-47.805-145.728-40.659"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M283.578 920.496c-8.574-25.081-6.707-67.972-56.779-87.113-31.057-11.873-85.096 19.27-127.631-2.485-66.841-34.185-99.65 8.222-156.49 2.692-40.622-3.953-137.669-47.805-145.728-40.659"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M276.627 938.677c-8.573-25.08-6.706-67.972-56.778-87.113-31.057-11.873-85.096 19.27-127.631-2.485-66.842-34.185-99.65 8.222-156.49 2.692-40.622-3.953-137.669-47.805-145.728-40.659"
        stroke="#202232"
        strokeWidth={2}
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h1280v832H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)

export default LandscapeHorizontal
