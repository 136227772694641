import React from 'react'
import { useWindowDimensions, View } from 'react-native'
import { MIN_WIDTH_FOR_TWO_COLUMN_ARTICLES } from '../../constants'
import { contentImagePairStyle } from './content-image-pair.style'
import { isNonNullish } from 'global-utils'

export interface ContentImagePairData {
  // Including key here because it is used to render ScrollArticles in a flat list.
  key: string
  content: React.ReactNode
  color?: string
  image?: React.ReactNode
  // Whether this is full width content. If so, just render the given children,
  // which can be used to take up the full width. This can also used for empty
  // space at the top of the flat list of articles. The full width content needs to also
  // be a content image pair so that we can set it's key as the tab in focus.
  isFullWidth?: boolean
}

// A component used to render an article that is part of a section list.
// A section in the list can contain multiple articles about the same topic.
// Each article has an optional image that can be rendered to the right of it
// in a two-column desktop layout, or above it in a single-column mobile layout
// (or narrow window desktop layout).
export function ContentImagePair (props: ContentImagePairData): JSX.Element {
  const windowWidth = useWindowDimensions().width
  const useTwoColumns = windowWidth >= MIN_WIDTH_FOR_TWO_COLUMN_ARTICLES

  const style = contentImagePairStyle(useTwoColumns, props.color)

  return (
    <>
      {isNonNullish(props.isFullWidth) && props.isFullWidth ? (
        <>{props.content}</>
      ) : (
        <View style={style.contentAndImageContainer}>
          <View style={style.contentContainer}>{props.content}</View>
          {isNonNullish(props.image) ? (
            <View style={style.imageContainer}>{props.image}</View>
          ) : useTwoColumns ? (
            <View style={style.accent} />
          ) : null}
        </View>
      )}
    </>
  )
}
