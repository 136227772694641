import React from 'react'
import { Pressable, useWindowDimensions, View, Text } from 'react-native'
import { shouldUseMobileDesign } from './use-mobile-design'
import { widthDependentWelcomeStyle } from './welcome-buttons.style'
import { buttonStyle, textStyle } from '../themes/global-styles.style'
import { NavigationButton } from './navigation-anchor'
import { isMobile } from 'react-device-detect'
import { WAITLIST_FOR_FREE_FINANCIAL_PLAN, THEME } from '../constants'
import { AnchorButton } from './anchor'

export function WelcomeButtons (props: {
  buttonColor: string
  handleOnPressDesktopAskAQuestion: () => void
  utmSource?: string
  utmCampaign?: string
}): JSX.Element {
  const windowWidth = useWindowDimensions().width
  const useMobileDesign = shouldUseMobileDesign(windowWidth)

  const welcomeStyle = widthDependentWelcomeStyle(useMobileDesign)

  return (
    <View style={welcomeStyle.buttonContainer}>
      <AnchorButton
        href={WAITLIST_FOR_FREE_FINANCIAL_PLAN}
        text={'Get a plan'}
        buttonStyle={[
          buttonStyle(props.buttonColor).inlineButton,
          welcomeStyle.firstButton
        ]}
        textStyle={[
          textStyle.largeText,
          textStyle.boldText,
          { color: THEME.color.background }
        ]}
      />
      {isMobile ? (
        <NavigationButton
          text={'Ask a question'}
          buttonStyle={[
            buttonStyle(props.buttonColor).inlineButton,
            welcomeStyle.lastButton
          ]}
          textStyle={[welcomeStyle.buttonText]}
          handlePress={(nav) => {
            nav.navigate('TextWalkthroughScreen', {
              utm_source: props.utmSource,
              utm_campaign: props.utmCampaign
            })
          }}
        />
      ) : (
        <Pressable
          style={[
            buttonStyle(props.buttonColor).inlineButton,
            welcomeStyle.lastButton
          ]}
          onPress={props.handleOnPressDesktopAskAQuestion}
        >
          <Text style={welcomeStyle.buttonText}>Ask a question</Text>
        </Pressable>
      )}
    </View>
  )
}
