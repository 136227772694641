// This file is currently in sync with app/frontend/themes/default-theme.ts. If updating this file, consider keeping
// it in sync with app/frontend/themes/default-theme.ts or potentially refactoring into a shared util.
import {
  SpacingTheme,
  ShadowTheme,
  FontTheme,
  ButtonTheme,
  RainbowColorTheme
} from './theme'

const DEFAULT_SPACING_THEME: SpacingTheme = {
  marginExtraSmall: 5,
  marginSmall: 7,
  marginMedium: 22,
  marginLarge: 47
}

const DEFAULT_SHADOW_THEME: ShadowTheme = {
  shadowOpacity: 0.3,
  shadowRadius: 4,
  upperDropShadowYOffset: -3,
  shadowColor: '#000000'
}

const DEFAULT_FONT_THEME: FontTheme = {
  fontSizeSmall: 18,
  fontSizeMedium: 20,
  fontSizeLarge: 22,
  fontSizeExtraLarge: 36,
  fontSizeExtraExtraLarge: 55
}

const DEFAULT_BUTTON_THEME: ButtonTheme = {
  smallButtonWidth: 200,
  buttonHeight: 40
}

const RAINBOW_THEME: RainbowColorTheme = {
  indigo: '#91CBFF',
  blue: '#7DDBFB',
  teal: '#91E5E5',
  seafoam: '#8FF1D8',
  green: '#A4FABF',
  lime: '#CBFFA1',
  yellow: '#FDFF86',
  orange: '#FFD68B',
  red: '#FFB7B9',
  pink: '#FFAFEA',
  purple: '#BFB5FF'
}

export {
  DEFAULT_SPACING_THEME,
  DEFAULT_SHADOW_THEME,
  DEFAULT_FONT_THEME,
  DEFAULT_BUTTON_THEME,
  RAINBOW_THEME
}
