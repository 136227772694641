import { StyleSheet, ViewStyle } from 'react-native'
import { THEME } from '../constants'

export const confirmationStyle = StyleSheet.create({
  outerContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: THEME.color.background,
    flex: 1,
    justifyContent: 'center'
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    bottom: 0
  }
})

export const screenWidthSpecificStyle = (
  useMobileDesign: boolean
): {
  header: ViewStyle
  textContainer: ViewStyle
} =>
  StyleSheet.create({
    header: {
      width: '100%',
      paddingVertical: THEME.spacing.marginMedium,
      paddingHorizontal: useMobileDesign
        ? THEME.spacing.marginMedium
        : THEME.spacing.marginLarge,
      backgroundColor: THEME.color.mountainColorTheme.sky,
      position: 'absolute',
      top: 0,
      zIndex: 3,
      flexDirection: useMobileDesign ? 'column' : 'row',
      justifyContent: 'space-between'
    },
    textContainer: {
      position: 'absolute',
      top: 100,
      margin: useMobileDesign
        ? THEME.spacing.marginMedium
        : THEME.spacing.marginLarge
    }
  })
