import React from 'react'
import { Text } from 'react-native'
import { Anchor } from '../../../util/anchor'
import { ArticlePage } from '../article-page'
import { NavigationAnchor } from '../../../util/navigation-anchor'
import { textStyle } from '../../../themes/global-styles.style'
import { ArticleKey } from '../../main'

const formCRSCopy = (
  <>
    <Text style={textStyle.regularText}>
      <Text style={textStyle.italicBoldText}>Relationship Summary</Text>
      <Text style={textStyle.boldText}>
        {'\n\n'}June 15, 2022
        {'\n\n'}Item 1. Introduction
        {'\n\n'}The Company, Inc. d/b/a Walkthrough (“Walkthrough”)
      </Text>
      {'\n\n'}Walkthrough is an investment adviser registered with the
      Securities and Exchange Commission (“SEC”).
      {'\n\n'}Brokerage and investment advisory services and fees differ, and it
      is important for you to understand the differences. The SEC provides free
      educational materials about investment advisors, brokerage services and
      investing and offers simple tools to help you to research firms and
      financial professionals at{' '}
      <Anchor
        href="https://www.investor.gov/CRS"
        text={'www.investor.gov/CRS'}
      />
      .
      <Text style={textStyle.boldText}>
        {'\n\n'}Item 2. Relationships and Services
      </Text>
      <Text style={textStyle.italicBoldText}>
        {'\n\n'}What investment services and advice can you provide me?
        {'\n\n'}Conversation Starters:
      </Text>
      {'\n'} • Given my financial situation, should I choose an investment
      advisory service? Why or why not?
      {'\n'} • How will you choose investments to recommend to me?
      {'\n'} • What is your relevant experience, including your licenses,
      education and other qualifications? What do you these qualifications mean?
      {'\n\n'}We maintain a website and mobile application that allows you to
      connect your financial accounts. We provide investment advisory services
      to individuals, such as financial planning advice for saving money,
      accomplishing personal financial goals, and improving your financial
      well-being. We provide an automated personal finance information
      management tool that allows you to track and organize your finances with
      relevant financial insight. We provide advice on certain limited types of
      non-affiliated investments such as: exchange-traded funds (ETFs), mutual
      funds, domestic publicly traded securities, bonds, certificates of deposit
      (CDs), and money market funds. Advice is based on your financial situation
      evidenced by your financial accounts that you link on your Walkthrough
      account and provide to Walkthrough via questionnaire or other methods.
      {'\n\n'}Walkthrough software reviews your account on an ongoing basis to
      ensure consistency with your risk profile, strategy and performance
      objectives.
      {'\n\n'}We will manage your account on a non-discretionary basis and in
      accordance with the Terms of Services.
      {'\n\n'}There is no minimum amount to open and maintain an account with
      Walkthrough.
      {'\n\n'}For more information regarding all of our services, read the
      accompanying{' '}
      <Anchor
        href="https://drive.google.com/file/d/13Ysp9KP6aq-eVA8mhAB3OiVrI5Rv07Mc/view"
        text="Brochure"
      />{' '}
      starting on page 1 (Items 4 and 7).
      <Text style={textStyle.boldText}>
        {'\n\n'}Item 3. Fees, Costs, Conflicts, and Standard of Conduct
      </Text>
      <Text style={textStyle.italicBoldText}>
        {'\n\n'}Conversation Starters:
      </Text>
      {'\n'} • How might your conflicts of interest affect me, and how will you
      address them?
      {'\n'} • What are your legal obligations to me when acting as my
      investment adviser? How else does your firm make money and what conflicts
      of interest do you have?
      {'\n'} • How do your financial professionals make money?
      <Text style={textStyle.italicBoldText}>
        {'\n\n'}What fees will I pay?
      </Text>
      {'\n'}Our fees are charged through an advisory fee program, meaning we
      charge one fee that covers all the advisory services provided. The fee is
      on a subscription-based program of $10.00 per month or $100.00 for a
      prepaid 12-month subscription. We reserve the right to negotiate your fee
      structure on an as-needed basis, but will not alter the fee structure
      without your explicit consent.
      {'\n\n'}Any third-party fees such as, brokerage commissions, transaction
      fees, and other related costs and expenses, are exclusive of and in
      addition to the advisory fee. We do not receive any portion of these
      commissions, fees, and costs. Other fees and costs you will pay are
      outlined in the corresponding third-parties terms of use or related
      agreement.
      {'\n\n'}Read more about our fees starting on page 2 (Item 5) of the{' '}
      <Anchor
        href="https://drive.google.com/file/d/13Ysp9KP6aq-eVA8mhAB3OiVrI5Rv07Mc/view"
        text="Brochure"
      />
      .
      <Text style={textStyle.italicBoldText}>
        {'\n\n'}What are your legal obligations to me when acting as my
        investment adviser? How else does your firm make money and what
        conflicts of interest do you have?
      </Text>
      {'\n'}When we act as your investment adviser, we are acting as your
      fiduciary, which means we have to act in your best interest and not put
      our interest ahead of yours. At the same time, the way we make money
      creates some conflicts with your interests. You should understand and ask
      us about these conflicts because they can affect the investment advice we
      provide you. Here are some examples to help you understand what this
      means.
      {'\n\n'}Other investment advisory firms may charge the same or lower fees
      than Walkthrough for the same or similar services.
      {'\n\n'}You pay our subscription fee monthly even if you do not buy or
      sell any securities in order to maintain access to our dynamic guidance.
      {'\n\n'}Read more about our conflicts of interest and how we manage them
      in our Form ADV Part 2A{' '}
      <Anchor
        href="https://drive.google.com/file/d/13Ysp9KP6aq-eVA8mhAB3OiVrI5Rv07Mc/view"
        text="Brochure"
      />{' '}
      starting on page 10 (Item 11).
      {'\n\n'}You will pay fees and costs whether you make or lose money on your
      investments. Fees and costs will reduce any amount of money you make on
      your investments over time. Please make sure you understand what fees and
      costs you are paying.
      <Text style={textStyle.boldText}>
        {'\n\n'}Item 4. Disciplinary History
      </Text>
      <Text style={textStyle.italicBoldText}>
        {'\n\n'}Conversation Starters:
      </Text>
      {'\n'} • As a financial professional, do you have any disciplinary
      history? For what type of conduct?
      <Text style={textStyle.italicBoldText}>
        {'\n\n'}Do you or your financial professionals have legal or
        disciplinary history?
      </Text>
      {'\n'}No, Walkthrough and its financial professionals have no disciplinary
      history. Visit{' '}
      <Anchor href="https://www.investor.gov/CRS" text="www.investor.gov/CRS" />{' '}
      for a free and simple search tool to research our firm, our financial
      professionals and learn more.
      <Text style={textStyle.boldText}>
        {'\n\n'}Item 5. Additional Information
      </Text>
      <Text style={textStyle.italicBoldText}>
        {'\n\n'}Conversation Starters:
      </Text>
      {'\n'} • Who is my primary contact person?
      {'\n'} • Is he or she a representative of an investment adviser or a
      broker-dealer?
      {'\n'} • Who can I talk to if I have concerns about how this person is
      treating me?
      {'\n\n'}Read the accompanying{' '}
      <Anchor
        href="https://drive.google.com/file/d/13Ysp9KP6aq-eVA8mhAB3OiVrI5Rv07Mc/view"
        text="Brochure"
      />{' '}
      before you invest with us. It contains important information about our
      advisory services. Call or text (267)-388-0028 for up-to-date information
      about us and to request a copy of our Relationship Summary. You can also
      visit us at{' '}
      <NavigationAnchor
        text="walkthrough.co"
        handlePress={(nav) => {
          nav.navigate('MainScreen', { articleKey: ArticleKey.WELCOME })
        }}
      />
      .
    </Text>
  </>
)

export function FormCrsScreen (): JSX.Element {
  return <ArticlePage>{formCRSCopy}</ArticlePage>
}
