import { StyleSheet } from 'react-native'

export const appStyle = StyleSheet.create({
  outerContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: '#343751',
    flex: 1,
    justifyContent: 'center'
  }
})
