import { StyleSheet } from 'react-native'
import { THEME } from '../constants'
import { shadowStyle } from '../themes/global-styles.style'

export const expandableCardStyle = StyleSheet.create({
  card: {
    width: '100%',
    padding: THEME.spacing.marginMedium,
    backgroundColor: THEME.color.surfaceOne,
    borderRadius: 10,
    ...shadowStyle.shadow
  },
  openCardContent: {
    paddingTop: THEME.spacing.marginMedium
  }
})
