import * as React from 'react'
import { Text, Pressable, View, ViewStyle } from 'react-native'
import { THEME } from '../../constants'
import { buttonStyle, textStyle } from '../../themes/global-styles.style'
import { navigationMenuButtonStyle } from './navigation-menu-button.style'

export interface NavigationMenuButtonProps {
  // This key is used to render a list of NavigationMenuButtons with unique keys.
  key: string
  handleOnPress: () => void
  text: string
  isSelected: boolean
  style?: ViewStyle
}

export function NavigationMenuButton (
  props: NavigationMenuButtonProps
): JSX.Element {
  const style = navigationMenuButtonStyle()

  return (
    <Pressable
      style={[style.selectedIconAndButtonContainer, props.style]}
      onPress={props.handleOnPress}
    >
      <View
        style={[
          style.selectedIconContainer,
          { display: props.isSelected ? 'flex' : 'none' }
        ]}
      >
        <Text style={[textStyle.largeText]}>⬤</Text>
      </View>
      <View style={buttonStyle(THEME.color.onSurface).asymmetricalButton}>
        <Text
          style={[
            textStyle.largeText,
            textStyle.boldText,
            { color: THEME.color.background }
          ]}
        >
          {props.text}
        </Text>
      </View>
    </Pressable>
  )
}
