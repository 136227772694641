import { ColorTheme, Gradient, MountainColorTheme, Theme } from './theme'
import {
  DEFAULT_BUTTON_THEME,
  DEFAULT_FONT_THEME,
  DEFAULT_SHADOW_THEME,
  DEFAULT_SPACING_THEME,
  RAINBOW_THEME
} from './default-theme'

const DAY_GRADIENT_A: Gradient = {
  colors: [
    '#c3ec81',
    '#b4ef90',
    '#a7f19f',
    '#9bf2ae',
    '#91f3bc',
    '#89f4ca',
    '#85f4d6',
    '#85f3e1',
    '#88f2eb',
    '#8ff1f2',
    '#98eff8',
    '#a2edfb',
    '#aeebfd',
    '#b9e9fd'
  ]
}

const DAY_GRADIENT_B: Gradient = {
  colors: [
    '#c3ec81',
    '#b2e784',
    '#a1e187',
    '#91dc8a',
    '#81d68d',
    '#72cf90',
    '#63c993',
    '#55c295',
    '#46bc97',
    '#38b599',
    '#2aae99',
    '#1ca79a',
    '#0e9f99',
    '#009898'
  ]
}

const DAY_GRADIENT_C: Gradient = {
  colors: [
    '#2da7cb',
    '#309cc1',
    '#3392b7',
    '#3587ad',
    '#367da2',
    '#367397',
    '#36698c',
    '#356081',
    '#345676',
    '#324d6b',
    '#2f4460',
    '#2c3b55',
    '#28334a',
    '#242b3f'
  ]
}

const MOUNTAIN_COLOR_THEME: MountainColorTheme = {
  river: '#B9E9FD',
  cliff: '#009898',
  hillsOne: '#22BEAB',
  hillsTwo: '#78DE82',
  mountain: '#C3EC81',
  sky: '#C8F5FF',
  moon: '#FFF',
  sun: '#FFBE68'
}

const DAY_COLOR_THEME: ColorTheme = {
  background: '#FFF9EF',
  surfaceOne: '#FFFDFB',
  surfaceTwo: '#FCF5EB',
  onSurface: '#373737',
  highlight: '#78DE82',
  highlightPressed: '#22BEAB',
  superHighlight: '#FF86B1',
  lowLight: '#D9D9D9',
  disabled: '#A5A5A5',
  outline: '#373737',
  overlay: '#373737bf',
  gradientA: DAY_GRADIENT_A,
  gradientB: DAY_GRADIENT_B,
  gradientC: DAY_GRADIENT_C,
  mountainColorTheme: MOUNTAIN_COLOR_THEME,
  rainbowColorTheme: RAINBOW_THEME
}

export const DAY_THEME: Theme = {
  color: DAY_COLOR_THEME,
  spacing: DEFAULT_SPACING_THEME,
  shadow: DEFAULT_SHADOW_THEME,
  font: DEFAULT_FONT_THEME,
  buttons: DEFAULT_BUTTON_THEME
}
