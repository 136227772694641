import React from 'react'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { AppStackParamList } from '../App'
import { navigateToHref } from '../util/anchor'
import { ArticleKey } from './main'
import { isNonNullish } from 'global-utils'

export function TextWalkthroughScreen ({
  navigation
}: NativeStackScreenProps<
AppStackParamList,
'TextWalkthroughScreen'
>): JSX.Element {
  const routes = navigation.getState()?.routes
  let previousRouteName: keyof AppStackParamList | undefined
  if (routes.length >= 2) {
    // -2 because -1 is the current route
    previousRouteName = routes[routes.length - 2].name
  }
  // Immediately open an sms link to text Walkthrough's OpenPhone number.
  // See https://my.openphone.com/inbox/PNbZoaOtFK.
  // Then navigate to the main screen so there's no strange blank screen left
  // in the browser after the text message opens. Use replace() instead of navigate()
  // or goBack() so the user can't get back to this redirect.
  React.useLayoutEffect(() => {
    navigateToHref('sms://+16283333452')
    if (isNonNullish(previousRouteName)) {
      navigation.replace(previousRouteName)
    } else {
      navigation.replace('MainScreen', {
        articleKey: ArticleKey.WELCOME
      })
    }
  }, [])
  return <></>
}
