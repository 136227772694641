import React from 'react'
import { ScrollView, useWindowDimensions, View } from 'react-native'
import { articleWidthDependentStyle, articleStyle } from './article-page.style'
import { ParamListBase, useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { THEME } from '../../constants'
import { BackButton } from '../../util/back-button'
import { ArticleKey } from '../main'
import { shouldUseMobileDesign } from '../../util/use-mobile-design'

// A component used to render an article on its own screen.
// This is used for content we don't want to include in the scrolling
// list of articles on the main screen.
export function ArticlePage (props: { children: React.ReactNode }): JSX.Element {
  const navigation =
    useNavigation<NativeStackNavigationProp<ParamListBase, string>>()
  const useMobileDesign = shouldUseMobileDesign(useWindowDimensions().width)

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <BackButton
          color={THEME.color.onSurface}
          handleOnPress={() => {
            if (navigation.canGoBack()) {
              navigation.goBack()
            } else {
              navigation.navigate('MainScreen', {
                articleKey: ArticleKey.WELCOME
              })
            }
          }}
        />
      )
    })
  }, [navigation])

  return (
    <>
      <View style={articleStyle.background}>
        {useMobileDesign ? null : <View style={articleStyle.accent} />}
        <ScrollView>
          <View
            style={
              articleWidthDependentStyle(useMobileDesign).articleBackground
            }
          >
            {props.children}
          </View>
        </ScrollView>
      </View>
    </>
  )
}
