import { getTimeDependentTheme } from './themes/time-dependent-theme'

/// ///////////////////////////////////////////////////////////////////////////////////////////////
// Constants for design theme.
/// ///////////////////////////////////////////////////////////////////////////////////////////////

export const THEME = getTimeDependentTheme()

export const MAX_WIDTH_FOR_MOBILE_DESIGN = 600

export const MIN_WIDTH_FOR_TWO_COLUMN_ARTICLES = 1220

/// ///////////////////////////////////////////////////////////////////////////////////////////////
// Constants for stripe links.
/// ///////////////////////////////////////////////////////////////////////////////////////////////

// Stripe will forward the utm params to the financial plan purchase confirmation page, so that we
// can track conversions in Plausible (as well as views of this page). Stripe unfortunately
// doesn't seem to show analytics for the utm params for the Stripe payment page itself, which
// is why we split out organic and paid advertising Stripe links here.
// See https://stripe.com/docs/payment-links/url-parameters#track-campaigns-with-utm-codes for documentation.

// Standard stripe link for purchasing financial plans. UTM params are appended to this link.
export const BUY_FINANCIAL_PLAN_STRIPE_LINK_STANDARD =
  'https://buy.stripe.com/00geX5gCQ23p4EMbIJ'

// Stripe link for purchasing financial plans from paid advertising campaigns. UTM params are appended
// to this link.
export const BUY_FINANCIAL_PLAN_STRIPE_LINK_PAID_ADVERTISING: string =
  'https://buy.stripe.com/cN23en1HW5fBc7e3ce'

/// ///////////////////////////////////////////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////////////////////////////////////////
// Constants for typeform links.
// NOTE: AS OF JAN 2024, WE HAVE STOPPED PAYING FOR TYPEFORM AND DO NOT EXPECT THESE STRINGS TO WORK
/// ///////////////////////////////////////////////////////////////////////////////////////////////

export const WAITLIST_FOR_FREE_FINANCIAL_PLAN_POPUP_TYPEFORM_ID: string =
  'MqA0TBtA'

export const HOW_DO_YOU_STACK_UP_EMBEDDED_TYPEFORM_ID: string = 'TcuOq5IP'

/// ///////////////////////////////////////////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////////////////////////////////////////
// Constants for Google Survey links.
/// ///////////////////////////////////////////////////////////////////////////////////////////////

export const WAITLIST_FOR_FREE_FINANCIAL_PLAN: string = 'https://forms.gle/WLdZhb1iGw7CPrmR7'

/// ///////////////////////////////////////////////////////////////////////////////////////////////

// IMPORTANT: If we add more paid advertising sources, we need to update this set.
// See the Inbound URL Tracking spreadsheet for the list of paid advertising (and other) sources we're using:
// https://docs.google.com/spreadsheets/d/1sfQo8g6FXKgJwfVWGk8I4cI0jHcDDrS00uyGoef0X0g/edit#gid=0
export const PAID_ADVERTISING_UTM_SOURCES = new Set<String>([
  'google',
  'meta',
  'tiktok'
])

/// ///////////////////////////////////////////////////////////////////////////////////////////////
// Constants for product links.
/// ///////////////////////////////////////////////////////////////////////////////////////////////

export const APP_LINK: string = 'https://app.walkthrough.co'
