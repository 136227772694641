import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { THEME } from '../constants'
import { textStyle } from '../themes/global-styles.style'

export const widthDependentWelcomeStyle = (
  useMobileDesign: boolean
): {
  buttonContainer: ViewStyle
  firstButton: ViewStyle
  middleButton: ViewStyle
  lastButton: ViewStyle
  buttonText: TextStyle
} =>
  StyleSheet.create({
    buttonContainer: {
      flexDirection: useMobileDesign ? 'column' : 'row',
      flexWrap: 'wrap',
      justifyContent: 'center'
    },
    firstButton: {
      marginRight: useMobileDesign ? 0 : THEME.spacing.marginMedium,
      marginTop: THEME.spacing.marginMedium
    },
    middleButton: {
      marginRight: useMobileDesign ? 0 : THEME.spacing.marginMedium,
      marginTop: useMobileDesign
        ? THEME.spacing.marginSmall
        : THEME.spacing.marginMedium
    },
    lastButton: {
      marginTop: useMobileDesign
        ? THEME.spacing.marginSmall
        : THEME.spacing.marginMedium
    },
    buttonText: {
      ...textStyle.largeText,
      ...textStyle.boldText,
      color: THEME.color.background
    }
  })
