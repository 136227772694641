import { ImageStyle, StyleSheet } from 'react-native'

export const imageStyle = StyleSheet.create({
  photoContainer: {
    alignItems: 'flex-start'
  }
})

export const imageSizeStyle = (
  width: number,
  // This is the width:height aspect ratio as a fraction: width/height.
  // This is used to scale the height given the width.
  aspectRatio: number
): {
  image: ImageStyle
} =>
  StyleSheet.create({
    image: {
      resizeMode: 'contain',
      alignItems: 'center',
      width: width,
      maxWidth: '100%',
      height: width / aspectRatio
    }
  })
