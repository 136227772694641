import React from 'react'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { AppStackParamList } from '../App'
import { ArticleKey } from './main'

export function NotFoundScreen ({
  navigation
}: NativeStackScreenProps<AppStackParamList, 'NotFoundScreen'>): JSX.Element {
  // Immediately redirect to main screen.  replace() instead of navigate() so the user can't get back to this redirect.
  React.useLayoutEffect(() => {
    navigation.replace('MainScreen', {
      articleKey: ArticleKey.WELCOME
    })
  }, [])
  return <></>
}
