import React, { useRef } from 'react'
import {
  View,
  useWindowDimensions,
  Platform,
  ViewToken,
  Pressable,
  FlatList
} from 'react-native'
import { mainStyle, screenWidthSpecificStyle } from './main.style'
import { THEME } from '../constants'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { NavigationMenu } from './navigation-menu/navigation-menu'
import { NavigationMenuButtonProps } from './navigation-menu/navigation-menu-button'
import {
  ContentImagePair,
  ContentImagePairData
} from './articles/content-image-pair'
import { isNonNullish } from 'global-utils'
import { whatIsWalkthroughShortCopy } from './articles/content/what-is-walkthrough'
import { financialPlanShortCopy } from './articles/content/financial-plan'
import { partnerWithUsCopy } from './articles/content/partner-with-us'
import { appShortCopy } from './articles/content/app'
import { incentivesShortCopy } from './articles/content/incentives'
import { monetizationShortCopy } from './articles/content/monetization'
import { teamShortCopy } from './articles/content/team'
import OneLineLogo from '../assets/one-line-logo'
import { shouldUseMobileDesign } from '../util/use-mobile-design'
import { navigateToHref } from '../util/anchor'
import { AppStackParamList } from '../App'
import { adviceShortCopy } from './articles/content/our-advice'
import { Banner } from '../util/banner'
import { ImageById, ImageId } from '../util/image'
import { welcomeContent } from './articles/content/welcome'
import { textWalkthroughShortCopy } from './articles/content/text-walkthrough'

// Article keys are use to identify articles & article sections. The article section
// always has the same key as the first article in that sections. These keys are
// also used for navigating to a particular section, so we use camel case strings for
// nice looking URLs.
export enum ArticleKey {
  WELCOME = 'welcome',
  HOW_DO_YOU_STACK_UP = 'howDoYouStackUp',
  WHAT_WE_DO = 'whatWeDo',
  OUR_ADVICE = 'ourAdvice',
  MEET_YUYU = 'meetYuyu',
  FINANCIAL_PLAN = 'financialPlan',
  TEXT = 'text',
  PARTNER_WITH_US = 'partnerWithUs',
  APP = 'app',
  INCENTIVES = 'incentives',
  HOW_WE_MAKE_MONEY = 'monetization',
  TEAM = 'team',
  MORE = 'more',
  SECURITY_POLICY = 'securityPolicy',
  PRIVACY_POLICY = 'privacyPolicy',
  TERMS_OF_SERVICE = 'termsOfService',
  FORM_CRS = 'formCRS',
  FORM_ADV = 'formADV',
  FORM_ADV_PART_2A = 'formADVPart2A',
  DATA_PROTECTION = 'dataProtection',
  LESS = 'less',
  EMPTY_BOTTOM = 'emptyBottom'
}

export function MainScreen ({
  route,
  navigation
}: NativeStackScreenProps<AppStackParamList, 'MainScreen'>): JSX.Element {
  if (Platform.OS !== 'web') {
    throw new Error(
      'Landing page website should not ever be deployed as a native app.'
    )
  }

  const useMobileDesign = shouldUseMobileDesign(useWindowDimensions().width)

  const [navigationMenuButtonSelected, setNavigationMenuButtonSelected] =
    React.useState<string | null>(null)
  const [navigationMenuIsOpen, setNavigationMenuIsOpen] = React.useState(false)
  const [showMoreButtons, setShowMoreButtons] = React.useState(false)
  const [hasScrolledToInitialArticle, setHasScrolledToInitialArticle] =
    React.useState(false)

  const articleFlatListRef = useRef<FlatList>(null)

  const articles: ContentImagePairData[] = [
    {
      key: ArticleKey.WELCOME,
      isFullWidth: true,
      content: welcomeContent(
        useMobileDesign,
        () => scrollToArticle(ArticleKey.TEXT),
        () => scrollToArticle(ArticleKey.HOW_DO_YOU_STACK_UP),
        route.params.utm_source,
        route.params.utm_campaign
      )
    },
    {
      key: ArticleKey.WHAT_WE_DO,
      content: whatIsWalkthroughShortCopy(
        route.params.utm_source,
        route.params.utm_campaign
      ),
      color: THEME.color.surfaceTwo
    },
    {
      key: ArticleKey.OUR_ADVICE,
      content: adviceShortCopy
    },
    {
      key: ArticleKey.FINANCIAL_PLAN,
      content: financialPlanShortCopy(
        route.params.utm_source,
        route.params.utm_campaign
      ),
      image: <ImageById imageId={ImageId.FINANCIAL_PLAN} />,
      color: THEME.color.surfaceTwo
    },
    {
      key: ArticleKey.TEXT,
      content: textWalkthroughShortCopy
    },
    {
      key: ArticleKey.APP,
      content: appShortCopy(route.params.utm_source, route.params.utm_campaign),
      image: <ImageById imageId={ImageId.APP_MAIN_SCREEN} />,
      color: THEME.color.surfaceTwo
    },
    {
      key: ArticleKey.PARTNER_WITH_US,
      content: partnerWithUsCopy(useMobileDesign)
    },
    {
      key: ArticleKey.INCENTIVES,
      content: incentivesShortCopy,
      color: THEME.color.surfaceTwo
    },
    {
      key: ArticleKey.HOW_WE_MAKE_MONEY,
      content: monetizationShortCopy
    },
    {
      key: ArticleKey.TEAM,
      content: teamShortCopy,
      image: <ImageById imageId={ImageId.TEAM} />,
      color: THEME.color.surfaceTwo
    },
    {
      key: ArticleKey.EMPTY_BOTTOM,
      isFullWidth: true,
      content: (
        <View style={{ height: useWindowDimensions().height / 6 }}></View>
      )
    }
  ]

  const articleKeySectionIndexMap = new Map(
    articles.map((article, index) => {
      return [article.key, index]
    })
  )

  const navigationMenuButtons: NavigationMenuButtonProps[] = [
    {
      key: ArticleKey.HOW_DO_YOU_STACK_UP,
      handleOnPress: () => {
        scrollToArticle(ArticleKey.HOW_DO_YOU_STACK_UP)
      },
      text: 'How do you stack up?',
      isSelected:
        navigationMenuButtonSelected === ArticleKey.HOW_DO_YOU_STACK_UP
    },
    {
      key: ArticleKey.WHAT_WE_DO,
      handleOnPress: () => {
        scrollToArticle(ArticleKey.WHAT_WE_DO)
      },
      text: 'What We Do',
      isSelected: navigationMenuButtonSelected === ArticleKey.WHAT_WE_DO
    },
    {
      key: ArticleKey.OUR_ADVICE,
      handleOnPress: () => {
        scrollToArticle(ArticleKey.OUR_ADVICE)
      },
      text: 'Our Advice',
      isSelected: navigationMenuButtonSelected === ArticleKey.OUR_ADVICE
    },
    {
      key: ArticleKey.FINANCIAL_PLAN,
      handleOnPress: () => scrollToArticle(ArticleKey.FINANCIAL_PLAN),
      text: 'Get a Financial Plan',
      isSelected: navigationMenuButtonSelected === ArticleKey.FINANCIAL_PLAN
    },
    {
      key: ArticleKey.TEXT,
      handleOnPress: () => scrollToArticle(ArticleKey.TEXT),
      text: 'Ask a Question',
      isSelected: navigationMenuButtonSelected === ArticleKey.TEXT
    },
    {
      key: ArticleKey.APP,
      handleOnPress: () => scrollToArticle(ArticleKey.APP),
      text: 'The App',
      isSelected: navigationMenuButtonSelected === ArticleKey.APP
    },
    {
      key: ArticleKey.PARTNER_WITH_US,
      handleOnPress: () => scrollToArticle(ArticleKey.PARTNER_WITH_US),
      text: 'Partner With Us',
      isSelected: navigationMenuButtonSelected === ArticleKey.PARTNER_WITH_US
    },
    {
      key: ArticleKey.INCENTIVES,
      handleOnPress: () => scrollToArticle(ArticleKey.INCENTIVES),
      text: 'Our Incentives',
      isSelected: navigationMenuButtonSelected === ArticleKey.INCENTIVES
    },
    {
      key: ArticleKey.HOW_WE_MAKE_MONEY,
      handleOnPress: () => scrollToArticle(ArticleKey.HOW_WE_MAKE_MONEY),
      text: 'How We Make Money',
      isSelected: navigationMenuButtonSelected === ArticleKey.HOW_WE_MAKE_MONEY
    },
    {
      key: ArticleKey.TEAM,
      handleOnPress: () => scrollToArticle(ArticleKey.TEAM),
      text: 'Founders',
      isSelected: navigationMenuButtonSelected === ArticleKey.TEAM
    },
    {
      key: ArticleKey.MORE,
      handleOnPress: () => {
        setShowMoreButtons(true)
      },
      text: 'More ...',
      isSelected: navigationMenuButtonSelected === ArticleKey.MORE,
      style: { display: showMoreButtons ? 'none' : 'flex' }
    },
    {
      key: ArticleKey.SECURITY_POLICY,
      handleOnPress: () => {
        navigation.navigate('SecurityPolicyScreen')
      },
      text: 'Security Policy',
      isSelected: navigationMenuButtonSelected === ArticleKey.SECURITY_POLICY,
      style: { display: showMoreButtons ? 'flex' : 'none' }
    },
    {
      key: ArticleKey.PRIVACY_POLICY,
      handleOnPress: () => {
        navigation.navigate('PrivacyPolicyScreen')
      },
      text: 'Privacy Policy',
      isSelected: navigationMenuButtonSelected === ArticleKey.PRIVACY_POLICY,
      style: { display: showMoreButtons ? 'flex' : 'none' }
    },
    {
      key: ArticleKey.TERMS_OF_SERVICE,
      handleOnPress: () => {
        navigation.navigate('TermsOfServiceScreen')
      },
      text: 'Terms of Service',
      isSelected: navigationMenuButtonSelected === ArticleKey.TERMS_OF_SERVICE,
      style: { display: showMoreButtons ? 'flex' : 'none' }
    },
    {
      key: ArticleKey.DATA_PROTECTION,
      handleOnPress: () => {
        navigation.navigate('ProtectDataScreen')
      },
      text: 'Data Protection',
      isSelected: navigationMenuButtonSelected === ArticleKey.DATA_PROTECTION,
      style: { display: showMoreButtons ? 'flex' : 'none' }
    },
    {
      key: ArticleKey.FORM_CRS,
      handleOnPress: () => {
        navigation.navigate('FormCrsScreen')
      },
      text: 'Form CRS',
      isSelected: navigationMenuButtonSelected === ArticleKey.FORM_CRS,
      style: { display: showMoreButtons ? 'flex' : 'none' }
    },
    {
      key: ArticleKey.FORM_ADV,
      handleOnPress: () => {
        navigateToHref('https://docsend.com/view/tm5xwcxs684gejhu')
      },
      text: 'Form ADV',
      isSelected: navigationMenuButtonSelected === ArticleKey.FORM_ADV,
      style: { display: showMoreButtons ? 'flex' : 'none' }
    },
    {
      key: ArticleKey.FORM_ADV_PART_2A,
      handleOnPress: () => {
        navigateToHref('https://docsend.com/view/yva4fqabx7rmerq7')
      },
      text: 'Form ADV, Part 2A',
      isSelected: navigationMenuButtonSelected === ArticleKey.FORM_ADV_PART_2A,
      style: { display: showMoreButtons ? 'flex' : 'none' }
    },
    {
      key: ArticleKey.LESS,
      handleOnPress: () => {
        setShowMoreButtons(false)
      },
      text: 'Show less ...',
      isSelected: navigationMenuButtonSelected === ArticleKey.LESS,
      style: { display: showMoreButtons ? 'flex' : 'none' }
    }
  ]

  function scrollToArticle (
    articleKey: ArticleKey,
    animated: boolean = true
  ): void {
    if (useMobileDesign) {
      setNavigationMenuIsOpen(false)
    }
    setNavigationMenuButtonSelected(articleKey)
    articleFlatListRef?.current?.scrollToIndex({
      animated: animated,
      index: articleKeySectionIndexMap.get(articleKey) ?? 0,
      viewOffset: useMobileDesign
        ? THEME.spacing.marginLarge * 2
        : THEME.spacing.marginLarge
    })
  }

  // If the articleKey in the route params updates, scroll to the correct article.
  React.useEffect(() => {
    scrollToArticle(route.params.articleKey, false)
  }, [route.params.articleKey])

  const viewabilityConfig = {
    itemVisiblePercentThreshold: 20,
    minimumViewTime: 400
  }
  function onViewableItemsChanged (info: {
    viewableItems: ViewToken[]
    changed: ViewToken[]
  }): void {
    if (info.viewableItems.length > 0) {
      const key = info.viewableItems[0]?.item.key
      if (isNonNullish(key)) {
        setNavigationMenuButtonSelected(key)
      }
    }
  }
  const viewabilityConfigCallbackPairs = useRef([
    { viewabilityConfig, onViewableItemsChanged }
  ])

  const screenWidthStyle = screenWidthSpecificStyle(
    useMobileDesign,
    navigationMenuIsOpen
  )

  // Separator used to separate multiple ContentImagePair components in the same article section in a SectionList.
  function ArticleSeparator (): JSX.Element {
    return (
      <View
        style={{
          marginVertical: THEME.spacing.marginSmall
        }}
      ></View>
    )
  }

  return (
    <>
      <Banner
        utmSource={route.params.utm_source}
        utmCampaign={route.params.utm_campaign}
      />
      <View style={mainStyle.outerContainer}>
        <View style={screenWidthStyle.header}>
          <Pressable
            onPress={() => {
              navigation.navigate('MainScreen', {
                articleKey: route.params.articleKey
              })
            }}
          >
            <OneLineLogo />
          </Pressable>
        </View>
        <View style={screenWidthStyle.navigationMenuContainer}>
          <NavigationMenu
            navigationMenuButtons={navigationMenuButtons}
            menuIsOpen={navigationMenuIsOpen}
            setMenuIsOpen={setNavigationMenuIsOpen}
          />
        </View>
        <FlatList
          ref={articleFlatListRef}
          style={screenWidthStyle.articleScrollContainer}
          data={articles}
          renderItem={({ item }) => (
            <ContentImagePair
              key={item.key}
              isFullWidth={item.isFullWidth}
              content={item.content}
              color={item.color}
              image={item.image}
            />
          )}
          onContentSizeChange={() => {
            if (!hasScrolledToInitialArticle) {
              scrollToArticle(route.params.articleKey, false)
              setHasScrolledToInitialArticle(true)
            }
          }}
          keyExtractor={(item) => item.key}
          viewabilityConfigCallbackPairs={
            viewabilityConfigCallbackPairs.current
          }
          onScrollToIndexFailed={() => {}}
          // Render all of the items so we can scroll to any of them.
          // We use a static 'magic number' here because if we calculate the # of total
          // article items (using map/reduce to get the actual # of items inside the sections),
          // it doesn't seem to calculate in time and not all of the items render. We don't
          // expect to ever have > 100 items on this page - if we do, we need to update this
          // number.
          initialNumToRender={100}
          ItemSeparatorComponent={ArticleSeparator}
        />
      </View>
    </>
  )
}
