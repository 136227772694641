import React from 'react'
import {
  Platform,
  useWindowDimensions,
  View,
  Text,
  ScrollView
} from 'react-native'
import { shouldUseMobileDesign } from '../util/use-mobile-design'
import {
  euphoriaStyle,
  screenWidthSpecificStyle
} from './euphoria-partnership.style'
import { textStyle } from '../themes/global-styles.style'
import { ParamListBase } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { ImageById, ImageId } from '../util/image'
import { WelcomeButtons } from '../util/welcome-buttons'
import { ExpandableCard } from '../util/expandable-card'
import { ArticleKey } from './main'
import { NavigationAnchor } from '../util/navigation-anchor'

export function EuphoriaPartnershipScreen (props: {
  navigation: NativeStackNavigationProp<ParamListBase, string>
}): JSX.Element {
  if (Platform.OS !== 'web') {
    throw new Error('Landing site should never be deployed as a native app.')
  }

  const [surveyIsOpen, setSurveyIsOpen] = React.useState(false)

  const useMobileDesign = shouldUseMobileDesign(useWindowDimensions().width)
  const screenWidthStyle = screenWidthSpecificStyle(useMobileDesign)

  return (
    <View style={screenWidthStyle.outerContainer}>
      <ScrollView contentContainerStyle={screenWidthStyle.scrollView}>
        <View style={euphoriaStyle.logoContainer}>
          <ImageById imageId={ImageId.EUPHORIA_LOGO} />
          <ImageById imageId={ImageId.WALKTHROUGH_LOGO_EUPHORIA_SIZE} />
        </View>
        <View style={euphoriaStyle.titleContainer}>
          <Text
            style={[
              useMobileDesign
                ? textStyle.extraLargeText
                : textStyle.extraExtraLargeText,
              textStyle.monospaceBoldItalic,
              euphoriaStyle.title
            ]}
          >
            EUPHORIA{useMobileDesign ? '\n' : ' '}X
            {useMobileDesign ? '\n' : ' '}WALKTHROUGH
          </Text>
        </View>
        <WelcomeButtons
          buttonColor={'#4063DD'}
          handleOnPressDesktopAskAQuestion={() =>
            props.navigation.navigate('MainScreen', {
              articleKey: ArticleKey.TEXT,
              utm_source: 'euphoria'
            })
          }
          utmSource={'euphoria'}
        />
        <View style={screenWidthStyle.contentContainer}>
          <Text
            style={[
              textStyle.largeText,
              textStyle.monospaceItalic,
              euphoriaStyle.text
            ]}
          >
            Euphoria is partnering with{' '}
            <NavigationAnchor
              text="Walkthrough"
              handlePress={(nav) => {
                nav.navigate('MainScreen')
              }}
            />{' '}
            to give you, beautiful soul, free financial advice. Walkthrough
            understands how painful finances can be and makes it easier to be in
            control of your money. We give financial advice, tailored to you, in
            a way that's easy to understand and take action on. And right now,
            it's all free!
            {'\n\n'}We are offering:
            {'\n'}• comprehensive financial plans
            {'\n'}• 1-on-1 financial advice over text (text 628-333-3452 to talk
            to an expert for free!)
            {'\n'}• an automated financial advising app{'\n\n'}
          </Text>
          <ExpandableCard
            style={[euphoriaStyle.expandableCard]}
            title={'What makes Walkthrough right for me?'}
            content={
              <Text style={[textStyle.regularText]}>
                You deserve financial peace.{'\n\n'}Walkthrough is for anyone
                who wants a friend on their financial journey. We are here to
                help you on the path towards financial wellbeing. We can help
                you with everything from paying off debt to minimizing taxes to
                figuring out whether it's better to rent or buy a home. Like
                Kate, the Founder of Euphoria, the folks at Walkthrough care
                about you. We want to help you achieve your financial goals.
              </Text>
            }
          />
          <ExpandableCard
            style={[euphoriaStyle.expandableCard]}
            title={'How is Euphoria connected to Walkthrough?'}
            content={
              <Text style={[textStyle.regularText]}>
                Walkthrough, like Euphoria, was part of the AWS Impact
                Accelerator (this makes us either cousins or siblings!) and is
                also a fledgling start-up. We asked Kate to share with y’all
                that we are giving away our services for free!
              </Text>
            }
          />
          <ExpandableCard
            style={[euphoriaStyle.expandableCard]}
            title={'Why trust Walkthrough?'}
            content={
              <Text style={textStyle.regularText}>
                Walkthrough's advice is accessible, down-to-earth, and backed by
                the leading financial research out there. We're a Registered
                Investment Advisor and are purpose-built to give ethical,
                unconflicted advice. Read more about our{' '}
                <NavigationAnchor
                  text={'incentives'}
                  handlePress={(nav) => {
                    nav.navigate('IncentivesScreen')
                  }}
                />{' '}
                and{' '}
                <NavigationAnchor
                  text={'how we make money'}
                  handlePress={(nav) => {
                    nav.navigate('MonetizationScreen')
                  }}
                />
                .
              </Text>
            }
          />
          <ExpandableCard
            style={[euphoriaStyle.expandableCard]}
            title={'Why is Walkthrough giving away things for free?'}
            content={
              <Text style={[textStyle.regularText]}>
                Walkthrough is giving away things for free to get more feedback
                on our products & test out new ideas. We don't expect our
                financial plans, texting service and app to always be free, but
                they are right now!
              </Text>
            }
          />
        </View>
      </ScrollView>
    </View>
  )
}
