import React from 'react'
import { Text } from 'react-native'
import { APP_LINK, THEME } from '../../../constants'
import { buttonStyle, textStyle } from '../../../themes/global-styles.style'
import { Anchor } from '../../../util/anchor'
import {
  NavigationAnchor,
  NavigationButton
} from '../../../util/navigation-anchor'
import { ArticlePage } from '../article-page'
import { appendUtmParamsToLink } from '../../../util/utm-params'

export const whatIsWalkthroughShortCopy: (
  utmSource?: string,
  utmCampaign?: string
) => React.ReactNode = (utmSource, utmCampaign) => {
  return (
    <>
      <Text style={[textStyle.extraLargeText, textStyle.serifBold]}>
        What We Do
      </Text>
      <Text style={textStyle.largeText}>
        {'\n'}Walkthrough is financial advice like you haven't seen before. We
        help you find the next best step in your financial journey, actually
        teach you why what you're doing is important, and give you all the tools
        you need to take action.{'\n\n'}
        We're a lot like a human financial advisor, except we're way cheaper and
        focused on making sure you actually understand what's going on. Or like
        your favorite account tracking app, if it gave you expert advice about
        your whole financial situation instead of just telling you that you
        spent too much on socks this year (and then showing you ads for socks).
        {'\n\n'}
        <Text style={textStyle.boldText}>Take your first step</Text> —{' '}
        <NavigationAnchor
          text={'get a financial plan'}
          handlePress={(nav) => {
            nav.navigate('FinancialPlanScreen', {
              utm_source: utmSource,
              utm_campaign: utmCampaign
            })
          }}
        />{' '}
        from Walkthrough or{' '}
        <Anchor
          href={appendUtmParamsToLink(
            APP_LINK,
            utmSource,
            utmCampaign,
            undefined,
            'whatIsWalkthroughShortCopyButton'
          )}
          text="open the app"
        />
        .{'\n\n'}
      </Text>
      <NavigationButton
        text={'Read more'}
        handlePress={(nav) => {
          nav.navigate('WhatIsWalkthroughScreen')
        }}
        buttonStyle={[buttonStyle(THEME.color.outline).underlineButton]}
      />
    </>
  )
}

export const whatIsWalkthroughCopy = (
  <>
    <Text style={textStyle.largeText}>
      <Text style={textStyle.boldText}>
        Walkthrough is financial advice like you haven't seen before.
      </Text>{' '}
      We help you find the next best step in your financial journey, actually
      teach you why what you're doing is important, and give you all the tools
      you need to take action. No more "this stuff is too complicated for you —
      just trust me" from your advisor, and no more ridiculously overpriced
      services. If you don't have a financial advisor already, don't worry.
      Walkthrough was made for you and the 70% of people in the U.S. who don't
      have access to financial planning. And because financial advice is taken
      by humans, our advice is built for you to understand and feel good about.
      {'\n\n'}
      <Text style={textStyle.boldText}>
        We're really good at answering questions about your specific financial
        situation.
      </Text>
      {'\n'}
      Things like:
      {'\n'}• "Given my situation, what should my investment allocation be?
      (Also, what does that mean?)"
      {'\n'}• "Should I be investing or paying off my debt? (And why do I
      care?)"
      {'\n'}• "Should I invest in my 401k or a brokerage account? (What's a
      401k, again?)"
      {'\n'}• "Help"*
      {'\n\n'}
      <Text style={textStyle.boldText}>
        Walkthrough is built to do exactly what's best for you.
      </Text>{' '}
      We're a{' '}
      <Anchor
        text={'Registered Investment Advisor with the SEC'}
        href={'https://adviserinfo.sec.gov/firm/summary/318172'}
      />{' '}
      and are built from the ground up to give ethical and unbiased advice. Read
      more about{' '}
      <NavigationAnchor
        text={'Our Incentives'}
        handlePress={(nav) => {
          nav.navigate('IncentivesScreen')
        }}
      />{' '}
      and{' '}
      <NavigationAnchor
        text={'How We Make Money'}
        handlePress={(nav) => {
          nav.navigate('MonetizationScreen')
        }}
      />
      .{'\n\n'}
    </Text>
    {'\n\n'}
    <Text style={textStyle.italicText}>* technically not a question.</Text>
  </>
)

export function WhatIsWalkthroughScreen (): JSX.Element {
  return <ArticlePage>{whatIsWalkthroughCopy}</ArticlePage>
}
