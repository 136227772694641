import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const Plant = (props: SvgProps): JSX.Element => (
  <Svg
    width='100%'
    height='100%'
    viewBox='0 0 393 400'
    fill='none'
    preserveAspectRatio='xMaxYMax slice'
    {...props}
  >
    <Path
      fill="#fff"
      d="M214.875 215.223c51.434-17.113 116.704-58.178 101.229-104.69-6.727-20.216-55.654-78.551-92.053-91.29-47.347-16.568-121.56 8.298-140.432 46.726-16.872 34.355 8.762 92.999 10.055 95.912.041.093.066.162.099.259 15.541 46.415 69.722 70.179 121.102 53.083Z"
    />
    <Path
      stroke="#628474"
      strokeLinecap="round"
      strokeWidth={4}
      d="M197 275c-1-36.333-7.6-114-26-134"
    />
    <Path
      fill="#EC6B54"
      d="M196.5 335c32.399 0 58.85-25.257 60.426-57 .082-1.655-1.269-3-2.926-3H139c-1.657 0-3.008 1.345-2.926 3 1.576 31.743 28.027 57 60.426 57Z"
    />
    <Path
      fill="#628474"
      d="M222.337 236.76c-8.575 7.829-27.808 10.522-27.808 10.522s3.434-17.463 9.971-26.282c8.455-11.406 30.537-19.731 30.537-19.731s-1.828 25.566-12.7 35.491ZM183.879 140.841c1.714 7.793-3.279 20.167-3.279 20.167s-11.47-6.82-14.827-14.058c-4.256-9.178 1.355-25.87 1.355-25.87s14.578 9.881 16.751 19.761ZM213.733 186.925c-6.624 8.638-24.214 13.806-24.214 13.806S194.24 186.065 199 177.5c6.1-10.975 19.738-25.379 19.738-25.379s3.351 23.908-5.005 34.804Z"
    />
    <Path
      fill="#628474"
      d="M165.595 199.157c8.795 4.344 25.087 1.228 25.087 1.228s-5.357-12.809-11.807-18.406c-9.468-8.218-30.779-9.128-30.779-9.128s6.437 20.842 17.499 26.306ZM179.811 225.802c9.11 6.301 18.626 21.394 18.626 21.394S179.413 249.483 169 244.5c-13.032-6.236-23.101-28.894-23.101-28.894s22.539 2.328 33.912 10.196Z"
    />
  </Svg>
)
export default Plant
