import { THEME } from '../../../constants'
import { Text, View } from 'react-native'
import { textStyle } from '../../../themes/global-styles.style'
import Plant from '../../../assets/plant'
import { welcomeStyle, widthDependentWelcomeStyle } from './welcome.style'
import { WelcomeButtons } from '../../../util/welcome-buttons'

export const welcomeContent: (
  useMobileDesign: boolean,
  handleOnPressDesktopAskAQuestion: () => void,
  handleOnPressHowDoYouStackUp: () => void,
  utmSource?: string,
  utmCampaign?: string
) => React.ReactNode = (
  useMobileDesign,
  handleOnPressDesktopAskAQuestion,
  handleOnPressHowDoYouStackUp,
  utmSource,
  utmCampaign
) => {
  const widthDependentStyle = widthDependentWelcomeStyle(useMobileDesign)
  return (
    <View style={widthDependentStyle.container}>
      <Text
        style={[
          useMobileDesign
            ? textStyle.extraLargeText
            : textStyle.extraExtraLargeText,
          textStyle.serifBold
        ]}
      >
        Make your next financial decision{'\n'}with a friend*
      </Text>
      <Text
        style={[
          useMobileDesign ? textStyle.smallText : textStyle.regularText,
          welcomeStyle.subtitle
        ]}
      >
        *who happens to be a Registered Investment Advisor with the SEC
      </Text>
      <WelcomeButtons
        utmSource={utmSource}
        utmCampaign={utmCampaign}
        handleOnPressDesktopAskAQuestion={handleOnPressDesktopAskAQuestion}
        buttonColor={THEME.color.onSurface}
      />
      <View style={widthDependentStyle.plantImageContainer}>
        <Plant />
      </View>
    </View>
  )
}
