import * as React from "react"
import Svg, { SvgProps, G, Path, Defs, ClipPath } from "react-native-svg"

const LandscapeVertical = (props: SvgProps): JSX.Element => (
  <Svg
    width='100%'
    height='100%'
    viewBox='0 0 390 844'
    fill="none"
    preserveAspectRatio='xMidYMid slice'
    {...props}
  >
    <G clipPath="url(#a)">
      <Path fill="#C8F5FF" d="M0 0h390v844H0z" />
      <Path
        d="M233.781 722.909c-26.863 3.198-62.36 48.236-76.751 70.356H516V678.938c-3.198 3.198-14.071 10.073-31.979 11.992-22.386 2.398-68.756-18.388-95.939-11.992-27.182 6.395-35.177 54.365-63.159 59.961-27.983 5.597-57.564-19.987-91.142-15.99Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M164.225 808.455c14.391-22.119 49.888-67.157 76.751-70.355 33.579-3.998 63.16 21.586 91.142 15.99 27.982-5.597 35.977-53.566 63.159-59.962 27.183-6.396 73.553 14.391 95.939 11.992 17.909-1.918 28.782-8.794 31.98-11.992"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M170.621 825.244c14.391-22.119 49.888-67.157 76.751-70.355 33.579-3.998 63.16 21.586 91.142 15.99 27.982-5.597 35.977-53.566 63.159-59.962 27.183-6.396 73.553 14.391 95.939 11.992 17.909-1.919 28.782-8.794 31.98-11.992"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M178.617 842.033c14.391-22.119 49.888-67.157 76.751-70.355 33.578-3.997 63.159 21.586 91.141 15.99 27.983-5.597 35.977-53.566 63.16-59.962 27.183-6.396 73.553 14.391 95.939 11.992 17.908-1.918 28.781-8.794 31.979-11.992"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M185.012 858.823c14.391-22.119 49.888-67.157 76.751-70.355 33.578-3.998 63.159 21.586 91.141 15.99 27.983-5.597 35.977-53.566 63.16-59.962 27.183-6.396 73.553 14.391 95.939 11.992 17.908-1.919 28.781-8.794 31.979-11.992"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M193.806 876.412c14.391-22.12 49.888-67.158 76.751-70.356 33.579-3.997 63.16 21.587 91.142 15.99 27.982-5.596 35.977-53.566 63.16-59.961 27.182-6.396 73.553 14.39 95.938 11.992 17.909-1.919 28.782-8.795 31.98-11.992"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M200.202 894c14.39-22.119 49.888-67.157 76.751-70.355 33.578-3.997 63.159 21.586 91.141 15.99 27.982-5.597 35.977-53.566 63.16-59.962 27.183-6.396 73.553 14.391 95.939 11.992 17.908-1.918 28.781-8.794 31.979-11.992"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M279.116 396c-10.205 0-28.063 10.551-45.986 17.637-20.092 7.944-45.422 28.693-101.859 28.693-60.897 0-90.566-9.28-94.423-10.296-3.858-1.016-17.593-6.684-23.093-6.684-11.55 0-20.35 16.98-36.3 6.684-12.149-7.841-50.602 10.296-73.152 10.296-24.323 0-53.351-9.195-53.351-9.195v52.815h705.111v-41.549s-11.034-14.811-41.251-15.951c-18.507-.698-49.724 32.451-77.551 32.451-24.2 0-37.748-16.33-53.351-25.101-9.652-5.426-11.324-14.775-24.2-14.775s-22.385-10.418-32.318-10.418c-19.766 0-21.145-14.607-48.276-14.607Z"
        fill="#C3EC81"
        stroke="#202232"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <Path
        d="M325.865 448.15c13.402 0 15.25 14.377 31.425 14.963 16.175.587 18.975 7.106 31.57 10.087 22.467 5.318 39.601-21.649 61.601-21.649 35.69 0 48.336 18.277 65.451 18.7 38.751.959 42.901-9.191 42.901-9.191v34.914h-709.511v-32.861s20.949-15.231 47.301-13.762c25.329 1.411 32.158 9.655 59.95 7.308 10.025-.847 28.807 5.37 44.001 6.454 13.644.974 22.852-6.454 35.75-6.454 12.9 0 15.738-12.704 27.677-12.704 15.194 0 30.807 22.759 87.911 22.759 42.328 0 39.479-30.887 51.507-30.887 10.629 0 8.319 27.286 26.804 27.286 14.592 0 22.092 15.697 33.183 15.697 11.091 0 13.031-17.75 29.205-17.75 16.175 0 19.872-12.91 33.274-12.91Z"
        fill="#78DE82"
        stroke="#202232"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <Path
        d="M248.242 493.743c-16.454 0-15.05-25.133-32.908-25.133-17.859 0-19.125 15.812-56.93 19.236-23.322 2.112-36.692-25.468-62.95-25.468-23.876 0-38.279 21.314-58.064 21.314-19.786 0-22.068.231-38.486-6.506-20.9-8.576-43.058 7.462-53.9 10.66-31.085 9.168-37.401-25.468-56.102-25.468-20.814 0-40.7 25.468-40.7 25.468v43.62h715.011v-54.28s-49.479 20.712-70.401 10.66c-12.351-5.934-53.651-4.727-66.001-10.66-12.351-5.934-20.418-28.36-34.101-27.636-16.942.897-20.358 27.931-33.521 27.636-13.163-.296-9.402-8.576-15.984-8.576-10.813 0-8.932 13.307-23.506 15.082-14.573 1.774-23.976-6.506-35.258-6.506-11.283 0-19.745 16.557-36.199 16.557Z"
        fill="#22BEAB"
        stroke="#202232"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <Path
        d="M245.23 500.021v-6.485s1.641.304 2.835.432c1.114.12 3.134-.144 3.134-.144v6.485s-2.053-.576-3.214-.576-2.755.288-2.755.288ZM261.51 489.743v-10.741s1.306.133 2.773 0c1.374-.123 3.467-.909 3.467-.909v7.617s-.924.747-2.773 1.942c-1.849 1.194-3.467 2.091-3.467 2.091Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <Path
        d="M195.034 494.967c10.153 0 27.891 8.525 34.237 8.723 4.919-1.282 15.423-3.844 18.088-3.844 3.332 0 19.516 3.548 19.992 6.505.476 2.957 1.904 3.548 4.76 5.322 2.285 1.419 3.478 5.802 3.478 7.675.952.591 2.979 1.123 4.311-.296 1.666-1.774-1.125-5.309 1.731-5.753 2.856-.443 2.856-8.131 4.522-8.279 1.666-.148 9.52-8.723 12.614-7.983 3.094.739 14.993 2.809 19.277 2.809 4.284 0 11.424-5.175 16.184-4.879 4.76.295 16.634-.179 27.132-1.774 12.276-1.866 18.399-8.511 30.8-7.893 11.5.574 42.341 11.174 53.351 14.546 16.896 5.174 29.7-2.07 50.601-2.07 48.012 0 74.801 8.575 74.801 8.575v39.773H274.458l-430.656.088v-55.961s.6 5.677 27.5 0c17.518-3.698 35.2 4.716 55.001 4.716 19.8 0 33 2.07 74.251-4.716 14.283-2.35 34.602 7.618 36.293 7.525 1.691-.094 13.549 2.101 28.219 2.07 10.52-.023 21.697-6.692 35.273-6.653 15.481.043 32.508 10.34 47.754 10.497 31.464.323 38.858-8.723 46.941-8.723Z"
        fill="#009898"
      />
      <Path
        d="M275.589 519.348c0-1.873-1.193-6.256-3.478-7.675-2.856-1.774-4.284-2.365-4.76-5.322s-16.66-6.505-19.992-6.505c-2.665 0-13.169 2.562-18.088 3.844-6.346-.198-24.084-8.723-34.237-8.723-8.083 0-15.477 9.046-46.941 8.723-15.246-.157-32.273-10.454-47.754-10.497-13.576-.039-24.752 6.63-35.273 6.653-14.67.031-26.528-2.164-28.219-2.07-1.69.093-22.01-9.875-36.293-7.525-41.25 6.786-54.45 4.716-74.251 4.716-19.8 0-37.483-8.414-55.001-4.716-26.9 5.677-27.5 0-27.5 0v55.961l430.656-.088m1.131-26.776c.952.591 2.979 1.123 4.311-.296m-4.311.296-1.131 26.776m5.442-27.072c1.666-1.774-1.125-5.309 1.731-5.753 2.856-.443 2.856-8.131 4.522-8.279 1.666-.148 9.52-8.723 12.614-7.983 3.094.739 14.993 2.809 19.277 2.809 4.284 0 11.424-5.175 16.184-4.879 4.76.295 16.634-.179 27.132-1.774 12.276-1.866 18.399-8.511 30.8-7.893 11.5.574 42.341 11.174 53.351 14.546 16.896 5.174 29.7-2.07 50.601-2.07 48.012 0 74.801 8.575 74.801 8.575v39.773H282.709m-2.809-27.072 2.809 27.072m-8.251 0h8.251"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="m274.534 546.097 1.137-26.482s.455.442 2.046.442c1.464 0 2.728-1.324 2.728-1.324l2.5 26.628s-1.136.295-3.637.442c-2.501.147-4.774.294-4.774.294Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <Path
        d="m275.009 546.902.275-4.95 6.875 1.1.55 3.85h-7.7Z"
        fill="#C8F5FF"
      />
      <Path
        d="M228.942 534.097c-29.007 12.485-24.445 43.306-27.672 61.087l44.408 103.178 274.72-106.925-32.555-75.639c-5.485-5.328-61.166 23.714-85.623 25.537-34.22 2.55-57.612-28.696-95.644-5.836-24.203 14.547-59.642-9.146-77.634-1.402Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M207.642 608.979c3.227-17.781-1.335-48.603 27.672-61.088 17.992-7.743 53.431 15.95 77.634 1.403 38.033-22.861 61.424 8.385 95.644 5.835 24.457-1.822 80.138-30.865 85.623-25.537"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M214.281 624.401c3.227-17.782-1.335-48.603 27.672-61.088 17.992-7.744 53.431 15.95 77.634 1.402 38.032-22.86 61.424 8.386 95.644 5.836 24.457-1.822 80.138-30.865 85.622-25.537"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M221.55 641.291c3.227-17.781-1.335-48.603 27.673-61.088 17.991-7.743 53.431 15.95 77.633 1.403 38.033-22.861 61.425 8.385 95.644 5.835 24.457-1.822 80.138-30.865 85.623-25.537"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M228.503 657.447c3.227-17.781-1.334-48.603 27.673-61.088 17.992-7.743 53.431 15.95 77.633 1.402 38.033-22.86 61.425 8.386 95.644 5.836 24.458-1.822 80.139-30.865 85.623-25.537"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M234.192 670.665c3.227-17.781-1.335-48.603 27.672-61.087 17.992-7.744 53.431 15.949 77.634 1.402 38.032-22.861 61.424 8.386 95.644 5.836 24.457-1.823 80.138-30.865 85.623-25.537"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M267.758 674.373c-24.304 4.477-33.445 42.906-34.778 60.761l235.85 35.716 8.794-70.494c-9.86-22.386-34.187-70.92-63.959-65.958-31.18 5.197-63.16-39.574-89.143-29.581-25.382 9.762-26.383 63.959-56.764 69.556Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M235.779 749.386c1.332-17.856 10.473-56.284 34.778-60.762 30.38-5.596 31.382-59.793 56.763-69.555 25.984-9.994 57.963 34.778 89.143 29.581 29.772-4.962 54.099 43.572 63.96 65.958"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M239.776 766.175c1.333-17.855 10.474-56.284 34.778-60.761 30.381-5.596 31.382-59.793 56.764-69.555 25.983-9.994 57.963 34.777 89.143 29.581 29.772-4.962 54.099 43.572 63.959 65.958"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M245.373 782.165c1.333-17.855 10.473-56.284 34.778-60.761 30.38-5.597 31.382-59.794 56.764-69.556 25.983-9.993 57.963 34.778 89.143 29.581 29.772-4.962 54.098 43.572 63.959 65.958"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M250.169 798.954c1.333-17.855 10.474-56.284 34.778-60.761 30.381-5.597 31.382-59.793 56.764-69.556 25.983-9.993 57.963 34.778 89.143 29.581 29.772-4.962 54.099 43.573 63.959 65.958"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M254.167 812.546c1.332-17.856 10.473-56.284 34.778-60.762 30.38-5.596 31.382-59.793 56.764-69.555 25.983-9.994 57.962 34.777 89.143 29.581 29.772-4.962 54.098 43.572 63.959 65.958"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M261.362 825.337c1.333-17.855 10.474-56.284 34.778-60.761 30.381-5.596 31.382-59.793 56.764-69.555 25.983-9.994 57.963 34.777 89.143 29.581 29.772-4.962 54.099 43.572 63.959 65.958"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M374.186 597.573c-40.998 9.291-52.152 50.926-52.356 69.714l407.033-7.98 6.841-74.853c-19.443-21.353-66.632-67.084-116.914-56.246-52.66 11.35-112.486-29.014-155.712-13.677-42.224 14.982-37.644 71.43-88.892 83.042Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M328.264 681.546c.204-18.788 11.358-60.424 52.356-69.714 51.248-11.612 46.667-68.06 88.892-83.042 43.226-15.337 103.052 25.027 155.711 13.677 50.282-10.838 97.472 34.893 116.914 56.246"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M337.042 698.21c.203-18.789 11.357-60.424 52.356-69.714 51.247-11.613 46.667-68.06 88.892-83.042 43.226-15.337 103.051 25.027 155.711 13.676 50.282-10.837 97.472 34.894 116.914 56.247"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M348.457 713.738c.204-18.788 11.358-60.423 52.356-69.713 51.248-11.613 46.668-68.061 88.892-83.043 43.226-15.337 103.052 25.027 155.712 13.677 50.282-10.838 97.471 34.894 116.913 56.246"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M358.601 730.249c.204-18.788 11.358-60.423 52.356-69.713 51.247-11.613 46.667-68.061 88.892-83.043C543.075 562.156 602.9 602.52 655.56 591.17c50.282-10.838 97.472 34.894 116.914 56.246"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M367.007 743.593c.204-18.788 11.358-60.424 52.356-69.714 51.248-11.612 46.667-68.06 88.892-83.042 43.226-15.337 103.052 25.027 155.712 13.677 50.281-10.838 97.471 34.893 116.913 56.246"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M380.784 755.497c.203-18.788 11.357-60.424 52.355-69.714 51.248-11.612 46.668-68.06 88.893-83.042 43.225-15.337 103.051 25.027 155.711 13.676 50.282-10.837 97.471 34.894 116.914 56.247"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M243.014 516.238c30.314 8.849 29.55 39.997 34.924 57.251L246.46 681.317l-285.72-72.583 23.076-79.048c4.793-5.958 63.604 16.069 88.1 14.892 34.275-1.647 53.677-35.516 94.216-17.47 25.798 11.484 58.079-16.36 76.882-10.87Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M273.298 587.959c-5.374-17.254-4.61-48.402-34.924-57.252-18.803-5.489-51.084 22.354-76.882 10.87-40.539-18.045-59.941 15.823-94.216 17.47-24.496 1.178-83.307-20.849-88.1-14.891"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M268.593 604.076c-5.374-17.255-4.61-48.403-34.924-57.252-18.803-5.489-51.084 22.354-76.882 10.87-40.539-18.046-59.94 15.823-94.216 17.47-24.496 1.177-83.307-20.849-88.1-14.892"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M263.44 621.727c-5.374-17.254-4.61-48.402-34.924-57.252-18.803-5.489-51.084 22.354-76.882 10.871-40.539-18.046-59.94 15.822-94.216 17.47-24.496 1.177-83.307-20.85-88.1-14.892"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M258.511 638.611c-5.374-17.254-4.609-48.402-34.924-57.252-18.802-5.489-51.084 22.354-76.881 10.871-40.54-18.046-59.941 15.823-94.216 17.47-24.497 1.177-83.307-20.85-88.1-14.892"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M254.477 652.425c-5.373-17.254-4.609-48.402-34.923-57.252-18.803-5.489-51.084 22.354-76.882 10.871-40.539-18.046-59.941 15.822-94.216 17.47-24.497 1.177-83.307-20.85-88.1-14.892"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M208.596 622.564c-24.304 4.606-33.445 44.142-34.778 62.512l235.85 36.745 8.794-72.525c-9.86-23.031-34.187-72.963-63.959-67.858-31.18 5.346-63.16-40.715-89.143-30.434-25.382 10.044-26.383 65.802-56.764 71.56Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M176.616 699.738c1.333-18.37 10.474-57.906 34.778-62.512 30.381-5.757 31.382-61.516 56.764-71.559 25.983-10.282 57.963 35.779 89.143 30.433 29.772-5.105 54.099 44.828 63.959 67.858"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M180.613 717.011c1.333-18.369 10.474-57.905 34.778-62.512 30.381-5.757 31.382-61.516 56.764-71.559 25.983-10.282 57.963 35.78 89.143 30.433 29.772-5.105 54.099 44.828 63.959 67.859"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M186.21 733.462c1.332-18.37 10.473-57.906 34.778-62.512 30.38-5.758 31.382-61.516 56.763-71.56 25.984-10.281 57.963 35.78 89.144 30.434 29.772-5.105 54.098 44.827 63.959 67.858"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M191.007 750.735c1.333-18.37 10.474-57.906 34.778-62.512 30.381-5.758 31.382-61.516 56.764-71.56 25.983-10.282 57.963 35.78 89.143 30.433 29.772-5.104 54.099 44.828 63.959 67.859"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M195.005 764.718c1.332-18.37 10.473-57.906 34.778-62.512 30.38-5.758 31.382-61.516 56.763-71.56 25.984-10.282 57.963 35.78 89.143 30.434 29.772-5.105 54.099 44.827 63.96 67.858"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M202.2 777.878c1.333-18.37 10.474-57.906 34.778-62.512 30.381-5.757 31.382-61.516 56.764-71.559 25.983-10.282 57.963 35.779 89.143 30.433 29.772-5.105 54.099 44.828 63.959 67.858"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M181.451 588.903c-26.846-3.342-72.113 31.862-91.391 49.882l348.482 86.14 27.434-110.986c-3.872 2.337-16.077 6.402-33.923 3.968-22.307-3.044-62.335-34.35-90.258-34.664-27.923-.314-47.195 44.335-75.703 43.053-28.507-1.281-51.085-33.216-84.641-37.393Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M93.4 655.258c19.278-18.02 64.546-53.224 91.391-49.882 33.557 4.177 56.134 36.112 84.642 37.393 28.507 1.282 47.779-43.367 75.703-43.053 27.923.313 67.95 31.62 90.257 34.663 17.846 2.435 30.051-1.63 33.923-3.967"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M95.58 673.091c19.278-18.019 64.546-53.223 91.391-49.882 33.557 4.177 56.134 36.112 84.642 37.394 28.507 1.282 47.78-43.368 75.703-43.054 27.923.314 67.95 31.621 90.258 34.664 17.845 2.435 30.051-1.631 33.922-3.968"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M99.312 691.309c19.278-18.02 64.546-53.224 91.391-49.882 33.557 4.177 56.134 36.111 84.642 37.393 28.507 1.282 47.78-43.367 75.703-43.054 27.923.314 67.95 31.621 90.258 34.664 17.845 2.435 30.051-1.631 33.922-3.968"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M101.492 709.142c19.278-18.019 64.546-53.223 91.391-49.882 33.557 4.177 56.135 36.112 84.642 37.394 28.507 1.282 47.78-43.368 75.703-43.054 27.923.314 67.951 31.621 90.258 34.664 17.845 2.435 30.051-1.631 33.923-3.968"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M105.809 728.328c19.279-18.02 64.546-53.224 91.392-49.882 33.556 4.177 56.134 36.111 84.641 37.393 28.508 1.282 47.78-43.367 75.703-43.054 27.923.314 67.951 31.621 90.258 34.664 17.846 2.435 30.051-1.631 33.923-3.968"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M107.797 746.937c19.278-18.02 64.546-53.223 91.391-49.882 33.557 4.177 56.135 36.112 84.642 37.394 28.508 1.281 47.78-43.368 75.703-43.054 27.923.314 67.951 31.62 90.258 34.664 17.846 2.434 30.051-1.631 33.923-3.968"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M30.71 587.367c-31.58 0-39.575 30.114-49.569 45.171v112.329l294.612 10.393v-82.347c-2.932-7.063-65.558-2.399-88.743-10.394-32.441-11.186-41.574-49.135-85.546-43.172-27.982 3.794-51.167-31.98-70.755-31.98Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-18.46 647.728c9.994-15.057 17.989-45.171 49.569-45.171 19.587 0 42.773 35.774 70.755 31.98 43.972-5.963 53.105 31.986 85.545 43.172 23.185 7.995 85.812 3.331 88.743 10.393"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-18.46 664.518c9.994-15.057 17.989-45.172 49.569-45.172 19.587 0 42.773 35.774 70.755 31.98 43.972-5.962 53.105 31.986 85.545 43.172 23.185 7.995 85.812 3.332 88.743 10.394"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-18.46 682.906c9.994-15.057 17.989-45.171 49.569-45.171 19.587 0 42.773 35.774 70.755 31.979 43.972-5.962 53.105 31.987 85.545 43.173 23.185 7.995 85.812 3.331 88.743 10.393"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-18.46 700.495c9.994-15.057 17.989-45.171 49.569-45.171 19.587 0 42.773 35.773 70.755 31.979 43.972-5.962 53.105 31.986 85.545 43.173 23.185 7.995 85.812 3.331 88.743 10.393"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-18.46 714.886c9.994-15.057 17.989-45.171 49.569-45.171 19.587 0 42.773 35.773 70.755 31.979 43.972-5.962 53.105 31.986 85.545 43.173 23.185 7.994 85.812 3.331 88.743 10.393"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-242.98 495.692c-31.411 3.261-36.254 34.039-44.64 50.048l11.597 111.728 294.11-20.079-8.501-81.907c-3.645-6.722-65.455 4.383-89.342-1.176-33.422-7.777-46.424-44.58-89.545-34.11-27.441 6.663-54.196-26.526-73.679-24.504Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-285.654 560.807c8.386-16.008 13.229-46.786 44.64-50.047 19.483-2.022 46.237 31.167 73.678 24.504 43.122-10.47 56.124 26.333 89.546 34.11 23.886 5.558 85.697-5.546 89.342 1.176"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-283.92 577.507c8.385-16.009 13.228-46.787 44.639-50.048 19.483-2.022 46.238 31.167 73.679 24.504 43.121-10.47 56.124 26.333 89.545 34.11 23.887 5.559 85.697-5.546 89.342 1.176"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-282.022 595.797c8.386-16.008 13.229-46.787 44.64-50.047 19.483-2.023 46.237 31.166 73.678 24.504 43.122-10.471 56.124 26.332 89.546 34.109 23.886 5.559 85.697-5.545 89.342 1.176"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-280.206 613.292c8.386-16.009 13.229-46.787 44.64-50.048 19.483-2.022 46.237 31.167 73.678 24.504 43.121-10.47 56.124 26.333 89.546 34.11 23.886 5.559 85.697-5.546 89.342 1.176"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-278.721 627.606c8.386-16.009 13.229-46.787 44.64-50.047 19.483-2.023 46.238 31.166 73.678 24.503 43.122-10.47 56.124 26.333 89.546 34.11 23.886 5.559 85.697-5.546 89.342 1.176"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M80.34 639.398c38.774 16.241 42.559 59.178 39.509 77.719l-399.517-78.263 6.21-74.908c22.842-17.668 77.231-54.548 124.88-35.177 49.903 20.288 115.808-9.12 155.73 13.463 38.997 22.059 24.722 76.864 73.189 97.166Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M111.046 730.048c3.049-18.541-.735-61.478-39.509-77.719-48.467-20.302-34.192-75.106-73.189-97.166-39.921-22.582-105.827 6.825-155.73-13.462-47.649-19.372-102.037 17.508-124.88 35.176"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M99.518 744.942c3.049-18.54-.735-61.477-39.509-77.718-48.466-20.302-34.191-75.107-73.189-97.166-39.921-22.583-105.827 6.825-155.73-13.463-47.649-19.371-102.037 17.508-124.88 35.177"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M85.589 758.262c3.049-18.54-.736-61.477-39.509-77.719-48.467-20.302-34.192-75.106-73.189-97.166-39.921-22.582-105.827 6.825-155.73-13.462-47.649-19.372-102.037 17.508-124.88 35.176"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M72.743 772.769c3.049-18.54-.736-61.477-39.51-77.718-48.466-20.302-34.19-75.107-73.188-97.166-39.921-22.583-105.827 6.825-155.73-13.463-47.649-19.371-102.038 17.509-124.88 35.177"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M62.155 784.459c3.049-18.541-.736-61.478-39.509-77.719-48.467-20.302-34.192-75.107-73.189-97.166-39.921-22.583-105.827 6.825-155.73-13.463-47.649-19.371-102.038 17.509-124.88 35.177"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M46.528 793.799c3.049-18.54-.736-61.477-39.509-77.719-48.467-20.301-34.192-75.106-73.189-97.165-39.921-22.583-105.827 6.825-155.73-13.463-47.649-19.371-102.038 17.508-124.88 35.176"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-49.036 595.688c40.032 15.304 38.539 49.595 45.393 69.646l-43.372 113.458-377.477-132.269 31.796-83.175c6.443-5.713 84.031 29.346 116.508 32.506 45.442 4.422 71.673-29.482 125.112-2.152 34.006 17.393 77.21-7.505 102.04 1.986Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-10.016 680.484c-6.855-20.052-5.362-54.343-45.394-69.646-24.83-9.492-68.034 15.406-102.04-1.986-53.439-27.331-79.669 6.573-125.111 2.151-32.478-3.16-110.066-38.219-116.508-32.506"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-16.498 697.442c-6.855-20.051-5.362-54.342-45.394-69.646-24.83-9.492-68.034 15.406-102.04-1.986-53.439-27.331-79.669 6.573-125.111 2.152-32.478-3.16-110.066-38.219-116.508-32.507"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M17.518 652.925c-21.186-6.396-21.186-26.383-45.97-30.38l-7.595 186.281 215.062 2.798 33.179-79.15c-10.393-13.591-39.627-5.115-68.756-15.989-29.981-11.193-31.637-36.028-49.568-51.967-25.184-22.386-57.967-6.043-76.352-11.593Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-35.648 632.938c24.784 3.998 24.784 23.985 45.97 30.381 18.386 5.55 51.168-10.793 76.352 11.593 17.931 15.939 19.587 40.774 49.568 51.966 29.129 10.875 58.363 2.399 68.756 15.99"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-44.442 645.73c24.784 3.997 24.784 23.984 45.97 30.38 18.386 5.55 51.168-10.793 76.352 11.593 17.931 15.939 19.587 40.774 49.568 51.967 29.129 10.875 58.363 2.398 68.756 15.989"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-51.637 658.521c24.784 3.998 24.784 23.985 45.97 30.381 18.385 5.55 51.168-10.793 76.352 11.593 17.931 15.939 19.587 40.774 49.568 51.966 29.129 10.875 58.363 2.399 68.756 15.99"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-58.833 672.113c24.784 3.997 24.784 23.985 45.97 30.381 18.386 5.55 51.168-10.793 76.352 11.592 17.931 15.939 19.587 40.774 49.568 51.967 29.129 10.875 58.363 2.398 68.756 15.99"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-66.828 687.303c24.785 3.998 24.785 23.985 45.971 30.381 18.385 5.55 51.167-10.793 76.351 11.593 17.931 15.939 19.588 40.774 49.569 51.966 29.129 10.875 58.362 2.399 68.756 15.99"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-77.222 700.095c24.784 3.998 24.784 23.985 45.97 30.381 18.386 5.55 51.168-10.793 76.352 11.593 17.931 15.939 19.588 40.774 49.568 51.966 29.13 10.875 58.363 2.399 68.757 15.99"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-77.222 700.095c24.784 3.998 24.784 23.985 45.97 30.381 18.386 5.55 51.168-10.793 76.352 11.593 17.931 15.939 19.588 40.774 49.568 51.966 29.13 10.875 58.363 2.399 68.757 15.99"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-84.416 713.686c24.784 3.998 24.784 23.985 45.97 30.381 18.385 5.55 51.168-10.793 76.352 11.592 17.931 15.939 19.587 40.774 49.568 51.967 29.129 10.875 58.363 2.399 68.756 15.99"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-91.611 728.077c24.784 3.998 24.784 23.985 45.97 30.381 18.385 5.55 51.168-10.793 76.352 11.592 17.93 15.939 19.587 40.774 49.568 51.967 29.129 10.875 58.363 2.399 68.756 15.99"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-97.207 741.669c24.784 3.997 24.784 23.984 45.97 30.38 18.385 5.55 51.167-10.793 76.351 11.593 17.932 15.939 19.588 40.774 49.569 51.967 29.129 10.875 58.362 2.398 68.756 15.989"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M-102.804 754.46c24.784 3.998 24.784 23.985 45.97 30.381 18.385 5.55 51.168-10.793 76.352 11.593 17.931 15.939 19.587 40.774 49.568 51.966 29.129 10.875 58.363 2.399 68.756 15.99"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M413.432 691.038c31.058-3.067 49.481 25.987 54.415 40.296L180.981 826.18l-23.875-55.512c8.16-21.141 29.52-67.795 67.47-71.982 39.744-4.385 71.316-50.071 105.475-49.069 33.368.978 44.558 45.255 83.381 41.421Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M466.98 743.828c-4.934-14.309-23.356-43.364-54.415-40.296-38.823 3.834-50.012-40.444-83.381-41.422-34.159-1.002-65.73 44.684-105.475 49.069-37.949 4.187-59.309 50.841-67.47 71.982"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M465.087 758.74c-4.933-14.308-23.356-43.363-54.414-40.296-38.823 3.835-50.013-40.443-83.381-41.421-34.16-1.002-65.731 44.684-105.475 49.069-37.95 4.187-59.31 50.841-67.47 71.982"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M461.058 773.439c-4.934-14.309-23.356-43.363-54.415-40.296-38.823 3.834-50.012-40.443-83.381-41.421-34.159-1.002-65.73 44.684-105.475 49.069-37.949 4.187-59.309 50.841-67.469 71.982"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M458.171 788.573c-4.934-14.309-23.356-43.363-54.415-40.296-38.823 3.834-50.012-40.443-83.381-41.421-34.159-1.002-65.73 44.684-105.475 49.069-37.949 4.187-59.31 50.841-67.47 71.982"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M455.691 800.857c-4.933-14.309-23.356-43.363-54.414-40.296-38.823 3.834-50.013-40.443-83.381-41.421-34.16-1.002-65.731 44.684-105.475 49.069-37.95 4.187-59.31 50.841-67.47 71.982"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M449.087 813.371c-4.934-14.309-23.356-43.363-54.415-40.296-38.823 3.834-50.012-40.443-83.381-41.422-34.159-1.001-65.73 44.685-105.475 49.07-37.949 4.186-59.309 50.841-67.47 71.982"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M89.652 821.226c-40.998 9.29-52.152 50.925-52.356 69.714l407.033-7.98 6.84-74.853c-19.442-21.353-66.632-67.084-116.913-56.247-52.66 11.351-112.486-29.013-155.712-13.676-42.225 14.982-37.644 71.429-88.892 83.042Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M43.73 905.199c.203-18.789 11.358-60.424 52.356-69.714 51.247-11.613 46.667-68.061 88.892-83.042 43.226-15.337 103.051 25.026 155.711 13.676 50.282-10.838 97.472 34.894 116.914 56.247"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M52.508 921.862c.203-18.788 11.357-60.423 52.355-69.713 51.248-11.613 46.668-68.061 88.893-83.043 43.226-15.337 103.051 25.027 155.711 13.677 50.282-10.838 97.471 34.894 116.914 56.246"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M63.923 937.391c.204-18.788 11.358-60.424 52.356-69.714 51.248-11.612 46.667-68.06 88.892-83.042 43.226-15.337 103.052 25.027 155.712 13.676 50.281-10.837 97.471 34.894 116.913 56.247"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M74.067 953.902c.203-18.788 11.357-60.424 52.356-69.714 51.247-11.612 46.667-68.06 88.892-83.042 43.226-15.337 103.051 25.027 155.711 13.676 50.282-10.837 97.472 34.894 116.914 56.247"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M82.473 967.246c.204-18.789 11.358-60.424 52.356-69.714 51.247-11.613 46.667-68.06 88.892-83.042 43.226-15.337 103.052 25.026 155.711 13.676 50.282-10.837 97.472 34.894 116.914 56.247"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M96.25 979.149c.203-18.788 11.357-60.423 52.355-69.713 51.248-11.613 46.668-68.061 88.892-83.042 43.226-15.337 103.052 25.026 155.712 13.676 50.282-10.838 97.471 34.894 116.913 56.246"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M259.04 778.308c26.809 3.619 61.594 49.21 75.636 71.552l-358.926-5.641 1.796-114.313c3.147 3.248 13.911 10.294 31.787 12.494 22.346 2.75 69.037-17.306 96.116-10.484 27.078 6.823 34.318 54.912 62.209 60.947 27.891 6.036 57.87-19.08 91.382-14.555Z"
        fill="#C8F5FF"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M327.242 864.935c-14.041-22.343-48.826-67.933-75.636-71.552-33.511-4.525-63.491 20.591-91.381 14.555-27.891-6.035-35.131-54.124-62.21-60.947-27.079-6.822-73.77 13.234-96.115 10.484-17.877-2.2-28.64-9.246-31.787-12.494"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M320.583 881.622c-14.041-22.343-48.827-67.933-75.636-71.553-33.511-4.524-63.491 20.591-91.382 14.556-27.89-6.036-35.13-54.125-62.21-60.947-27.078-6.822-73.77 13.233-96.115 10.483-17.876-2.2-28.64-9.245-31.787-12.493"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M312.326 898.284c-14.041-22.343-48.826-67.933-75.636-71.553-33.511-4.524-63.491 20.591-91.381 14.556-27.891-6.036-35.131-54.125-62.21-60.947-27.079-6.822-73.77 13.233-96.115 10.483-17.877-2.2-28.64-9.245-31.788-12.493"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M305.667 914.97c-14.041-22.342-48.827-67.932-75.636-71.552-33.511-4.525-63.491 20.591-91.382 14.556-27.89-6.036-35.13-54.125-62.21-60.947-27.078-6.823-73.77 13.233-96.114 10.483-17.877-2.2-28.64-9.246-31.788-12.493"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M296.597 932.419c-14.041-22.343-48.826-67.933-75.636-71.553-33.511-4.524-63.491 20.591-91.381 14.556-27.891-6.036-35.131-54.125-62.21-60.947-27.079-6.822-73.77 13.233-96.115 10.483-17.876-2.2-28.64-9.245-31.787-12.493"
        stroke="#202232"
        strokeWidth={2}
      />
      <Path
        d="M289.926 949.905c-14.041-22.343-48.826-67.933-75.636-71.552-33.511-4.525-63.491 20.591-91.381 14.555-27.891-6.035-35.131-54.124-62.21-60.947-27.079-6.822-73.77 13.234-96.115 10.484-17.876-2.2-28.64-9.246-31.787-12.494"
        stroke="#202232"
        strokeWidth={2}
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h390v844H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)

export default LandscapeVertical
