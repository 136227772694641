import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const Back = (props: SvgProps): JSX.Element => (
  <Svg
    width={19}
    height={16}
    fill='none'
    {...props}
  >
    <Path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.658.929.294 7.293a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.414-1.414L3.415 9h13.706a1 1 0 1 0 0-2H3.415l4.657-4.657A1 1 0 1 0 6.658.93Z'
      fill={props.color}
    />
  </Svg>
)

export default Back
