import React from 'react'
import { Pressable, ViewStyle, View, Text, TextStyle } from 'react-native'
import { expandableCardStyle } from './expandable-card.style'
import { textStyle } from '../themes/global-styles.style'

// A card that can be expanded to reveal more content. Initially this card
// only shows the title. When the user taps on the card, the card expands
// to show the content.
export function ExpandableCard (props: {
  title: string
  // The content to be displayed when the card is expanded.
  content: JSX.Element
  // Styles included here add to (or override) the default expandable card style.
  style?: ViewStyle[]
  titleStyle?: TextStyle[]
}): JSX.Element {
  const [cardIsOpen, setCardIsOpen] = React.useState(false)

  return (
    <Pressable
      style={[expandableCardStyle.card, ...(props.style ?? [])]}
      onPress={() => setCardIsOpen(!cardIsOpen)}
    >
      <View>
        <Text
          style={[
            textStyle.regularText,
            textStyle.boldText,
            ...(props.titleStyle ?? [])
          ]}
        >
          {props.title}
        </Text>
      </View>
      {cardIsOpen ? (
        <View style={expandableCardStyle.openCardContent}>{props.content}</View>
      ) : null}
    </Pressable>
  )
}
