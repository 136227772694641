import React from 'react'
import { Image, useWindowDimensions, View } from 'react-native'
import { THEME } from '../constants'
import { shouldUseMobileDesign } from './use-mobile-design'
import { imageSizeStyle, imageStyle } from './image.style'

export enum ImageId {
  ACCOUNTS,
  TASK_INTRO,
  TASK,
  FINANCIAL_LITERACY,
  POINTS_AND_YUYU,
  TEAM,
  APP_MAIN_SCREEN,
  FINANCIAL_PLAN,
  EUPHORIA_LOGO,
  WALKTHROUGH_LOGO_EUPHORIA_SIZE
}

export function ImageById (props: { imageId: ImageId }): JSX.Element {
  const windowWidth = useWindowDimensions().width
  const useMobileDesign = shouldUseMobileDesign(windowWidth)

  switch (props.imageId) {
    case ImageId.ACCOUNTS:
      return (
        <View style={imageStyle.photoContainer}>
          <Image
            source={require('../assets/app-screenshots/accounts.png')}
            style={imageSizeStyle(300, /* 1.25:1 aspect ratio = */ 1.25).image}
          />
        </View>
      )
    case ImageId.TASK_INTRO:
      return (
        <View style={imageStyle.photoContainer}>
          <Image
            source={require('../assets/app-screenshots/recommended-task.png')}
            style={imageSizeStyle(300, /* 2.15:1 aspect ratio = */ 2.15).image}
          />
        </View>
      )
    case ImageId.TASK:
      return (
        <View style={imageStyle.photoContainer}>
          <Image
            source={require('../assets/app-screenshots/task-steps.png')}
            style={imageSizeStyle(300, /* 2.79:1 aspect ratio = */ 2.79).image}
          />
        </View>
      )
    case ImageId.FINANCIAL_LITERACY:
      return (
        <View style={imageStyle.photoContainer}>
          <Image
            source={require('../assets/app-screenshots/financial-literacy.png')}
            style={imageSizeStyle(300, /* 2.32:1 aspect ratio = */ 2.32).image}
          />
        </View>
      )
    case ImageId.POINTS_AND_YUYU:
      return (
        <View style={imageStyle.photoContainer}>
          <Image
            source={require('../assets/app-screenshots/points-and-yuyu.png')}
            style={imageSizeStyle(300, /* 2:1 aspect ratio = */ 2).image}
          />
        </View>
      )
    case ImageId.TEAM:
      return (
        <View style={imageStyle.photoContainer}>
          <Image
            source={require('../assets/walkthrough-team.jpg')}
            // On mobile, we fill the available screen width (minus margins).
            // On desktop, we use a 500px width, and it scales down as needed with a maxWidth of 100%.
            // The aspect ratio of this particular photo is 3:2 (width:height), or 1.5 as a decimal.
            style={[
              imageSizeStyle(
                useMobileDesign
                  ? windowWidth - 2 * THEME.spacing.marginMedium
                  : 500,
                /* 3:2 aspect ratio = */ 1.5
              ).image,
              { borderRadius: 10 }
            ]}
          />
        </View>
      )
    case ImageId.APP_MAIN_SCREEN:
      return (
        <View style={imageStyle.photoContainer}>
          <Image
            source={require('../assets/app-screenshots/main-screen.png')}
            // On mobile, we fill the available screen width (minus margins).
            // On desktop, we use a 280px width, and it scales down as needed with a maxWidth of 100%.
            // The aspect ratio of this particular photo is 403:836 (width:height), or 0.48206 as a decimal.
            style={
              imageSizeStyle(
                useMobileDesign
                  ? windowWidth - 2 * THEME.spacing.marginMedium
                  : 280,
                /* 403:836 aspect ratio = */ 0.48206
              ).image
            }
          />
        </View>
      )
    case ImageId.FINANCIAL_PLAN:
      return (
        <View style={imageStyle.photoContainer}>
          <Image
            source={require('../assets/financial-plan.png')}
            // On mobile, we fill the available screen width (minus margins).
            // On desktop, we use a 300px width, and it scales down as needed with a maxWidth of 100%.
            // The aspect ratio of this particular photo is 680:1050.8 (width:height), or 0.6471 as a decimal.
            style={
              imageSizeStyle(
                useMobileDesign
                  ? windowWidth - 2 * THEME.spacing.marginMedium
                  : 300,
                /* 680:1050.8 aspect ratio = */ 0.6471
              ).image
            }
          />
        </View>
      )
    case ImageId.EUPHORIA_LOGO:
      return (
        <View style={imageStyle.photoContainer}>
          <Image
            source={require('../assets/euphoria-logo.png')}
            style={imageSizeStyle(128, /* 1:1 aspect ratio = */ 1).image}
          />
        </View>
      )
    case ImageId.WALKTHROUGH_LOGO_EUPHORIA_SIZE:
      return (
        <View style={imageStyle.photoContainer}>
          <Image
            source={require('../assets/walkthrough-logo-euphoria-size.png')}
            style={imageSizeStyle(128, /* 1:1 aspect ratio = */ 1).image}
          />
        </View>
      )
  }
}
