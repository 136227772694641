import React from 'react'
import { Text, View } from 'react-native'
import { APP_LINK, THEME } from '../../../constants'
import { buttonStyle, textStyle } from '../../../themes/global-styles.style'
import { Anchor, AnchorButton } from '../../../util/anchor'
import {
  NavigationAnchor,
  NavigationButton
} from '../../../util/navigation-anchor'
import { ArticlePage } from '../article-page'
import { ImageById, ImageId } from '../../../util/image'
import { isNonNullish } from 'global-utils'
import { appendUtmParamsToLink } from '../../../util/utm-params'

export const appShortCopy: (
  utmSource?: string,
  utmCampaign?: string
) => React.ReactNode = (utmSource, utmCampaign) => {
  return (
    <>
      <Text style={[textStyle.extraLargeText, textStyle.serifBold]}>
        The App
      </Text>
      <Text style={[textStyle.largeText]}>
        {'\n'}We've created a beautiful, friendly app that gives you unbiased
        advice about what next financial steps make sense. Get real-time
        personalized financial advice specific to you, and track your progress
        and finances in one place.
        {'\n\n'}
      </Text>
      <NavigationButton
        text={'Read more'}
        handlePress={(nav) => {
          nav.navigate('AppArticleScreen', {
            utm_source: utmSource,
            utm_campaign: utmCampaign
          })
        }}
        buttonStyle={[buttonStyle(THEME.color.outline).underlineButton]}
      />
      <AnchorButton
        text={'Open the app!'}
        buttonStyle={[buttonStyle(THEME.color.onSurface).smallButton]}
        textStyle={[{ color: THEME.color.background }]}
        href={appendUtmParamsToLink(
          APP_LINK,
          utmSource,
          utmCampaign,
          undefined,
          'appShortCopyButton'
        )}
      />
    </>
  )
}

export const appCopy: (
  utmSource?: string,
  utmCampaign?: string
) => React.ReactNode = (utmSource, utmCampaign) => {
  return (
    <>
      <Text style={textStyle.largeText}>
        Our app puts your whole financial picture in one place, gives you
        unbiased advice about what next steps make sense, and helps you track
        your progress as you go.
      </Text>
      <AnchorButton
        text={'Open the app!'}
        buttonStyle={[buttonStyle(THEME.color.onSurface).smallButton]}
        textStyle={[
          {
            color: THEME.color.background
          }
        ]}
        href={appendUtmParamsToLink(
          APP_LINK,
          utmSource,
          utmCampaign,
          undefined,
          'appArticleButton'
        )}
      />
      <Text style={textStyle.largeText}>
        {'\n\n'}It works like this:{'\n\n'}
        1. You securely connect your accounts so everything is in one place. We
        keep that info up to date for you so you can check back in any time (and
        don't use it for anything else).
      </Text>
      <View
        style={{ marginTop: THEME.spacing.marginMedium, alignSelf: 'center' }}
      >
        <ImageById imageId={ImageId.ACCOUNTS} />
      </View>
      <Text style={textStyle.largeText}>
        {'\n\n'}2. We recommend appropriate tasks for you.
      </Text>
      <View
        style={{ marginTop: THEME.spacing.marginMedium, alignSelf: 'center' }}
      >
        <ImageById imageId={ImageId.TASK_INTRO} />
      </View>
      <Text style={textStyle.largeText}>
        {'\n\n'}3. And teach you about them.
      </Text>
      <View
        style={{ marginTop: THEME.spacing.marginMedium, alignSelf: 'center' }}
      >
        <ImageById imageId={ImageId.FINANCIAL_LITERACY} />
      </View>
      <Text style={textStyle.largeText}>
        {'\n\n'}4. And help you pursue them.
      </Text>
      <View
        style={{ marginTop: THEME.spacing.marginMedium, alignSelf: 'center' }}
      >
        <ImageById imageId={ImageId.TASK} />
      </View>
      <Text style={textStyle.largeText}>
        {'\n\n'}5. In a beautiful, friendly, app that realizes that we are all
        just human.
      </Text>
      <View
        style={{ marginTop: THEME.spacing.marginMedium, alignSelf: 'center' }}
      >
        <ImageById imageId={ImageId.POINTS_AND_YUYU} />
      </View>
      <Text style={textStyle.largeText}>
        {'\n\n'}You can get our app in one of two ways:
        {'\n\n'}•{' '}
        <NavigationAnchor
          text={'Buy a financial plan'}
          handlePress={(nav) => {
            nav.navigate('FinancialPlanScreen', {
              utm_source: utmSource,
              utm_campaign: utmCampaign
            })
          }}
        />
        ! Financial plan clients get access to the Walkthrough app for a year.
        {'\n'}•{' '}
        <Anchor
          href={appendUtmParamsToLink(
            APP_LINK,
            utmSource,
            utmCampaign,
            undefined,
            'appArticleHowToGet'
          )}
          text="Open the app right now"
        />{' '}
        (limited time only!)
      </Text>
    </>
  )
}

export function AppArticleScreen (props: {
  route: {
    params: {
      // NOTE: utm_ params are snake case because Plausible, our analytics tool, expects snake case &
      // will automatically track utm params. This is the standard format for utm params.
      // See https://plausible.io/docs/custom-dimensions#utm-parameters.
      // Since React navigation automatically parses query params to get the params for a screen, we
      // keep the screen params in snake case as well.
      // See https://reactnavigation.org/docs/configuring-links/#passing-params.
      utm_source?: string
      utm_campaign?: string
    }
  }
}): JSX.Element {
  let utmSource, utmCampaign: string | undefined
  if (
    isNonNullish(props.route.params) &&
    isNonNullish(props.route.params.utm_source) &&
    isNonNullish(props.route.params.utm_campaign)
  ) {
    utmSource = props.route.params.utm_source
    utmCampaign = props.route.params.utm_campaign
  }
  return <ArticlePage>{appCopy(utmSource, utmCampaign)}</ArticlePage>
}
