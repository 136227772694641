import React from 'react'
import { Text } from 'react-native'
import { textStyle } from '../../../themes/global-styles.style'
import { Anchor } from '../../../util/anchor'
import { ArticlePage } from '../article-page'

const protextDataCopy = (
  <>
    <Text style={textStyle.largeText}>
      • In order to help you work toward your financial goals, we collect some
      basic information about you and have you link your accounts into the app.
      Walkthrough doesn't ever see or store your bank credentials.
      {'\n'}• Our providers for account linking (MX and Yodlee) are generally
      able to connect to your accounts without storing or ever seeing your
      credentials (via{' '}
      <Anchor href="https://youtu.be/CPbvxxslDTU?t=49" text="OAuth2" />
      ). However, in a few limited instances, they may need to securely store
      credentials on their end in order to maintain the connection to the
      financial institution. This is their primary business and they take
      security very seriously, you can check out more{' '}
      <Anchor
        href="https://www.mx.com/whitepapers/security-how-mx-protects-your-data/"
        text="here"
      />{' '}
      (for MX) and{' '}
      <Anchor
        href="https://developer.yodlee.com/FAQs_to_Security"
        text="here"
      />{' '}
      (for Yodlee).{'\n'}• We are fully powered by AWS, which implements
      state-of-the-art security practices. Among other things, any data you
      share with us will be encrypted in transit and at rest. You can read more
      about{' '}
      <Anchor
        href="https://docs.aws.amazon.com/whitepapers/latest/aws-overview/security-and-compliance.html"
        text="the security standards used here"
      />
      .{'\n'}• We NEVER sell your data.
      {'\n'}• We NEVER share your individual data with third parties, except for
      our carefully vetted service providers (who use it to power Walkthrough).
      We may create aggregated data (for example: “our members' average net
      worth is $100k.”) which may, for example, go into our pitch deck or be
      shared with academic collaborators.
      {'\n'}• Our CTO worked in Privacy at Google for 5+ years and takes these
      issues very seriously. We deliberately took extra time to build this app
      safely and robustly so that you'll never have to worry about these issues.
      If you still have any questions, he is happy to talk endlessly about
      privacy with you 😊 (
      <Anchor href="mailto:cto@walkthrough.co" text="cto@walkthrough.co" />
      ).
    </Text>
  </>
)

export function ProtectDataScreen (): JSX.Element {
  return <ArticlePage>{protextDataCopy}</ArticlePage>
}
