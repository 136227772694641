import React from 'react'
import { Text } from 'react-native'
import { THEME } from '../../../constants'
import { buttonStyle, textStyle } from '../../../themes/global-styles.style'
import { Anchor, AnchorButton } from '../../../util/anchor'

export const partnerWithUsCopy: (
  useMobileDesign: boolean
) => React.ReactNode = (useMobileDesign) => {
  return (
    <>
      <Text style={[textStyle.extraLargeText, textStyle.serifBold]}>
        Partner With Us
      </Text>
      <Text style={textStyle.largeText}>
        {'\n'}We want to increase financial literacy and empowerment in the
        world. If you think we should team up, or if you're interested in
        providing Walkthrough to your community — employees, members, alumni, or
        others — please reach out to us at{' '}
        <Anchor
          href="mailto:partnerships@walkthrough.co"
          // On mobile this text wraps weirdly, so adding a space here for the mobile layout.
          text={
            useMobileDesign
              ? 'partnerships @walkthrough.co'
              : 'partnerships@walkthrough.co'
          }
        />
        .{'\n\n'}
      </Text>
      <AnchorButton
        text={'Email Us!'}
        buttonStyle={[buttonStyle(THEME.color.outline).underlineButton]}
        href={'mailto:partnerships@walkthrough.co'}
      />
    </>
  )
}
