import { StyleSheet, ViewStyle } from 'react-native'
import { THEME } from '../constants'

export const textStyle = StyleSheet.create({
  extraExtraLargeText: {
    fontSize: THEME.font.fontSizeExtraExtraLarge,
    fontFamily: 'DMSans_400Regular',
    color: THEME.color.onSurface
  },
  extraLargeText: {
    fontSize: THEME.font.fontSizeExtraLarge,
    fontFamily: 'DMSans_400Regular',
    color: THEME.color.onSurface
  },
  largeText: {
    fontSize: THEME.font.fontSizeLarge,
    fontFamily: 'DMSans_400Regular',
    color: THEME.color.onSurface
  },
  regularText: {
    fontSize: THEME.font.fontSizeMedium,
    fontFamily: 'DMSans_400Regular',
    color: THEME.color.onSurface
  },
  smallText: {
    fontSize: THEME.font.fontSizeSmall,
    fontFamily: 'DMSans_400Regular',
    color: THEME.color.onSurface
  },
  italicBoldText: {
    fontFamily: 'DMSans_700Bold_Italic'
  },
  italicText: {
    fontFamily: 'DMSans_400Regular_Italic'
  },
  boldText: {
    fontFamily: 'DMSans_700Bold'
  },
  serif: {
    fontFamily: 'PlayfairDisplay_400Regular'
  },
  serifItalic: {
    fontFamily: 'PlayfairDisplay_400Regular_Italic'
  },
  serifBold: {
    fontFamily: 'PlayfairDisplay_700Bold'
  },
  serifBoldItalic: {
    fontFamily: 'PlayfairDisplay_700Bold_Italic'
  },
  monospace: {
    fontFamily: 'SourceCodePro_400Regular'
  },
  monospaceItalic: {
    fontFamily: 'SourceCodePro_400Regular_Italic'
  },
  monospaceBold: {
    fontFamily: 'SourceCodePro_600SemiBold'
  },
  monospaceBoldItalic: {
    fontFamily: 'SourceCodePro_600SemiBold_Italic'
  },
  outlineColorText: {
    color: THEME.color.outline
  },
  underline: {
    textDecorationLine: 'underline'
  }
})

export const buttonStyle = (
  color: string
): {
  inlineButton: ViewStyle
  smallButton: ViewStyle
  asymmetricalButton: ViewStyle
  underlineButton: ViewStyle
} =>
  StyleSheet.create({
    inlineButton: {
      backgroundColor: color,
      minWidth: 'fit-content',
      height: THEME.buttons.buttonHeight,
      borderRadius: 20,
      justifyContent: 'center',
      alignItems: 'center',
      paddingHorizontal: THEME.spacing.marginMedium
    },
    smallButton: {
      backgroundColor: color,
      width: THEME.buttons.smallButtonWidth,
      maxWidth: '100%',
      height: THEME.buttons.buttonHeight,
      borderRadius: 20,
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: THEME.spacing.marginMedium
    },
    asymmetricalButton: {
      backgroundColor: color,
      minWidth: 'fit-content',
      height: THEME.buttons.buttonHeight,
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      justifyContent: 'center',
      alignItems: 'center',
      paddingHorizontal: THEME.spacing.marginMedium
    },
    underlineButton: {
      width: 'fit-content',
      paddingBottom: 2,
      borderBottomWidth: 4,
      borderColor: color
    }
  })

export const shadowStyle = StyleSheet.create({
  shadow: {
    shadowColor: THEME.color.outline,
    shadowOffset: {
      width: 0,
      height: 3
    },
    shadowOpacity: 0.1,
    shadowRadius: 3
  }
})
