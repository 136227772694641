import { StyleSheet, ViewStyle } from 'react-native'
import { THEME } from '../constants'

export const mainStyle = StyleSheet.create({
  outerContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: THEME.color.background,
    flex: 1,
    justifyContent: 'center'
  },
  menuIcon: {
    position: 'absolute',
    top: 20,
    left: 20,
    zIndex: 3
  },
  menu: {
    position: 'absolute',
    width: 300,
    maxWidth: '100%',
    height: '100%',
    backgroundColor: THEME.color.gradientB.colors[8],
    borderColor: THEME.color.outline,
    borderRightWidth: 2,
    zIndex: 2
  },
  menuLinkText: {
    color: THEME.color.onSurface
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    bottom: 0
  }
})

export const screenWidthSpecificStyle = (
  useMobileDesign: boolean,
  navigationMenuIsOpen: boolean
): {
  header: ViewStyle
  navigationMenuContainer: ViewStyle
  articleScrollContainer: ViewStyle
  ctaButtonContainer: ViewStyle
} =>
  StyleSheet.create({
    header: {
      width: '100%',
      paddingVertical: THEME.spacing.marginMedium,
      paddingHorizontal: useMobileDesign
        ? THEME.spacing.marginMedium
        : THEME.spacing.marginLarge,
      backgroundColor: THEME.color.background,
      position: 'absolute',
      top: 0,
      zIndex: 3,
      flexDirection: useMobileDesign ? 'column' : 'row',
      justifyContent: 'space-between'
    },
    ctaButtonContainer: {
      flexDirection: 'row',
      width: useMobileDesign ? '100%' : 'fit-content',
      // Use +2 for the outline height to keep the CTA buttons in line with
      // the first button in the navigation container.
      marginTop: useMobileDesign ? THEME.spacing.marginSmall + 2 : 0
    },
    navigationMenuContainer: {
      marginHorizontal: useMobileDesign
        ? THEME.spacing.marginMedium
        : THEME.spacing.marginLarge,
      position: 'absolute',
      zIndex: 3,
      // The menu is in the top right corner.
      top: THEME.spacing.marginMedium,
      right: 0,
      // If the menu is closed it is just the height of the menu button. If it's open we let it take
      // up 95% of the window height.
      height:
        useMobileDesign && navigationMenuIsOpen
          ? '100%'
          : navigationMenuIsOpen
            ? '95%'
            : 'fit-content',
      width: useMobileDesign && navigationMenuIsOpen ? '100%' : 'fit-content'
    },
    articleScrollContainer: {
      position: 'relative',
      marginTop: THEME.spacing.marginLarge
    }
  })

export const menuLinkStyle = (
  color: string
): {
  menuLink: ViewStyle
} =>
  StyleSheet.create({
    menuLink: {
      width: '100%',
      height: 60,
      padding: 20,
      backgroundColor: color
    }
  })
