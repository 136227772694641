import * as React from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { ArticleKey, MainScreen } from './screens/main'
import {
  useFonts,
  PlayfairDisplay_400Regular,
  PlayfairDisplay_400Regular_Italic,
  PlayfairDisplay_700Bold,
  PlayfairDisplay_700Bold_Italic
} from '@expo-google-fonts/playfair-display'
import {
  DMSans_400Regular,
  DMSans_400Regular_Italic,
  DMSans_700Bold,
  DMSans_700Bold_Italic
} from '@expo-google-fonts/dm-sans'
import {
  SourceCodePro_400Regular,
  SourceCodePro_400Regular_Italic,
  SourceCodePro_600SemiBold,
  SourceCodePro_600SemiBold_Italic
} from '@expo-google-fonts/source-code-pro'
import { View } from 'react-native'
import { appStyle } from './app.style'
import { FormCrsScreen } from './screens/articles/content/form-crs'
import { enableMapSet } from 'immer'
import { THEME } from './constants'
import { PrivacyPolicyScreen } from './screens/articles/content/privacy-policy'
import { ProtectDataScreen } from './screens/articles/content/protect-data'
import { SecurityPolicyScreen } from './screens/articles/content/security-policy'
import { TermsOfServiceScreen } from './screens/articles/content/terms-of-service'
import { stringInStringEnum } from 'global-utils'
import { AppArticleScreen } from './screens/articles/content/app'
import { WhatIsWalkthroughScreen } from './screens/articles/content/what-is-walkthrough'
import { IncentivesScreen } from './screens/articles/content/incentives'
import { MonetizationScreen } from './screens/articles/content/monetization'
import { OurAdviceScreen } from './screens/articles/content/our-advice'
import { TeamScreen } from './screens/articles/content/team'
import Plausible from 'plausible-tracker'
import { FinancialPlanPurchaseConfirmationScreen } from './screens/financial-plan-purchase-confirmation'
import { NotFoundScreen } from './screens/not-found'
import { FinancialPlanScreen } from './screens/articles/financial-plan'
import { FreePlanWaitlistConfirmationScreen } from './screens/free-plan-waitlist-confirmation'
import { TextWalkthroughScreen } from './screens/text-walkthrough'
import { EuphoriaPartnershipScreen } from './screens/euphoria-partnership'
import { textStyle } from './themes/global-styles.style'

enableMapSet()

// NOTE: utm_ params can be appended to the URL for any screen & our analytics tool, Plausible,
// will track them automatically. See https://plausible.io/docs/custom-dimensions#utm-parameters.
// We don't need to specify these parameters as screen params unless the screen needs to access them.
// The format for these query params is a bit strange, because otherwise they are removed from the URL
// (see https://github.com/aws-amplify/amplify-hosting/issues/792 for a description of the issue & the
// suggested solution we're using).
// The format is:
// https://www.walkthrough.co/screenName/?utm_source=source&utm_campaign=campaign
// Note the unusual '/' after the screen name.
// A working example URL: https://www.walkthrough.co/financialPlan/?utm_source=papaya&utm_campaign=mango
//
// Disable eslint to keep this as a type (not an interface) per requirement from
// https://reactnavigation.org/docs/typescript/#type-checking-the-navigator
// eslint-disable-next-line
export type AppStackParamList = {
  NotFoundScreen: undefined
  MainScreen: {
    articleKey: ArticleKey
    utm_source?: string
    utm_campaign?: string
  }
  OurAdviceScreen: undefined
  WhatIsWalkthroughScreen: undefined
  FinancialPlanScreen: {
    utm_source?: string
    utm_campaign?: string
  }
  AppArticleScreen: {
    utm_source?: string
    utm_campaign?: string
  }
  IncentivesScreen: undefined
  MonetizationScreen: undefined
  TeamScreen: undefined
  SecurityPolicyScreen: undefined
  TermsOfServiceScreen: undefined
  PrivacyPolicyScreen: undefined
  ProtectDataScreen: undefined
  FormCrsScreen: undefined
  FinancialPlanPurchaseConfirmationScreen: {
    sessionId: string
  }
  FreePlanWaitlistConfirmationScreen: undefined
  TextWalkthroughScreen: undefined
  EuphoriaPartnershipScreen: undefined
}

const AppStack = createNativeStackNavigator<AppStackParamList>()

const config = {
  screens: {
    EntryScreen: '',
    // TODO: Remove this screen once traffic to source/:sourceId pages has gone to 0 for > 1 month.
    // We can now use utm param tracking to achieve the same thing. (See comment about utm params
    // above the AppStackParamList type.)
    // Entry screen for link tracking incoming clicks from different marketing channels.
    // Plausible tracks specific page views.
    // URLs should be formatted as: https://www.walkthrough.co/source/sourceId, where
    // sourceID is a random string. Random strings can be generated here: https://www.random.org/strings/
    // NOTE: We thought some about using a link shortener service like bit.ly, but found that the popular ones did
    // cookie tracking. We could also look into using links that redirect, or search for other services like bit.ly
    // that are more privacy friendly. We could also potentially use plausible custom props:
    // https://plausible.io/docs/custom-event-goals#using-custom-props if we wanted to link track pages other than
    // the welcome page.
    // Specific URLs for various marketing campaigns are tracked here:
    // https://docs.google.com/spreadsheets/d/1sfQo8g6FXKgJwfVWGk8I4cI0jHcDDrS00uyGoef0X0g/edit#gid=0
    EntryScreenLinkTracking: 'source/:sourceId',
    // You can navigation to a specific article on the main screen by passing the correct
    // ArticleKey.
    MainScreen: {
      path: 'main/:articleKey',
      parse: {
        articleKey: (articleKey: string) =>
          stringInStringEnum(ArticleKey, articleKey)
            ? articleKey
            : ArticleKey.WELCOME
      }
    },
    OurAdviceScreen: 'advice',
    WhatIsWalkthroughScreen: 'whatWeDo',
    FinancialPlanScreen: 'financialPlan',
    AppArticleScreen: 'app',
    IncentivesScreen: 'incentives',
    MonetizationScreen: 'monetization',
    TeamScreen: 'team',
    SecurityPolicyScreen: 'securityPolicy',
    TermsOfServiceScreen: 'termsOfService',
    PrivacyPolicyScreen: 'privacyPolicy',
    FormCrsScreen: 'formCRS',
    ProtectDataScreen: 'protectData',
    FinancialPlanPurchaseConfirmationScreen: 'purchaseConfirmation',
    FreePlanWaitlistConfirmationScreen: 'waitlistConfirmation',
    TextWalkthroughScreen: 'text',
    EuphoriaPartnershipScreen: 'euphoria',
    // Catch all unmatched routes. The NotFoundScreen redirects to the MainScreen.
    // See https://reactnavigation.org/docs/configuring-links/#handling-unmatched-routes-or-404
    NotFoundScreen: '*'
  }
}

const linking = {
  prefixes: [], // Prefixes are not needed for Web. See https://reactnavigation.org/docs/configuring-links/#prefixes
  config
}

const { enableAutoPageviews } = Plausible({
  domain: 'walkthrough.co',
  // Change trackLocalhost to true for local testing.
  trackLocalhost: false
})

enableAutoPageviews()

export default function App (): JSX.Element {
  const [fontsLoaded] = useFonts({
    PlayfairDisplay_400Regular,
    PlayfairDisplay_400Regular_Italic,
    PlayfairDisplay_700Bold,
    PlayfairDisplay_700Bold_Italic,
    DMSans_400Regular,
    DMSans_400Regular_Italic,
    DMSans_700Bold,
    DMSans_700Bold_Italic,
    SourceCodePro_400Regular,
    SourceCodePro_400Regular_Italic,
    SourceCodePro_600SemiBold,
    SourceCodePro_600SemiBold_Italic
  })

  if (!fontsLoaded) {
    return <View style={appStyle.outerContainer} />
  }

  return (
    <NavigationContainer
      linking={linking}
      documentTitle={{
        enabled: false
      }}
    >
      <AppStack.Navigator>
        <AppStack.Screen
          name="MainScreen"
          component={MainScreen}
          options={{
            headerShown: false
          }}
          initialParams={{
            articleKey: ArticleKey.WELCOME
          }}
        />
        <AppStack.Screen
          name="NotFoundScreen"
          component={NotFoundScreen}
          options={{
            headerShown: false
          }}
        />
        <AppStack.Screen
          name="WhatIsWalkthroughScreen"
          component={WhatIsWalkthroughScreen}
          options={{
            headerTitle: 'What We Do',
            headerTitleStyle: { ...textStyle.largeText, ...textStyle.boldText },
            headerShadowVisible: false,
            headerTintColor: THEME.color.onSurface,
            headerStyle: {
              backgroundColor: THEME.color.background
            }
          }}
        />
        <AppStack.Screen
          name="OurAdviceScreen"
          component={OurAdviceScreen}
          options={{
            headerTitle: 'Our Advice',
            headerTitleStyle: { ...textStyle.largeText, ...textStyle.boldText },
            headerShadowVisible: false,
            headerTintColor: THEME.color.onSurface,
            headerStyle: {
              backgroundColor: THEME.color.background
            }
          }}
        />
        <AppStack.Screen
          name="FinancialPlanScreen"
          component={FinancialPlanScreen}
          options={{
            headerShown: false
          }}
        />
        <AppStack.Screen
          name="AppArticleScreen"
          component={AppArticleScreen}
          options={{
            headerTitle: 'The App',
            headerTitleStyle: { ...textStyle.largeText, ...textStyle.boldText },
            headerShadowVisible: false,
            headerTintColor: THEME.color.onSurface,
            headerStyle: {
              backgroundColor: THEME.color.background
            }
          }}
        />
        <AppStack.Screen
          name="IncentivesScreen"
          component={IncentivesScreen}
          options={{
            headerTitle: 'Our Incentives',
            headerTitleStyle: { ...textStyle.largeText, ...textStyle.boldText },
            headerShadowVisible: false,
            headerTintColor: THEME.color.onSurface,
            headerStyle: {
              backgroundColor: THEME.color.background
            }
          }}
        />
        <AppStack.Screen
          name="MonetizationScreen"
          component={MonetizationScreen}
          options={{
            headerTitle: 'How We Make Money',
            headerTitleStyle: { ...textStyle.largeText, ...textStyle.boldText },
            headerShadowVisible: false,
            headerTintColor: THEME.color.onSurface,
            headerStyle: {
              backgroundColor: THEME.color.background
            }
          }}
        />
        <AppStack.Screen
          name="TeamScreen"
          component={TeamScreen}
          options={{
            headerTitle: 'The Founders',
            headerTitleStyle: { ...textStyle.largeText, ...textStyle.boldText },
            headerShadowVisible: false,
            headerTintColor: THEME.color.onSurface,
            headerStyle: {
              backgroundColor: THEME.color.background
            }
          }}
        />
        <AppStack.Screen
          name="SecurityPolicyScreen"
          component={SecurityPolicyScreen}
          options={{
            headerTitle: 'Security Policy',
            headerTitleStyle: { ...textStyle.largeText, ...textStyle.boldText },
            headerShadowVisible: false,
            headerTintColor: THEME.color.onSurface,
            headerStyle: {
              backgroundColor: THEME.color.background
            }
          }}
        />
        <AppStack.Screen
          name="TermsOfServiceScreen"
          component={TermsOfServiceScreen}
          options={{
            headerTitle: 'Terms of Service',
            headerTitleStyle: { ...textStyle.largeText, ...textStyle.boldText },
            headerShadowVisible: false,
            headerTintColor: THEME.color.onSurface,
            headerStyle: {
              backgroundColor: THEME.color.background
            }
          }}
        />
        <AppStack.Screen
          name="PrivacyPolicyScreen"
          component={PrivacyPolicyScreen}
          options={{
            headerTitle: 'Privacy Policy',
            headerTitleStyle: { ...textStyle.largeText, ...textStyle.boldText },
            headerShadowVisible: false,
            headerTintColor: THEME.color.onSurface,
            headerStyle: {
              backgroundColor: THEME.color.background
            }
          }}
        />
        <AppStack.Screen
          name="ProtectDataScreen"
          component={ProtectDataScreen}
          options={{
            headerTitle: 'How does Walkthrough protect my data?',
            headerTitleStyle: { ...textStyle.largeText, ...textStyle.boldText },
            headerShadowVisible: false,
            headerTintColor: THEME.color.onSurface,
            headerStyle: {
              backgroundColor: THEME.color.background
            }
          }}
        />
        <AppStack.Screen
          name="FormCrsScreen"
          component={FormCrsScreen}
          options={{
            headerTitle: 'Form CRS',
            headerTitleStyle: { ...textStyle.largeText, ...textStyle.boldText },
            headerShadowVisible: false,
            headerTintColor: THEME.color.onSurface,
            headerStyle: {
              backgroundColor: THEME.color.background
            }
          }}
        />
        <AppStack.Screen
          name="FinancialPlanPurchaseConfirmationScreen"
          component={FinancialPlanPurchaseConfirmationScreen}
          options={{
            headerShown: false
          }}
        />
        <AppStack.Screen
          name="FreePlanWaitlistConfirmationScreen"
          component={FreePlanWaitlistConfirmationScreen}
          options={{
            headerShown: false
          }}
        />
        <AppStack.Screen
          name="TextWalkthroughScreen"
          component={TextWalkthroughScreen}
          options={{
            headerShown: false
          }}
        />
        <AppStack.Screen
          name="EuphoriaPartnershipScreen"
          component={EuphoriaPartnershipScreen}
          options={{
            headerShown: false
          }}
        />
      </AppStack.Navigator>
    </NavigationContainer>
  )
}
