import * as React from 'react'
import { Text, TextStyle, Platform, ViewStyle, Pressable } from 'react-native'
import * as Linking from 'expo-linking'
import { buttonStyle, textStyle } from '../themes/global-styles.style'
import { THEME } from '../constants'

// TODO: consider using something other than window.open() here so that these links are represented as anchors
// (which might improve search engine crawlability).  Googlebot crawler does not
// render urls- it just sends them to the indexer. The indexer will render them and add newly detected url's back
// to the crawler queue, but this can be very slow/require unnecessary iterations between the indexer and crawler.

export function navigateToHref (href: string): void {
  if (Platform.OS === 'web') {
    window.open(href, '_blank')
  } else {
    Linking.openURL(href).catch(console.log)
  }
}

// An inline anchor that creates a hyperlink to web pages, email addresses etc. See
// https://docs.expo.dev/guides/linking/ for href formatting.
export function Anchor (props: {
  // Text styles nest, so only specify extra styles you want to add to indicate this is an anchor.  By default,
  // we just underline the text.
  style: TextStyle[]
  href: string
  text: string
}): JSX.Element {
  return (
    <Text style={props.style} onPress={() => navigateToHref(props.href)}>
      {props.text}
    </Text>
  )
}

Anchor.defaultProps = {
  style: { textDecorationLine: 'underline' }
}

export function AnchorButton (props: {
  href: string
  text: string
  buttonStyle: ViewStyle[]
  textStyle?: TextStyle[]
}): JSX.Element {
  return (
    <Pressable
      style={props.buttonStyle}
      onPress={() => navigateToHref(props.href)}
    >
      <Text
        style={[
          textStyle.largeText,
          textStyle.boldText,
          ...(props.textStyle ?? [])
        ]}
      >
        {props.text}
      </Text>
    </Pressable>
  )
}

AnchorButton.defaultProps = {
  buttonStyle: [buttonStyle(THEME.color.highlight).inlineButton]
}
