import React from 'react'
import {
  Platform,
  useWindowDimensions,
  View,
  Text,
  Pressable
} from 'react-native'
import LandscapeVertical from '../assets/landscape-vertical'
import LandscapeHorizontal from '../assets/landscape-horizontal'
import { shouldUseMobileDesign } from '../util/use-mobile-design'
import { WebView } from 'react-native-webview'
import {
  confirmationStyle,
  screenWidthSpecificStyle
} from './free-plan-waitlist-confirmation.style'
import { textStyle } from '../themes/global-styles.style'
import OneLineLogo from '../assets/one-line-logo'
import { ParamListBase } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

export function FreePlanWaitlistConfirmationScreen (props: {
  navigation: NativeStackNavigationProp<ParamListBase, string>
}): JSX.Element {
  if (Platform.OS !== 'web') {
    throw new Error('Landing site should never be deployed as a native app.')
  }

  const useMobileDesign = shouldUseMobileDesign(useWindowDimensions().width)
  const screenWidthStyle = screenWidthSpecificStyle(useMobileDesign)

  // TODO: There is a chance that Meta will count the same conversions
  // that Google counts. This might happen if someone first sees both a Meta ad and a Google ad before converting.
  // If we see this happening, we can use the utm_source param passed through from the typeform page to only
  // send Meta conversion pings for the 'meta' utm_source, and only send Google conversion pings for the 'google'
  // utm_source.

  // Duplicate conversions:
  // We don't use session IDs for the conversions here. This is because we can't create a session ID for typeform users
  // unless we pass in a unique ID for each user.
  // See https://community.typeform.com/build-your-typeform-7/unique-code-for-each-submission-1026 for details.
  // We can't do this because we want to be able to send the link via email/slack/etc (not just via our website).
  // So we might get duplicate conversions.

  // Why scripts are in the <head> tag:
  // From Meta's documentation (https://developers.facebook.com/docs/meta-pixel/get-started/#installing-the-pixel):
  // Placing the code within your <head> tags reduces the chances of browsers or third-party code blocking the Pixel's
  // execution. It also executes the code sooner, increasing the chance that your visitors are tracked before they
  // leave your page.
  // Google & Tiktok similarly recommend putting the tag in the <head> tag.

  // Where each of the tag scripts came from:
  // Google: https://ads.google.com/aw/conversions/detail?ocid=1196097329&ascid=1196097329&ctId=6504132887&euid=813499872&__u=3186351328&uscid=1196097329&__c=6069977321&authuser=1&sourceid=emp&workflowSessionId=a224122B1-9D7E-4E20-861D-190C1AD92DEB--1
  // Meta: https://business.facebook.com/events_manager2/list/pixel/1291880318397010/overview?business_id=6745043915524599&global_scope_id=6745043915524599&act=936461127536942&nav_source=flyout_menu
  const tagHTML = `
  <html>
  <head>
  <!-- Google tag (gtag.js) -->
  <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11107802748"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'AW-11107802748');
  </script>
  <!-- Google event snippet for Join waitlist to get a free financial plan conversion -->
  <!-- Event snippet for Join waitlist to get a free financial plan conversion page -->
  <script>
    gtag('event', 'conversion', {'send_to': 'AW-11107802748/hcGGCJfitJ0YEPy8zrAp'});
  </script>
  <!-- Meta Pixel Code -->
  <script>
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '1291880318397010');
  fbq('track', 'PageView');
  </script>
  <noscript><img height="1" width="1" style="display:none"
  src="https://www.facebook.com/tr?id=1291880318397010&ev=PageView&noscript=1"
  /></noscript>
  <!-- End Meta Pixel Code -->
  <!-- Meta event snippet for Lead conversion (the person joins the waitlist) -->
  <script>
  fbq('track', 'Lead');
  </script>
  </head>
  </html>
  `

  return (
    <View style={confirmationStyle.outerContainer}>
      <WebView source={{ html: tagHTML }} />
      <View style={confirmationStyle.backgroundImage}>
        {useMobileDesign ? <LandscapeVertical /> : <LandscapeHorizontal />}
      </View>
      <View style={screenWidthStyle.header}>
        <Pressable
          onPress={() => {
            props.navigation.navigate('EntryScreen')
          }}
        >
          <OneLineLogo />
        </Pressable>
      </View>
      <View style={screenWidthStyle.textContainer}>
        <Text style={[textStyle.extraLargeText, textStyle.boldText]}>
          Thanks for joining the waitlist for a Walkthrough financial plan!
          {'\n\n'}
        </Text>
        <Text style={textStyle.extraLargeText}>
          We'll email you when the wait is over :) We hope you have a most
          excellent day.
        </Text>
      </View>
    </View>
  )
}
