import React from 'react'
import { Text } from 'react-native'
import { THEME } from '../../../constants'
import { buttonStyle, textStyle } from '../../../themes/global-styles.style'
import { Anchor } from '../../../util/anchor'
import {
  NavigationAnchor,
  NavigationButton
} from '../../../util/navigation-anchor'
import { ArticlePage } from '../article-page'

export const incentivesShortCopy = (
  <>
    <Text style={[textStyle.extraLargeText, textStyle.serifBold]}>
      Our Incentives
    </Text>
    <Text style={textStyle.largeText}>
      {'\n'}You need to understand someone's incentives before trusting any
      advice they give you. We've intentionally structured Walkthrough to
      minimize the "just trust us" part of the equation and maximize the "we are
      incentivized to do the right thing" part.
      {'\n\n'}
    </Text>
    <NavigationButton
      text={'Read more'}
      handlePress={(nav) => {
        nav.navigate('IncentivesScreen')
      }}
      buttonStyle={[buttonStyle(THEME.color.outline).underlineButton]}
    />
  </>
)

const incentivesCopy = (
  <>
    <Text style={textStyle.largeText}>
      <Text style={textStyle.italicText}>
        Do you have ideas for other mechanisms we could add to ensure our
        incentives stay aligned with yours? We want more ideas! Let us know at{' '}
        <Anchor
          href="mailto:incentives@walkthrough.co"
          text="incentives@walkthrough.co"
        />
        !{'\n\n'}
      </Text>
      You need to understand someone's incentives before trusting any advice
      they give you. It's nice to believe advisors are looking out for you out
      of the goodness of their heart, but it's even better when they would still
      give you the right advice if they were actually doing whatever's best for
      them.{'\n\n'}
      <Text style={textStyle.boldText}>
        Our mission at Walkthrough is to improve access to financial planning,
        guidance and literacy for the broader public through software
        automation.
      </Text>
      {'\n'}
      We truly care about this. Financial literacy has changed our lives and the
      lives of many of our friends for the better.{'\n\n'}
      But we're a company, too, so we'd forgive you for being skeptical of our
      benevolence. Most companies exist just to make money.{'\n\n'}
      We've intentionally structured Walkthrough to minimize the "just trust us"
      part of the equation and maximize the "we are incentivized to do the right
      thing" part. Here are some ways we've done that:
      {'\n\n'}
      <Text style={textStyle.boldText}>
        We don't get paid for product recommendations or referrals, or get paid
        by anyone other than you.
      </Text>
      {'\n'}
      If we did that, we would be incentivized to recommend products that pay us
      the most, not the ones that are best for you. Read more about how we make
      money{' '}
      <NavigationAnchor
        text={'here'}
        handlePress={(nav) => {
          nav.navigate('MonetizationScreen')
        }}
      />
      .{'\n\n'}
      <Text style={textStyle.boldText}>We don't advertise in-app.{'\n'}</Text>
      If we did that, we would be incentivized to make our app more addicting
      and get you to spend more time in it. Plus, the ads might convince you to
      do something that isn't good for your finances (like opening a new credit
      card you can't afford).{'\n\n'}
      <Text style={textStyle.boldText}>
        We don't offer in-market products like mutual funds, bank accounts, or
        credit cards.{'\n'}
      </Text>
      If we did that, we'd be incentivized to always tell you to use our
      products, even if they weren't in your interest or there were better
      options out there.{'\n\n'}
      <Text style={textStyle.boldText}>
        We charge you a flat fee
        {'\n'}
      </Text>
      <Text style={textStyle.italicText}>
        NOTE: Currently we're giving away financial plans for free to get more
        product feedback and test new ideas, but usually we charge a flat fee.
      </Text>
      {'\n\n'}We charge you a flat fee, because we want to be transparent and
      upfront about what we cost. It also means we aren’t incentivized to give
      certain types of advice that would increase our own revenue. Our only goal
      is to give excellent financial advice, tailored to you.
      {'\n\n'}
      <Text style={textStyle.boldText}>
        We are a PUBLIC BENEFIT CORPORATION{'\n'}
      </Text>
      Walkthrough isn't a normal company — it's a public benefit corporation.
      That means we have special provisions in our charter that let us pursue
      our mission, even at the expense of company profit! This protects the
      company from getting sued by our shareholders if our leaders make ethical
      but unprofitable choices, which makes those choices a lot easier to make.
      {'\n\n'}
      <Text style={textStyle.boldText}>We are a fiduciary{'\n'}</Text>
      This is a fancy legal word that means we are legally required to act in
      your best interest. Whichever advisor you choose, you should definitely
      ensure they are a fiduciary "in all their capacities"! If they aren't, run
      away!
      {'\n\n'}
      We take this obligation really seriously, but it isn't enough — that's why
      it's the last thing on our list. Fiduciaries can still:
      {'\n'}• Get referral fees for recommendations{'\n'}• Recommend their own
      products, even if they underperform their relevant benchmarks or charge
      high fees.{'\n'}• Do anything, really, as long as they can argue a
      reasonable person might recommend that in your best interest. It's a vague
      definition.
      {'\n\n'}… and if you think they did something shady, you have to complain
      to the SEC or start a lawsuit. What a pain! Wouldn't it be better if they
      were incentivized to not do that thing in the first place?{'\n\n'}
      Here's the bottom line:{'\n\n'}
      <Text style={textStyle.boldText}>
        Walkthrough will provide you with affordable, actionable, and
        appropriate financial advice personalized to your situation. We give you
        our word. If that's not enough, look at our incentives.
        {'\n\n'}
      </Text>
    </Text>
  </>
)

export function IncentivesScreen (): JSX.Element {
  return <ArticlePage>{incentivesCopy}</ArticlePage>
}
