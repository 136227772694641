import * as React from "react"
import Svg, { SvgProps, Circle, Path } from "react-native-svg"

const CloseIcon = (props: SvgProps): JSX.Element => (
  <Svg
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <Path
      d="m15.05 24.95 9.9-9.9M24.95 24.95l-9.9-9.9"
      stroke={props.color ?? "#202232"}
      strokeWidth={3}
      strokeLinecap="round"
    />
  </Svg>
)

export default CloseIcon
