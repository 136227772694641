import React from 'react'
import { Text } from 'react-native'
import { textStyle } from '../../../themes/global-styles.style'
import { Anchor } from '../../../util/anchor'
import { NavigationAnchor } from '../../../util/navigation-anchor'
import { ArticlePage } from '../article-page'

const privacyPolicyCopy = (
  <>
    <Text style={textStyle.regularText}>
      <Text style={textStyle.boldText}>Last Updated:</Text> May 31, 2022
      {'\n\n'}
      This Privacy Policy describes how The Company, Inc., doing business as
      Walkthrough, and our affiliates and subsidiaries (collectively,
      “Walkthrough”, “we”, “our”, or “us”) collects, uses, and discloses
      information collected through websites and mobile applications owned and
      operated by us that link to this Privacy Policy, such as the Walkthrough
      mobile app and www.walkthrough.co, and any other interactions (e.g.,
      customer service and other communications) that you may have with
      Walkthrough (collectively, the “Services”).
      {'\n\n'}
      PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND HOW WE HANDLE YOUR
      INFORMATION. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, PLEASE DO NOT USE
      THE SERVICES.
      {'\n\n'}
      This Privacy Policy includes the following sections:
      {'\n'}1.{'\t'}Information We Collect
      {'\n'}2.{'\t'}How We Use Your Information
      {'\n'}3.{'\t'}Cookies and Similar Technologies
      {'\n'}4.{'\t'}Online Analytics and Advertising
      {'\n'}5.{'\t'}How We Share Your Information
      {'\n'}6.{'\t'}How We Protect Your Information
      {'\n'}7.{'\t'}Retention of Information
      {'\n'}8.{'\t'}Third Party Links and Features
      {'\n'}9.{'\t'}Children's Privacy
      {'\n'}10.{'\t'}Your Choices
      {'\n'}11.{'\t'}Notice to California Residents
      {'\n'}12.{'\t'}Notice to Nevada Residents
      {'\n'}13.{'\t'}Changes to this Privacy Policy
      {'\n'}14.{'\t'}Contact Information
      {'\n\n'}
      <Text style={textStyle.boldText}>1. INFORMATION WE COLLECT</Text>
      {'\n\n'}
      <Text style={textStyle.boldText}>
        Information You Provide Directly.
      </Text>{' '}
      We collect information that you provide when you use the Services or
      otherwise communicate with us. For example, when you:
      {'\n'}• Create an account to use the Services, we may collect contact and
      registration information, such as your name and email address. We may also
      collect payment information, including your payment card number and
      associated billing information, and other profile information you upload
      to your account.
      {'\n'}• Interact with Services, we may collect the information that you
      upload or provide, including financial information such as your account
      balance, descriptions of financial transactions, investment interests,
      budget names and amounts (“Financial Information”), information about
      other members, credentials to facilitate integrations with Financial Data
      Sources (as such term is defined below), and descriptions regarding the
      foregoing.
      {'\n'}• Contact us with questions, comments, or for customer service, we
      may collect contact information, such as your name and email address, as
      well as any information you provide in connection with your outreach. We
      will also collect any information you provide to publicly-accessible parts
      of the Services. Participate in promotions, events, surveys, and
      sweepstakes, we may collect basic contact information and any other
      information you provide in connection with these activities.
      {'\n'}• Choose to integrate the Services with other apps/functionalities
      on your mobile device (e.g., your photos or contacts), we may collect
      information contained in these other apps/functionalities in accordance
      with your app and device settings.
      {'\n\n'}
      <Text style={textStyle.boldText}>
        Information You Provide via Integration with Financial Data Sources.
      </Text>{' '}
      We collect information about you when you connect your registered account
      with us to financial institutions and other third-party data sources
      (“Financial Data Sources”) or you otherwise provide us with access to
      information from those Financial Data Sources. For example, you may choose
      to connect your bank or other financial accounts with your Walkthrough
      account. To sync information from these Financial Data Sources, we will
      collect your Financial Information including your account balance,
      transactions, and holdings. We may also collect certain information
      necessary to facilitate and maintain an integration with a Financial Data
      Source that you have chosen to make, including API keys required to
      connect your registered account with us to cryptocurrency exchanges or
      wallets. We do not process or store any of your log-in credentials to any
      other account or service other than Walkthrough's. We may use third-party
      data sources to assist in collecting and syncing your information,
      including Financial Information, from Financial Data Sources, and by
      connecting your Walkthrough account to a Financial Data Source, you agree
      to all applicable third-party sources' terms and conditions and privacy
      policies.{'\n\n'}
      <Text style={textStyle.boldText}>Information from Other Sources.</Text> We
      also obtain information from other sources, like other members of the
      Services, payment service providers who provide us with payment or balance
      information, analytics service providers, and security service providers,
      who provide us with information to secure our systems, prevent fraud and
      help us protect the security of the Services. If you access the Services
      through a third-party connection or log-in (e.g., through Sign in with
      Apple, Google, or Facebook), you may allow us to have access to and store
      certain information from such third-party services, depending on your
      settings on that service. If you do not wish to have this information
      shared, you may be able to adjust your account settings on those Services,
      or choose not to use these connections to access our Services. Please see
      the privacy policies and terms for these third parties for more
      information.{'\n\n'}
      <Text style={textStyle.boldText}>
        Automatically Collected Information.
      </Text>{' '}
      We may automatically receive and store certain information about the
      computer or device you use to access the Services. As discussed further
      below, we and our service providers may use a variety of technologies,
      including cookies, to assist in this information.
      {'\n'}• Device Information. We collect technical information associated
      with the device(s) you use to access our Services, including information
      related to your browser and operating system, IP address (the Internet
      address of your computer), unique device identifiers, and other
      information such as your device type.
      {'\n'}• Usage Information. We also collect a variety of information to
      better understand your use of our Services. This may include the webpages,
      screens, or features of our Services you use, how much time you spend
      there, date, time and use, how you engage with the Services, information
      about your usage preferences, whether you open an email and your
      interaction with email content, access times, error logs, and other
      similar information. If you access our Services via a link on a
      third-party website, we may also receive certain click-through data such
      as the webpage that you were visiting before accessing our Services.
      {'\n'}• Location Information. We also may collect your device's IP address
      or your mobile device's GPS signal (with your permission in accordance
      with your device settings) when you use certain Services as well as the
      city, state, county, and ZIP code associated with that information.
      {'\n\n'}
      <Text style={textStyle.boldText}>2. HOW WE USE YOUR INFORMATION</Text>
      {'\n'}We use your information in a variety of ways to provide the Services
      and to operate our business. In particular, we may use your information
      to:
      {'\n'}• provide the information and Services you request;
      {'\n'}• provide you with effective customer service;
      {'\n'}• personalize your experience and our communications with you;
      {'\n'}• engage in transactions with you, including contacting you about
      your account, billing you for the Services, and processing payments;
      {'\n'}• contact you with operational information and notices related to
      your use of the Services;
      {'\n'}• contact you with special offers and other promotional content
      related to our Services that we believe will be of interest to you (in
      accordance with any privacy preferences you have expressed to us);
      {'\n'}• invite you to participate in promotions, sweepstakes, surveys and
      provide feedback to us;
      {'\n'}• analyze use of the Services and improve the content, functionality
      and usability of the Services;
      {'\n'}• secure the Services and investigate and help prevent fraud,
      security issues, and abuse;
      {'\n'}• understand and resolve app crashes and other issues being
      reported;
      {'\n'}• comply with any procedures, laws, and regulations where necessary
      for our legitimate interests or legitimate interests of others;
      {'\n'}• establish, exercise, or defend our legal rights where necessary
      for our legitimate interests or the legitimate interests of others,
      including the enforcement of our{' '}
      <NavigationAnchor
        text="Terms of Service"
        handlePress={(nav) => {
          nav.navigate('TermsOfServiceScreen')
        }}
      />
      , other usage policies, and other legal terms or controls, or to engage in
      other legal matters; and
      {'\n'}• for any other purpose with your consent.
      {'\n\n'}
      <Text style={textStyle.boldText}>Combined Information.</Text> For the
      purposes discussed in this Privacy Policy, we may combine the information
      that we collect through the Services with information that we receive from
      other sources, both online and offline, and use such combined information
      in accordance with this Privacy Policy.
      {'\n\n'}
      <Text style={textStyle.boldText}>
        Aggregate/De-Identified Information.
      </Text>{' '}
      We may aggregate and/or de-identify any information collected through the
      Services so that such information can no longer be directly linked to you
      or your device (“Aggregate/De-Identified Information”). We may use
      Aggregate/De-Identified/Anonymized Information for any first-party or
      third-party purpose, including for research and marketing purposes. We
      will never share data granular enough that it can be reassociated with you
      or a single pseudonymous identity.
      {'\n\n'}
      <Text style={textStyle.boldText}>
        3. COOKIES AND SIMILAR TECHNOLOGIES
      </Text>
      {'\n'}To collect the information in the “Automatically Collected
      Information” section above, we and our service providers may use web
      server logs, cookies, tags, SDKs, tracking pixels, and similar tracking
      technologies. We use these technologies in order to offer you a more
      tailored experience in the future.
      {'\n'}• A web server log is a file where website activity is stored.
      {'\n'}• An SDK is a set of tools and/or code that we embed in our
      applications and software to allow third parties to collect information
      about how members interact with the Services.
      {'\n'}• A cookie is a small text file that is placed on your computer or
      mobile device when you use the Services, that enables us to: (i) recognize
      your computer; (ii) store your preferences and settings; (iii) understand
      the web pages of the Services you have visited; (iv), enhance your member
      experience by delivering and measuring the effectiveness of content and
      advertising tailored to your interests; (v) perform searches and
      analytics; and (vi) assist with security and administrative functions.
      Some cookies are placed in your browser cache while those associated with
      Flash technologies are stored with your Adobe Flash Player files.
      {'\n'}• Tracking pixels (sometimes referred to as web beacons or clear
      GIFs) are tiny electronic tags with a unique identifier embedded in
      websites, online ads and/or email that are designed to: (1) collect usage
      information like ad impressions or clicks and email open rates; (2)
      measure popularity of the Services and associated advertising; and (3)
      access member cookies.
      {'\n'}As we adopt additional technologies, we may also gather information
      through other methods. We may also collect, access, or store session
      cookies to facilitate integrations with Financial Data Sources. Please
      note that you can change your settings to notify you when a cookie is
      being set or updated, or to block cookies altogether. Please consult the
      “Help” section of your browser for more information (e.g.,{' '}
      <Anchor
        href="https://support.google.com/chrome/answer/95647?hl=en"
        text="Google Chrome"
      />
      ;{' '}
      <Anchor
        href="http://kb.mozillazine.org/Cookies#Firefox"
        text="Mozilla Firefox"
      />
      ; or{' '}
      <Anchor
        href="https://support.apple.com/kb/PH5042?locale=en_US"
        text="Apple Safari"
      />
      ). You can also manage the use of Flash technologies, including flash
      cookies and local storage objects with the Flash management tools
      available at{' '}
      <Anchor
        href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager02.html"
        text="Adobe's website"
      />
      . Please note that by blocking, disabling, or managing any or all cookies,
      you may not have access to certain features or offerings of the Services.
      {'\n\n'}
      <Text style={textStyle.boldText}>
        4. ONLINE ANALYTICS AND ADVERTISING
      </Text>
      {'\n'}We may use third-party analytics services (such as Google Analytics)
      on our Services to collect and analyze usage information through cookies
      and similar technologies; engage in auditing, research, or reporting;
      assist with fraud prevention; and provide certain features to you. To
      prevent Google Analytics from using your information for analytics, you
      may install the Google Analytics Opt-out Browser Add-on by clicking{' '}
      <Anchor href="https://tools.google.com/dlpage/gaoptout" text="here" />.
      {'\n\n'}We may also work with a number of companies that assist in
      marketing our services to you on third party websites, mobile apps, and
      online services. These third parties may use cookies, web beacons or other
      tracking technologies to collect information about your use of the
      Services and your activities across other websites and online services,
      which they may associate with persistent identifiers. Sometimes, these
      identifiers may be derived from a hashed or encrypted version of personal
      information such as your email address. We may use this information to
      measure the performance of our advertising as well, for instance, to
      evaluate which ads or content our members prefer, or which are most
      effective. In addition, sometimes we or an advertising partner may tailor
      advertising to you across different devices.
      {'\n\n'}The activities of these third parties and your choices regarding
      their use of your information to personalize ads to you are subject to and
      set out in the third parties' own policies. We neither have access to, nor
      does this Privacy Policy govern, the use of cookies or other tracking
      technologies that may be placed on your computer, mobile phone, or other
      device by non-affiliated, third-party providers. As described below, these
      providers may offer you a way to opt-out of the collection of information
      that is used for our interest-based advertising to you. We cannot
      guarantee that these instructions will not change, or that they will
      continue to be available; they are controlled by each third-party service
      provider, not us.
      {'\n\n'}If you are interested in more information about interest-based
      advertising and how you can generally control cookies other tracking
      technologies from being put on your computer to deliver such advertising,
      you may visit the{' '}
      <Anchor
        href="http://www.networkadvertising.org/choices"
        text="Network Advertising Initiative's Consumer Opt-Out link"
      />
      , the{' '}
      <Anchor
        href="http://www.aboutads.info/choices/"
        text="Digital Advertising Alliance's (DAA's) Consumer Opt-Out link"
      />
      , or{' '}
      <Anchor
        href="http://preferences-mgr.truste.com/"
        text="TrustArc's Advertising Choices Page"
      />
      . To opt out of the display of interest-based advertising from Google,
      please visit the{' '}
      <Anchor
        href="https://www.google.com/settings/ads"
        text="Google Ads Settings page"
      />
      . For more information and to exercise your choices regarding Facebook
      and/or Instagram ads, please visit the{' '}
      <Anchor
        href="https://www.facebook.com/help/1075880512458213"
        text="Facebook Ads Settings page"
      />{' '}
      and/or the{' '}
      <Anchor
        href="https://help.instagram.com/478880589321969/?helpref=hc_fnav&bc%5b0%5d=Instagram%20Help&bc%5b1%5d=Managing%20Your%20Account"
        text="Instagram Ads Settings page"
      />
      .{'\n\n'}
      <Text style={textStyle.boldText}>Notice Concerning Do Not Track.</Text> Do
      Not Track (“DNT”) is a privacy preference that members can set in certain
      web browsers. We are committed to providing you with meaningful choices
      about the information collected on our website for third party purposes,
      and that is why we provide the variety of opt-out mechanisms listed above.
      Because of the changing state of technology and indecision within the
      industry regarding the meaning of DNT signals, we currently do not make
      any guarantee that we will honor DNT signals. Learn more about{' '}
      <Anchor href="https://allaboutdnt.com/" text="Do Not Track" />.{'\n\n'}
      <Text style={textStyle.boldText}>5. HOW WE SHARE YOUR INFORMATION</Text>
      {'\n'}We do not share Financial Information (whether provided by you
      manually or via a Financial Data Source integration) with (1) other
      companies for joint marketing purposes; or (2) any third parties so they
      can market to you. We may share, transfer, or disclose information
      collected from and about you in the following circumstances:
      {'\n\t• '}
      <Text style={textStyle.boldText}>Service Providers:</Text> We may share
      information with select third parties who provide services to us, such as
      customer service, interfacing with Financial Data Sources, analytics,
      website management, information technology, and other similar services.
      {'\n\t• '}
      <Text style={textStyle.boldText}>Financial Data Sources:</Text> We may
      share information with the Financial Data Sources to help establish or
      maintain an integration you have chosen to make.
      {'\n\t• '}
      <Text style={textStyle.boldText}>
        Other Members of this Service or the Public:
      </Text>{' '}
      To the extent that you post content in publicly-accessible parts of the
      Services, or choose to make certain content or account information
      viewable by other members or the public, such content and information can
      be read, collected, and used by others with permission to view it.
      {'\n\t• '}
      <Text style={textStyle.boldText}>
        Comply with Laws and Protect Our Rights and the Rights of Others:
      </Text>
      We may disclose your information as we believe to be necessary or
      appropriate to: comply with applicable law and legal processes; respond to
      requests from public and government authorities, including public and
      government authorities outside your country of residence; enforce our{' '}
      <NavigationAnchor
        text="Terms of Service"
        handlePress={(nav) => {
          nav.navigate('TermsOfServiceScreen')
        }}
      />
      ; protect our rights, privacy, safety, or property, and/or that of our
      affiliates, you, or others; and allow us to pursue available remedies or
      limit the damages that we may sustain.
      {'\n\t• '}
      <Text style={textStyle.boldText}>Corporate Transactions:</Text> We reserve
      the right to transfer your information to service providers, advisors,
      potential transactional partners, or other third parties in connection
      with the consideration, negotiation, or completion of a corporate
      transaction in which we are acquired by or merged with another company or
      we sell, liquidate, or transfer all or a portion of our assets.
      {'\n\t• '}
      <Text style={textStyle.boldText}>
        With Your Consent or At Your Direction:
      </Text>{' '}
      We may share information with third parties when you direct us to do so or
      if you have consented to additional sharing of your information (including
      as set out in this Privacy Policy).
      {'\n\n'}
      <Text style={textStyle.boldText}>6. HOW WE PROTECT YOUR INFORMATION</Text>
      {'\n'}We implement a variety of security safeguards designed to protect
      your information. However, no method or transmission over the internet or
      electronic system is completely secure, so there is no guarantee that such
      data may not be accessed, disclosed, altered, or destroyed in connection
      with a breach of our physical, technical, organizational, or managerial
      safeguards. When you create an account, you may be prompted to create a
      username and password. If you create an account with us, you are
      responsible for maintaining the confidentiality of your account password
      and for any activity that occurs under your account. We are not
      responsible for any loss or damage arising from your failure to maintain
      the confidentiality of your password. We urge you to change your passwords
      often, use a combination of letters and numbers, and make sure you are
      using a secure browser. If you have reason to believe that your
      interaction with us is no longer secure or if you suspect someone else is
      using your account, please let us know immediately by contacting us as
      indicated in the “Contact Information” section below. For additional
      details about the steps we take to protect your information, please review
      our{' '}
      <NavigationAnchor
        text="Security Policy"
        handlePress={(nav) => {
          nav.navigate('SecurityPolicyScreen')
        }}
      />
      .{'\n\n'}
      <Text style={textStyle.boldText}>7. RETENTION OF INFORMATION</Text>
      {'\n'}We will retain your information for at least as long as your account
      is active or as needed to comply with applicable legal obligations. We
      will also retain and use your information as necessary to resolve
      disputes, protect Walkthrough and our members, and enforce our agreements.
      {'\n\n'}
      <Text style={textStyle.boldText}>8. THIRD PARTY LINKS AND FEATURES</Text>
      {'\n'}The Services may contain links, banners, or widgets to third-party
      sites, apps, services, and plug-ins, including Financial Data Sources. We
      may also incorporate third-party software (including open source software)
      in the Services. These links and features are provided for your reference
      and convenience only and do not imply any endorsement of information
      provided through these third-party links and features, nor any association
      with their operators. Walkthrough is not responsible for the practices of
      such third parties. Any information you provide to third parties on their
      websites or services is covered under their privacy and data collection
      collections, and is not covered by this Privacy Policy. We urge you to
      read the privacy and security policies of these third parties.
      {'\n\n'}
      <Text style={textStyle.boldText}>9. CHILDREN'S PRIVACY</Text>
      {'\n'}The Services are intended for a general audience over the age of 18
      and is not intended for children under the age of 13. We do not knowingly
      collect, maintain, or use personal information (as defined by the United
      States Children's Online Privacy Protection Act) from children under 13
      years of age. If you believe that we might have any such information from
      a child, please contact us as described at the end of this Privacy Policy.
      If we learn that we have collected any personal information from children
      under 13, we will promptly take steps to delete such information and
      terminate the child's account.
      {'\n\n'}
      <Text style={textStyle.boldText}>10. YOUR CHOICES</Text>
      {'\n'}Marketing Communications. You may instruct us not to use your
      information to contact you by email, postal mail, or phone regarding
      products, services, promotions and special events that might appeal to
      your interests by contacting us using the information below. You can
      opt-out of receiving further promotional emails from us by following the
      unsubscribe instructions provided in the promotional email you receive or
      by emailing us directly at{' '}
      <Anchor
        href="mailto:support@walkthrough.co"
        text="support@walkthrough.co"
      />{' '}
      with “OPT-OUT”, “UNSUBSCRIBE”, “STOP”, or “REMOVE” in the subject line.
      Removing your name from the email list may take a reasonable amount of
      time. Please note that, regardless of your request, we may still use and
      share certain information as permitted by this Privacy Policy or as
      required by applicable law. For example, you may not opt out of certain
      operational emails, such as those reflecting our relationship or
      transactions with you. Account Information. To keep your information
      accurate, current, and complete or delete your information, please contact
      us through the app or at{' '}
      <Anchor
        href="mailto:support@walkthrough.co"
        text="support@walkthrough.co"
      />
      . Additional Information. If you do not want the Service to collect
      information through the use of cookies, your browser may allow you to be
      notified when you receive certain types of cookies and restrict or disable
      certain cookies. Each browser is different, so you should check your
      browser's “Help” menu to learn how to change your cookie preferences.
      Please be aware that if you disable or reject cookies, some features may
      not work properly.
      {'\n\n'}
      <Text style={textStyle.boldText}>11. NOTICE TO CALIFORNIA RESIDENTS</Text>
      {'\n'}California's Consumer Privacy Act (CCPA) gives California residents
      the right under certain circumstances to request information from us
      regarding the manner in which we share certain categories of “personal
      information” (as defined in the CCPA) with third parties for their direct
      marketing purposes. We do not share your “personal information” with third
      parties for their own direct marketing purposes.
      {'\n\n'}
      <Text style={textStyle.boldText}>12. NOTICE TO NEVADA RESIDENTS</Text>
      {'\n'}Certain Nevada consumers may opt out of the sale of “personally
      identifiable information” for monetary consideration (as such terms are
      defined under Nevada law) to a person for that person to license or sell
      such information to additional persons. We do not engage in such activity;
      however, if you are a Nevada resident who has purchased or leased goods or
      services from us, you may submit a request to opt out of any potential
      future sales under Nevada law by contacting us at{' '}
      <Anchor
        href="mailto:support@walkthrough.co"
        text="support@walkthrough.co"
      />
      . Please note we will take reasonable steps to verify your identity and
      the authenticity of the request. Once verified, we will maintain your
      request in the event our practices change.
      {'\n\n'}
      <Text style={textStyle.boldText}>13. CHANGES TO THIS PRIVACY POLICY</Text>
      {'\n'}We reserve the right to update or modify this Privacy Policy at any
      time to reflect changes in the law, our data collection and use practices,
      the features of our Services, or advances in technology. We will make the
      revised Privacy Policy accessible through use of the Services, so you
      should review the Privacy Policy periodically. You can know if the Privacy
      Policy has changed since the last time you reviewed it by checking the
      “Last Updated” date included at the beginning of the document. If we make
      a material change to the Policy, we will provide you with notice in
      accordance with legal requirements. Your use of the Services following any
      such change constitutes your agreement that all information collected from
      or about you after the revised Privacy Policy is posted will be subject to
      the terms of the revised Privacy Policy.
      {'\n\n'}
      <Text style={textStyle.boldText}>14. CONTACT INFORMATION</Text>
      {'\n'}If you have any questions or comments about this Privacy Policy, our
      privacy practices, please contact us at{' '}
      <Anchor
        href="mailto:support@walkthrough.co"
        text="support@walkthrough.co"
      />{' '}
      or by writing to us at:
      {'\n'}
      {'\n'}The Company, Inc.
      {'\n'}1532 Pine Street, Apt 1R
      {'\n'}Philadelphia, PA 19102
    </Text>
  </>
)

export function PrivacyPolicyScreen (): JSX.Element {
  return <ArticlePage>{privacyPolicyCopy}</ArticlePage>
}
