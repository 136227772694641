import React from 'react'
import { Pressable, View, Text, useWindowDimensions } from 'react-native'
import { textStyle } from '../themes/global-styles.style'
import { shouldUseMobileDesign } from './use-mobile-design'
import { bannerScreenWidthStyle } from './banner.style'
import { ParamListBase, useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

export function Banner (props: {
  utmSource?: string
  utmCampaign?: string
}): JSX.Element {
  const useMobileDesign = shouldUseMobileDesign(useWindowDimensions().width)
  const bannerStyle = bannerScreenWidthStyle(useMobileDesign)
  const navigation =
    useNavigation<NativeStackNavigationProp<ParamListBase, string>>()
  return (
    <Pressable
      style={bannerStyle.banner}
      onPress={() => {
        navigation.navigate('FinancialPlanScreen', {
          utm_source: props.utmSource,
          utm_campaign: props.utmCampaign
        })
      }}
    >
      <View>
        <Text
          style={[
            useMobileDesign ? textStyle.smallText : textStyle.largeText,
            bannerStyle.bannerText
          ]}
        >
          <Text style={textStyle.boldText}>Limited time only: </Text>Walkthrough
          is giving away financial plans! Get yours today.
        </Text>
      </View>
    </Pressable>
  )
}
