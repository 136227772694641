import React from 'react'
import { Text } from 'react-native'
import { THEME } from '../../../constants'
import { buttonStyle, textStyle } from '../../../themes/global-styles.style'
import { Anchor } from '../../../util/anchor'
import { NavigationButton } from '../../../util/navigation-anchor'
import { ArticlePage } from '../article-page'

export const teamShortCopy = (
  <>
    <Text style={[textStyle.extraLargeText, textStyle.serifBold]}>
      Founders
    </Text>
    <Text style={textStyle.largeText}>
      {'\n'}Walkthrough was founded by three good friends from Yale (
      <Anchor
        href="https://www.linkedin.com/in/eric-anderson-14b325aa/"
        text="Eric"
      />
      {', '}
      <Anchor
        href="https://www.linkedin.com/in/megan-valentine-47023474/"
        text="Megan"
      />
      {', and '}
      <Anchor
        href="https://www.linkedin.com/in/ben-bartolome-4941a667/"
        text="Ben"
      />
      ) who learned personal finance as young adults. They were frustrated by
      misinformation and lackluster offerings in the personal finance space and
      decided to build a company to do it better.{'\n\n'}
    </Text>
    <NavigationButton
      text={'Read more'}
      handlePress={(nav) => {
        nav.navigate('TeamScreen')
      }}
      buttonStyle={[buttonStyle(THEME.color.outline).underlineButton]}
    />
  </>
)

const teamCopy = (
  <>
    <Text style={textStyle.largeText}>
      Walkthrough was founded by three good friends (Eric, Megan and Ben) who
      learned personal finance as young adults. They were frustrated by
      misinformation and lackluster offerings in the personal finance space and
      decided to build a company to do it better. Personal finance doesn't have
      to be hard! It doesn't have to be boring! And most of all, the advice does
      NOT have to be expensive. They are working to make Walkthrough the
      software they wished they had when they were starting out their financial
      journeys: an app where folks can get accessible and tailored advice,
      monitor their financial life all in one place, and engage with a
      supportive community.
      {'\n\n'}
      <Anchor
        href="https://www.linkedin.com/in/ben-bartolome-4941a667/"
        text="Ben"
      />{' '}
      is the co-CEO of Walkthrough. He was born and raised in New Orleans and
      didn't grow up with much in the way of money, though he was surrounded and
      supported by incredible friends and family. He's been a fierce advocate
      for marginalized communities in his life, particularly Latino and
      First-Generation/Low-Income folks. After graduating from Yale, Ben worked
      in finance for 6 years, bringing those learnings to his community back
      home. While working at the Navy Federal Credit Union as a data scientist,
      Ben has mentored many people through career changes from non-technical
      positions to become data analysts. He strongly believes anyone can learn
      to do anything, especially personal finance 😇. Ben also serves as the
      youngest member and executive officer on the Yale Alumni Association's
      Board of Governors. If you'd like to read more about his story,{' '}
      <Anchor
        href="https://docs.google.com/document/d/1O1pkL96NFs9MbjmHYVEM0PbM1ThNpHNgDoL9iR_LrKA/edit"
        text="click here"
      />
      !{'\n\n'}
      <Anchor
        href="https://www.linkedin.com/in/eric-anderson-14b325aa/"
        text="Eric"
      />{' '}
      is our CTO. He's a computer scientist at heart and cares deeply about
      security, privacy, building robust systems, and understanding all the
      details. He also sails a lot and was the training partner for the U.S.
      Finn olympian this past quad — crazy given he was working full-time at
      Google the whole time! His work background is in ads privacy, where he
      learned how to keep gigantic software ecosystems and datasets compliant
      with global privacy law. He went to Yale too (that's where they all met),
      and graduated summa cum laude with a degree in Computer Science and
      Mechanical Engineering.
      {'\n\n'}
      <Anchor
        href="https://www.linkedin.com/in/megan-valentine-47023474/"
        text="Megan"
      />{' '}
      is the co-CEO of Walkthrough. She does all the things—coding, design,
      product, UXR. She cares about humans, about building things that help
      people in real, tangible ways. She is kind and brave. Megan worked at
      Google as a software engineer for five years before this—optimizing things
      in Ads, building an internal startup from the ground up in Area 120, and
      transforming ML research into consumer products in Google Brain. She
      graduated summa cum laude from Yale with Exceptional Distinction in
      Computing & the Arts.
      {'\n\n'}Note: Megan wrote this. Eric and Ben wouldn't brag this much about
      themselves, but they're real excellent humans, and I couldn't help myself.
    </Text>
  </>
)

export function TeamScreen (): JSX.Element {
  return <ArticlePage>{teamCopy}</ArticlePage>
}
