import React from 'react'
import { Pressable, Text } from 'react-native'
import { THEME, WAITLIST_FOR_FREE_FINANCIAL_PLAN } from '../../../constants'
import { buttonStyle, textStyle } from '../../../themes/global-styles.style'
import { Anchor, AnchorButton } from '../../../util/anchor'
import {
  NavigationAnchor,
  NavigationButton
} from '../../../util/navigation-anchor'

export const financialPlanIntroCopy = (
  <>
    <Text>
      Financial plans at Walkthrough are free, at least for a little while! You
      tell us about your financial picture through a detailed survey — things
      like bank accounts, risk tolerance, and goals — and we build a plan
      tailored to your personal situation. Here's{' '}
      <Anchor
        href={'https://docsend.com/view/g4uw5kwgxfqwfrtf'}
        text={'an example plan'}
      />{' '}
      to get a sense of what it's like.
    </Text>
  </>
)

export const financialPlanShortCopy: (
  utmSource?: string,
  utmCampaign?: string
) => React.ReactNode = (utmSource, utmCampaign) => {
  return (
    <>
      <Text style={[textStyle.extraLargeText, textStyle.serifBold]}>
        Get a Financial Plan
      </Text>
      <Text style={textStyle.largeText}>
        {'\n'}
        {financialPlanIntroCopy}
        {'\n\n'}
      </Text>
      <NavigationButton
        text={'Read more'}
        handlePress={(nav) => {
          nav.navigate('FinancialPlanScreen', {
            utm_source: utmSource,
            utm_campaign: utmCampaign
          })
        }}
        buttonStyle={[buttonStyle(THEME.color.outline).underlineButton]}
      />
      <AnchorButton
        href={WAITLIST_FOR_FREE_FINANCIAL_PLAN}
        text={'Get a plan'}
        buttonStyle={[buttonStyle(THEME.color.onSurface).smallButton]}
        textStyle={[
          textStyle.largeText,
          textStyle.boldText,
          { color: THEME.color.background }
        ]}
      />
    </>
  )
}

export const whatIsIncludedInPlanCopy = (
  <>
    <Text>
      Walkthrough financial plans give you an overview of your financial
      journey, and help you take action to reach your financial goals. We can
      help with all sorts of things, like:
      {'\n\n'}• What your investment allocation should be based on your age and
      risk tolerance
      {'\n'}• Where you are in your journey to financial independence and
      freedom
      {'\n'}• What financial tasks to prioritize first
      {'\n'}• Whether or not you need life insurance
      {'\n'}• How much money you should have in your emergency fund
      {'\n'}• and much more!
      {'\n\n'}Here's{' '}
      <Anchor
        href={'https://docsend.com/view/g4uw5kwgxfqwfrtf'}
        text={'an example plan'}
      />{' '}
      to get a sense of what it's like.
    </Text>
  </>
)

export const whyTrustWalkthroughCopy = (
  <>
    <Text>
      Our advice is accessible, down-to-earth, and backed by the leading
      financial research out there. We're a Registered Investment Advisor and
      are purpose-built to give ethical, unconflicted advice. Read more about{' '}
      <NavigationAnchor
        text={'Our Incentives'}
        handlePress={(nav) => {
          nav.navigate('IncentivesScreen')
        }}
      />{' '}
      and{' '}
      <NavigationAnchor
        text={'How We Make Money'}
        handlePress={(nav) => {
          nav.navigate('MonetizationScreen')
        }}
      />
      .
    </Text>
  </>
)

export const whyFreePlansCopy = (
  <>
    <Text>
      Walkthrough is giving away financial plans for free to get more feedback
      on our products & test out new ideas. We don't expect our plans to always
      be free, but they are right now!
    </Text>
  </>
)

export const whatShouldIExpectCopy = (
  <>
    <Text>
      1. You'll 🎉{' '}
      <Anchor
        href={WAITLIST_FOR_FREE_FINANCIAL_PLAN}
        text={'join the waitlist for a free financial plan here'}
      />{' '}
      🎉.
      {'\n'}2. We'll take people off the waitlist as fast as we can. When we
      take you off the waitlist, we'll send you some questions that give us
      the full picture of your financial situation.
      {'\n'}3. Once you fill out the questions, we'll build you a plan with
      your next financial steps with all the insights you need to understand
      it.
      {'\n'}4. We'll send you your plan & instructions for how to get the
      Walkthrough app via email.
      {'\n'}5. If you've opted into texting with your financial coach, you'll
      get a text from them to help get you started.
    </Text>
  </>
)
