import { StyleSheet, ViewStyle } from 'react-native'
import { MAX_WIDTH_FOR_MOBILE_DESIGN, THEME } from '../../constants'

export const articleStyle = StyleSheet.create({
  background: {
    width: '100%',
    height: '100%',
    backgroundColor: THEME.color.background
  },
  accent: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '55%',
    height: '80%',
    margin: THEME.spacing.marginLarge,
    borderTopLeftRadius: 10000,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 10000,
    backgroundColor: THEME.color.surfaceOne
  }
})

export const articleWidthDependentStyle = (
  useMobileDesign: boolean
): {
  articleBackground: ViewStyle
} =>
  StyleSheet.create({
    articleBackground: {
      width: useMobileDesign ? 'auto' : '55%',
      minWidth: useMobileDesign
        ? 0
        : MAX_WIDTH_FOR_MOBILE_DESIGN - THEME.spacing.marginMedium * 2,
      zIndex: 0,
      marginHorizontal: useMobileDesign
        ? THEME.spacing.marginMedium
        : THEME.spacing.marginLarge,
      marginTop: useMobileDesign
        ? THEME.spacing.marginMedium
        : THEME.spacing.marginLarge,
      marginBottom: 300,
      padding: THEME.spacing.marginMedium,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 10000,
      borderBottomLeftRadius: 10000,
      borderBottomRightRadius: 5,
      backgroundColor: THEME.color.surfaceOne
    }
  })
