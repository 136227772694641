import React from 'react'
import { Text } from 'react-native'
import { THEME } from '../../../constants'
import { buttonStyle, textStyle } from '../../../themes/global-styles.style'
import {
  NavigationButton,
  NavigationAnchor
} from '../../../util/navigation-anchor'
import { ArticlePage } from '../article-page'
import { Anchor } from '../../../util/anchor'

export const adviceShortCopy = (
  <>
    <Text style={[textStyle.extraLargeText, textStyle.serifBold]}>
      Our Advice
    </Text>
    <Text style={textStyle.largeText}>
      {'\n'}Walkthrough is a{' '}
      <Anchor
        text={'Registered Investment Advisor with the SEC'}
        href={'https://adviserinfo.sec.gov/firm/summary/318172'}
      />
      . We give straightforward, bread-and-butter financial advice — things like
      whether to get your 401(k) match before paying off your credit card debt
      and when it makes more sense to invest in a Roth IRA instead of a regular
      brokerage account. All our advice is backed by broad, publicly available
      research and well-understood math. No hedge fund secret sauce here.
      {'\n\n'}
      The point is that we give the advice you need, at the right time, in a way
      folks actually understand. Then we help you act on it.{'\n\n'}
    </Text>
    <NavigationButton
      text={'Read more'}
      handlePress={(nav) => {
        nav.navigate('OurAdviceScreen')
      }}
      buttonStyle={[buttonStyle(THEME.color.outline).underlineButton]}
    />
  </>
)

const adviceCopy = (
  <>
    <Text style={textStyle.largeText}>
      <Text style={textStyle.boldText}>
        Walkthrough gives straightforward, bread-and-butter financial advice
      </Text>
      — things like whether to get your 401(k) match before paying off your
      credit card debt and when it makes more sense to invest in a Roth IRA
      instead of a regular brokerage account. All our advice is backed by broad,
      publicly available research and well-understood math.
      {'\n\n'}
      You could teach yourself all this stuff if you wanted to. Actually, you{' '}
      <Text style={textStyle.italicText}>have </Text>
      to learn some of the basics — advisors who say "it's too complicated, I'll
      take care of everything" teach you to not question them or empower
      yourself.{' '}
      <Text style={textStyle.boldText}>
        The problem is that learning personal finance alone is hard and
        annoying.
      </Text>{' '}
      You have to read a bunch of research papers, then maybe some blogs
      explaining the papers (during which you'll find a LOT of blogs selling
      crappy financial products and generally not looking out for your
      interests), then figure out how it all applies to your situation. Oh, and
      make sure you don't miss any details, misunderstand anything, or
      procrastinate on reading the new laws that affect you (student debt
      forgiveness? SECURE 2.0?).{'\n\n'}
      Walkthrough's financial and technical team actually{' '}
      <Text style={textStyle.italicText}>likes</Text> doing this, because we're
      founded by Yale nerds with deep software and finance backgrounds. But we
      realize most people don't, which is the point.{' '}
      <Text style={textStyle.boldText}>
        We've built our products specifically to:
      </Text>
      {'\n\n'}
      <Text style={textStyle.boldText}>
        {'1) '}Apply all this publicly known research to your situation to give
        you personalized advice.{'\n'}
      </Text>
      <Text style={textStyle.boldText}>
        {'2) '}Help you learn the basics you need to know without wasting time
        on the wrong topic or the wrong article.
      </Text>{' '}
      We do this even if we didn't create the best article out there — we always
      point you to the best resources we know of.{'\n'}
      <Text style={textStyle.boldText}>
        {'3) '}Give you step-by-step instructions to take action.{'\n'}
      </Text>
      <Text style={textStyle.boldText}>
        {'4) '}Make all this mess a little less anxiety-inducing and a little
        more fun ❤️{'\n\n'}
      </Text>
      <Text style={textStyle.italicText}>
        Note: if you're still skeptical, that's great! We don't want you to just
        trust us. We want to help you learn enough to *know* our advice is good,
        and we've specifically structured the company to make this possible
        (read more about{' '}
        <NavigationAnchor
          text={'our incentives'}
          handlePress={(nav) => {
            nav.navigate('IncentivesScreen')
          }}
        />
        ).
      </Text>
    </Text>
  </>
)

export function OurAdviceScreen (): JSX.Element {
  return <ArticlePage>{adviceCopy}</ArticlePage>
}
