import { isNonNullish } from 'global-utils'

// Returns the given param if it is valid, otherwise returns 'anomalousUtmParam'.
// This will allow us to know if we are getting invalid utm params in our analytics.
function getValidUtmParam (param: string): string {
  // This regular expression matches any string that contains only uppercase and lowercase
  // alphabet characters (a-z, A-Z) and digits (0-9), with a minimum length of 1.
  const regex = /^[a-zA-Z0-9]+$/
  return regex.test(param) ? param : 'anomalousUtmParam'
}

// Appends whichever utm params are non-nullish to the given link.
// If utm_source is nullish, defaults to 'walkthrough'.
export function appendUtmParamsToLink (
  link: string,
  utmSource?: string,
  utmCampaign?: string,
  utmContent?: string,
  utmMedium?: string,
  utmTerm?: string
): string {
  let linkWithUtmParams = link
  // Note that we always append a '?' to the link, since we append a default utm_source param,
  // even if all the params are nullish.
  if (isNonNullish(utmSource)) {
    linkWithUtmParams = `${linkWithUtmParams}?utm_source=${getValidUtmParam(
      utmSource
    )}`
  } else {
    // Default to 'walkthrough' if utm_source is nullish.
    linkWithUtmParams = `${linkWithUtmParams}?utm_source=walkthrough`
  }
  if (isNonNullish(utmCampaign)) {
    linkWithUtmParams = `${linkWithUtmParams}&utm_campaign=${getValidUtmParam(
      utmCampaign
    )}`
  }
  if (isNonNullish(utmContent)) {
    linkWithUtmParams = `${linkWithUtmParams}&utm_content=${getValidUtmParam(
      utmContent
    )}`
  }
  if (isNonNullish(utmMedium)) {
    linkWithUtmParams = `${linkWithUtmParams}&utm_medium=${getValidUtmParam(
      utmMedium
    )}`
  }
  if (isNonNullish(utmTerm)) {
    linkWithUtmParams = `${linkWithUtmParams}&utm_term=${getValidUtmParam(
      utmTerm
    )}`
  }
  return linkWithUtmParams
}
