import { StyleSheet, ViewStyle } from 'react-native'
import { THEME } from '../../constants'

export const navigationMenuButtonStyle = (): {
  selectedIconAndButtonContainer: ViewStyle
  selectedIconContainer: ViewStyle
} =>
  StyleSheet.create({
    selectedIconAndButtonContainer: {
      flexDirection: 'row-reverse',
      alignItems: 'center',
      marginBottom: THEME.spacing.marginSmall
    },
    selectedIconContainer: {
      paddingLeft: THEME.spacing.marginSmall,
      paddingRight: 0
    }
  })
