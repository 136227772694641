import { ImageStyle, StyleSheet, ViewStyle } from 'react-native'
import { MAX_WIDTH_FOR_MOBILE_DESIGN, THEME } from '../../constants'

const HEIGHT_OF_LOGO_AND_MENU_ICON = 38

export const financialPlanScreenStyle = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: THEME.color.background
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    bottom: 0,
    zIndex: 0
  },
  textContainer: {
    marginBottom: THEME.spacing.marginMedium,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 10000,
    borderBottomLeftRadius: 10000,
    borderBottomRightRadius: 5,
    backgroundColor: THEME.color.surfaceOne
  },
  expandableCard: {
    marginTop: THEME.spacing.marginMedium
  },
  mobileContainer: {
    top: THEME.spacing.marginMedium + HEIGHT_OF_LOGO_AND_MENU_ICON,
    padding: THEME.spacing.marginMedium
  },
  desktopContainer: {
    flexDirection: 'row',
    height: '100%',
    paddingVertical: THEME.spacing.marginMedium,
    paddingLeft: THEME.spacing.marginLarge,
    // No right padding so the scroll bar inside this container is on the far right.
    // Right padding is handled by the desktopContentContainer.
    paddingTop: THEME.spacing.marginMedium * 2 + HEIGHT_OF_LOGO_AND_MENU_ICON
  },
  desktopContentContainer: {
    paddingHorizontal: THEME.spacing.marginLarge,
    paddingBottom: THEME.spacing.marginLarge,
    maxWidth: 800
  },
  buttonText: {
    color: THEME.color.background
  },
  button: {
    width: 'fit-content',
    marginBottom: THEME.spacing.marginMedium
  }
})

export const financialPlanWidthDependentStyle = (
  useMobileDesign: boolean
): {
  articleBackground: ViewStyle
  header: ViewStyle
} =>
  StyleSheet.create({
    articleBackground: {
      width: useMobileDesign ? 'fit-content' : '55%',
      minWidth: useMobileDesign
        ? 0
        : MAX_WIDTH_FOR_MOBILE_DESIGN - THEME.spacing.marginMedium * 2,
      alignSelf: 'center',
      zIndex: 0,
      borderWidth: 2,
      borderColor: THEME.color.outline,
      borderRadius: 20,
      backgroundColor: THEME.color.mountainColorTheme.sky,
      padding: THEME.spacing.marginMedium,
      marginHorizontal: THEME.spacing.marginMedium,
      marginTop: THEME.spacing.marginMedium
    },
    header: {
      width: '100%',
      backgroundColor: THEME.color.background,
      paddingVertical: THEME.spacing.marginMedium,
      paddingHorizontal: useMobileDesign
        ? THEME.spacing.marginMedium
        : THEME.spacing.marginLarge,
      position: 'absolute',
      top: 0,
      zIndex: 3,
      flexDirection: useMobileDesign ? 'column' : 'row',
      justifyContent: 'space-between'
    }
  })

export const imageSizeStyle = (
  width: number,
  // This is the width:height aspect ratio as a fraction: width/height.
  // This is used to scale the height given the width.
  aspectRatio: number
): {
  image: ImageStyle
} =>
  StyleSheet.create({
    image: {
      resizeMode: 'contain',
      alignItems: 'center',
      width: width,
      height: width / aspectRatio
    }
  })
