import { StyleSheet, ViewStyle } from 'react-native'
import { THEME } from '../../constants'

export const contentImagePairStyle = (
  useTwoColumns: boolean,
  color?: string
): {
  contentAndImageContainer: ViewStyle
  contentContainer: ViewStyle
  imageContainer: ViewStyle
  accent: ViewStyle
} =>
  StyleSheet.create({
    contentAndImageContainer: {
      flexDirection: useTwoColumns ? 'row' : 'column-reverse',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      backgroundColor: color ?? THEME.color.background,
      paddingHorizontal: useTwoColumns
        ? THEME.spacing.marginLarge
        : THEME.spacing.marginMedium,
      paddingTop: useTwoColumns
        ? THEME.spacing.marginLarge
        : 2 * THEME.spacing.marginMedium,
      paddingBottom: useTwoColumns
        ? 2 * THEME.spacing.marginLarge
        : 2 * THEME.spacing.marginMedium
    },
    contentContainer: {
      width: useTwoColumns ? '55%' : '100%',
      height: 'fit-content',
      padding: THEME.spacing.marginMedium,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 10000,
      borderBottomLeftRadius: 10000,
      borderBottomRightRadius: 5,
      backgroundColor: THEME.color.surfaceOne
    },
    imageContainer: {
      width: useTwoColumns ? '45%' : '100%',
      height: 'fit-content',
      marginLeft: useTwoColumns ? THEME.spacing.marginMedium : 0,
      marginBottom: useTwoColumns ? 0 : THEME.spacing.marginMedium,
      alignItems: 'center',
      justifyContent: 'center'
    },
    accent: {
      width: '35%',
      height: 200,
      alignSelf: 'flex-end',
      marginLeft: THEME.spacing.marginLarge,
      borderTopLeftRadius: 500,
      borderTopRightRadius: 5,
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 500,
      backgroundColor: THEME.color.surfaceOne
    }
  })
