import * as React from "react"
import Svg, { SvgProps, Circle, Path } from "react-native-svg"

const MenuIcon = (props: SvgProps): JSX.Element => (
  <Svg
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <Path
      d="M13 13h14M13 20h14M13 27h14"
      stroke="#202232"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </Svg>
)

export default MenuIcon
