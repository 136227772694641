import { StyleSheet, ViewStyle } from 'react-native'
import { THEME } from '../constants'

export const euphoriaStyle = StyleSheet.create({
  title: {
    color: '#fff',
    textAlign: 'center'
  },
  text: {
    color: '#fff'
  },
  titleContainer: {
    width: '100%',
    marginBottom: THEME.spacing.marginMedium
  },
  logoContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center'
  },
  expandableCard: {
    marginTop: THEME.spacing.marginMedium
  }
})

export const screenWidthSpecificStyle = (
  useMobileDesign: boolean
): {
  outerContainer: ViewStyle
  contentContainer: ViewStyle
  scrollView: ViewStyle
} =>
  StyleSheet.create({
    outerContainer: {
      width: '100%',
      height: '100%',
      backgroundColor: '#13151A',
      alignItems: 'center'
    },
    contentContainer: {
      width: useMobileDesign ? '100%' : '70%',
      marginTop: THEME.spacing.marginLarge
    },
    scrollView: {
      alignItems: 'center',
      padding: useMobileDesign
        ? THEME.spacing.marginMedium
        : THEME.spacing.marginLarge
    }
  })
