import React from 'react'
import { Text } from 'react-native'
import { THEME } from '../../../constants'
import { buttonStyle, textStyle } from '../../../themes/global-styles.style'
import { Anchor } from '../../../util/anchor'
import {
  NavigationAnchor,
  NavigationButton
} from '../../../util/navigation-anchor'
import { ArticlePage } from '../article-page'
import { whyFreePlansCopy } from './financial-plan'

export const monetizationShortCopy = (
  <>
    <Text style={[textStyle.extraLargeText, textStyle.serifBold]}>
      How We Make Money
    </Text>
    <Text style={textStyle.largeText}>
      {'\n'}Usually, a Walkthrough financial plan costs $100 (and includes free
      access to the app for a year!). The Walkthrough app costs $10 per month.
      You paying us is our only source of income.{' '}
      <Text style={textStyle.boldText}>
        This is one of the best things about Walkthrough because it aligns our
        incentives with yours.
      </Text>
      {'\n\n'}
    </Text>
    <NavigationButton
      text={'Read more'}
      handlePress={(nav) => {
        nav.navigate('MonetizationScreen')
      }}
      buttonStyle={[buttonStyle(THEME.color.outline).underlineButton]}
    />
  </>
)

const monetizationCopy = (
  <>
    <Text style={textStyle.largeText}>
      <Text style={textStyle.italicBoldText}>
        ***
        {'\n\n'}
        Why is Walkthrough giving away financial plans?
        {'\n'}
      </Text>
      {whyFreePlansCopy}
      <Text style={textStyle.italicBoldText}>
        {'\n\n'}
        ***
        {'\n\n'}
      </Text>
      <Text style={textStyle.italicText}>
        We thought about this decision a lot. If you think we're going about
        this wrong, let us know! We'd love to discuss. Send us an email at{' '}
        <Anchor
          href="mailto:monetization@walkthrough.co"
          text="monetization@walkthrough.co"
        />
        .
      </Text>
      {'\n\n'}Eventually, we expect to charge around $100 for our financial
      plans and $10 per month for our app. You paying us will be our only source
      of income.{' '}
      <Text style={textStyle.boldText}>
        This is one of the best things about Walkthrough.
      </Text>
      {'\n\n'}If you don't like this, you should. Here's why:
      {'\n\n'}
      <Text style={textStyle.boldText}>$100 isn't too much:</Text>
      {'\n'}Financial planners and advisors are expensive, because an expert’s
      time is expensive. Unfortunately, this means that it’s mostly well-off
      folks who can afford their fees. What are the other 70% of us supposed to
      do? Don’t worry, that’s where Walkthrough comes in. Our fees of $10 a
      month or $100 a year is the right way for us to 1) be an actual company
      that can pay its employees and 2) provide excellent financial advice at a
      rate most anyone can afford. If you think you can’t afford this, please be
      in touch at{' '}
      <Anchor
        href="mailto:support@walkthrough.co"
        text="support@walkthrough.co"
      />
      , and we’ll help you out.
      {'\n\n'}
      <Text style={textStyle.boldText}>$100 isn't too little:</Text>
      {'\n'}“Free” financial advising services:
      {'\n'}• mine your data
      {'\n'}• make their apps addictive so they can advertise more products to
      you
      {'\n'}• try to sign you up for their own products which charge fees and
      might not be best in class
      {'\n'}… and do pretty much anything to make a buck. This can be true even
      if they are a fiduciary (we're one too, but we go above and beyond that -
      see our{' '}
      <NavigationAnchor
        text="incentives page"
        handlePress={(nav) => {
          nav.navigate('IncentivesScreen')
        }}
      />
      ). We think all of this is unethical and fundamentally against your
      interest.
      {'\n\n'}
      <Text style={textStyle.boldText}>It's clear and aligned:</Text>
      {'\n'}• We're upfront about what we charge you — $100 for a financial plan
      or $10 each month for the app!
      {'\n'}• You know exactly all the ways we make money.
      {'\n'}• We bill you directly so you can see the charge.
      {'\n'}• Our fee doesn't change depending on the advice we give, which
      means we aren't biased against giving you some types of advice.
      {'\n\n'}The bottom line:
      {'\n'}
      <Text style={textStyle.boldText}>
        Financial literacy and empowerment is valuable. It's not nearly as
        expensive as the world seems to think, but it's not free, either.
        {'\n\n'}
        You want to pay for your own financial advice.{'\n\n'}
      </Text>
      {whyFreePlansCopy}
    </Text>
  </>
)

export function MonetizationScreen (): JSX.Element {
  return <ArticlePage>{monetizationCopy}</ArticlePage>
}
