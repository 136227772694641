import React from 'react'
import { Text } from 'react-native'
import { textStyle } from '../../../themes/global-styles.style'
import { NavigationAnchor } from '../../../util/navigation-anchor'
import { isMobile } from 'react-device-detect'

export const textWalkthroughShortCopy = (
  <>
    <Text style={[textStyle.extraLargeText, textStyle.serifBold]}>
      Ask a Question
    </Text>
    <Text style={textStyle.largeText}>
      {'\n'}You can text Walkthrough for financial advice! Right now, it’s{' '}
      <Text style={textStyle.boldText}>free</Text>!{'\n\n'}Text us at{' '}
      <Text style={textStyle.boldText}>
        {isMobile ? (
          <NavigationAnchor
            text={'(628) 333-3452'}
            handlePress={(nav) => {
              nav.navigate('TextWalkthroughScreen')
            }}
          />
        ) : (
          <Text>(628) 333-3452</Text>
        )}
      </Text>{' '}
      to get expert answers to any personal finance questions you have, like:
      {'\n'}• Which options should I pick for my 401(k) at my new job?
      {'\n'}• Which debt should I start paying off first?
      {'\n'}• Do I have too much money in my savings account?
      {'\n'}• How do I start investing?
      {'\n'}• Does it make sense for me to buy a home?
      {'\n\n'}Your entire conversation is confidential.
    </Text>
  </>
)
