import React from 'react'
import { Text } from 'react-native'
import { textStyle } from '../../../themes/global-styles.style'
import { Anchor } from '../../../util/anchor'
import { NavigationAnchor } from '../../../util/navigation-anchor'
import { ArticlePage } from '../article-page'

const securityPolicyCopy = (
  <>
    <Text style={textStyle.regularText}>
      <Text style={textStyle.boldText}>Last Updated:</Text> Mar 07, 2023
      {'\n\n'}We understand that by using Walkthrough, you are trusting us with
      your data. That's why we treat your personal and financial data like we'd
      want ours to be treated. Below, you'll find the principles that guide our
      approach to privacy and security.
      {'\n\n'}
      <Text style={textStyle.boldText}>Access</Text>
      {'\n'}We believe that you should have access to and control over your
      data.
      {'\n\n'}Your personal Walkthrough financial account data, such as budgets
      and transactions, is only accessed by the Walkthrough team when necessary
      to provide the Walkthrough services, like when you request support for a
      data issue. We use aggregated and anonymized data for internal analytics
      and business purposes – you can read our{' '}
      <NavigationAnchor
        text="Privacy Policy"
        handlePress={(nav) => {
          nav.navigate('PrivacyPolicyScreen')
        }}
      />{' '}
      for more information.
      {'\n\n'}We employ a number of security measures to help keep your data
      safe, including 256-bit encryption to protect it at rest and Transport
      Layer Security (TLS) to protect it in transit. In other words, your data
      is encrypted while it is being stored and while interacting with our
      servers. And we do not see or store your bank login credentials because we
      partner with trusted data aggregators, like Yodlee and MX, to connect to
      your financial institutions. Walkthrough works with third party vendors
      who adhere to industry security standards. You can read more about{' '}
      <Anchor
        href="https://www.yodlee.com/legal/yodlee-security"
        text="Yodlee's security policy"
      />{' '}
      and{' '}
      <Anchor
        href="https://www.mx.com/whitepapers/security-how-mx-protects-your-data/"
        text="MX's security policy"
      />{' '}
      on their websites or by following the links above.
      {'\n\n'}
      <Text style={textStyle.boldText}>Retention</Text>
      {'\n'}You can delete your Walkthrough account at any time by sending a
      request to{' '}
      <Anchor
        href="mailto:support@walkthrough.co"
        text="support@walkthrough.co"
      />
      . If you delete your account, we do not keep any of your linked financial
      data or Walkthrough account data (email address, budgets, etc.), except in
      the limited circumstances where required by law, to resolve disputes,
      protect Walkthrough and our users, and enforce our agreements. Where
      Walkthrough has no such obligations, the data will be completely removed
      from all our internal systems, including backups, within 90 days.
      {'\n\n'}Deleting your Walkthrough account and canceling your app
      subscription are two separate actions, as subscriptions are potentially
      managed by Apple or Google depending on how you subscribed.
      {'\n\n'}You can cancel your app subscription from Settings prior to
      deleting your account. Go to Walkthrough Settings → Subscription → Manage
      your Subscription and you'll be taken to the appropriate route to complete
      the cancellation. If you forget to cancel your subscription before
      deleting your account, you can still do so by going to the respective App
      Store's Settings → Purchases → Subscriptions → Walkthrough. We will not
      charge you any fees after you delete your account.
      {'\n\n'}
      <Text style={textStyle.boldText}>Infrastructure</Text>
      {'\n'}Walkthrough's infrastructure is built on the Amazon Web Services
      Platform (AWS), which is used by leading financial companies worldwide.
      AWS adheres to industry standard security, privacy and compliance
      controls, including:
      {'\n\n'}ISO/IEC 27001, 27017 and 27018
      {'\n'}SOC 1/2/3
      {'\n'}PCI DSS
      {'\n'}CSA STAR
      {'\n\n'}
      <Text style={textStyle.boldText}>
        What you do in Walkthrough, stays in Walkthrough
      </Text>
      {'\n'}Our only focus is on building tools that help you improve your
      finances. We respect your privacy, so we give you transparency and control
      over your data and keep it private. We don't like it when we start seeing
      online ads for things we recently bought, so we do not sell your personal
      data to third parties so that they can advertise products to you, or with
      anyone except our carefully vetted service providers (who use it to power
      Walkthrough).
      {'\n\n'}
      <Text style={textStyle.boldText}>Transparency above all else</Text>
      {'\n'}Our Privacy Policy comprehensively details our data practices, but
      we understand that legal documents aren't everyone's favorite thing to
      read. That's why we have this policy to provide you with a straightforward
      summary of how we think about your data.
      {'\n\n'}We'll keep this page up-to-date and let you know if anything big
      changes with our practices. We're also available if you have any questions
      or concerns. You can always contact us at{' '}
      <Anchor
        href="mailto:support@walkthrough.co"
        text="support@walkthrough.co"
      />
      .
    </Text>
  </>
)

export function SecurityPolicyScreen (): JSX.Element {
  return <ArticlePage>{securityPolicyCopy}</ArticlePage>
}
