import { StyleSheet, ViewStyle } from 'react-native'
import { THEME } from '../../../constants'
import { textStyle } from '../../../themes/global-styles.style'

export const welcomeStyle = StyleSheet.create({
  subtitle: {
    paddingTop: THEME.spacing.marginSmall
  },
  buttonText: {
    ...textStyle.largeText,
    ...textStyle.boldText,
    color: THEME.color.background
  }
})

export const widthDependentWelcomeStyle = (
  useMobileDesign: boolean
): {
  container: ViewStyle
  buttonContainer: ViewStyle
  buttonOne: ViewStyle
  buttonTwo: ViewStyle
  buttonThree: ViewStyle
  plantImageContainer: ViewStyle
} =>
  StyleSheet.create({
    container: {
      width: '100%',
      padding: useMobileDesign
        ? THEME.spacing.marginMedium
        : THEME.spacing.marginLarge,
      paddingTop: THEME.spacing.marginMedium * 2,
      alignItems: 'center',
      textAlign: 'center'
    },
    buttonContainer: {
      flexDirection: useMobileDesign ? 'column' : 'row'
    },
    buttonOne: {
      marginRight: useMobileDesign ? 0 : THEME.spacing.marginMedium,
      marginTop: THEME.spacing.marginMedium
    },
    buttonTwo: {
      marginRight: useMobileDesign ? 0 : THEME.spacing.marginMedium,
      marginTop: useMobileDesign
        ? THEME.spacing.marginSmall
        : THEME.spacing.marginMedium
    },
    buttonThree: {
      marginTop: useMobileDesign
        ? THEME.spacing.marginSmall
        : THEME.spacing.marginMedium
    },
    plantImageContainer: {
      width: useMobileDesign ? '70%' : 300,
      marginTop: THEME.spacing.marginMedium
    }
  })
