import { StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { THEME } from '../constants'

export const bannerScreenWidthStyle = (
  useMobileDesign: boolean
): {
  banner: ViewStyle
  bannerText: TextStyle
} =>
  StyleSheet.create({
    banner: {
      width: '100%',
      paddingVertical: THEME.spacing.marginMedium,
      paddingHorizontal: useMobileDesign
        ? THEME.spacing.marginMedium
        : THEME.spacing.marginLarge,
      backgroundColor: THEME.color.outline
    },
    bannerText: {
      color: '#fff',
      textAlign: useMobileDesign ? 'left' : 'center'
    }
  })
