import React from 'react'
import {
  useWindowDimensions,
  View,
  Text,
  Pressable,
  Image,
  ScrollView
} from 'react-native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { THEME, WAITLIST_FOR_FREE_FINANCIAL_PLAN } from '../../constants'
import { shouldUseMobileDesign } from '../../util/use-mobile-design'
import { AnchorButton, navigateToHref } from '../../util/anchor'
import {
  financialPlanScreenStyle,
  financialPlanWidthDependentStyle,
  imageSizeStyle
} from './financial-plan.style'
import OneLineLogo from '../../assets/one-line-logo'
import { buttonStyle, textStyle } from '../../themes/global-styles.style'
import { ExpandableCard } from '../../util/expandable-card'
import {
  financialPlanIntroCopy,
  whatShouldIExpectCopy,
  whatIsIncludedInPlanCopy,
  whyTrustWalkthroughCopy,
  whyFreePlansCopy
} from './content/financial-plan'
import { ArticleKey } from '../main'
import { ParamListBase, useNavigation } from '@react-navigation/native'
import { isNonNullish } from 'global-utils'

export function FinancialPlanScreen (props: {
  route: {
    params: {
      // NOTE: utm_ params are snake case because Plausible, our analytics tool, expects snake case &
      // will automatically track utm params. This is the standard format for utm params.
      // See https://plausible.io/docs/custom-dimensions#utm-parameters.
      // Since React navigation automatically parses query params to get the params for a screen, we
      // keep the screen params in snake case as well.
      // See https://reactnavigation.org/docs/configuring-links/#passing-params.
      utm_source?: string
      utm_campaign?: string
    }
  }
}): JSX.Element {
  const navigation =
    useNavigation<NativeStackNavigationProp<ParamListBase, string>>()

  const windowWidth = useWindowDimensions().width
  const useMobileDesign = shouldUseMobileDesign(windowWidth)

  const screenWidthStyle = financialPlanWidthDependentStyle(useMobileDesign)

  let utmSource, utmCampaign: string | undefined
  if (
    isNonNullish(props.route.params) &&
    isNonNullish(props.route.params.utm_source) &&
    isNonNullish(props.route.params.utm_campaign)
  ) {
    utmSource = props.route.params.utm_source
    utmCampaign = props.route.params.utm_campaign
  }

  return (
    <>
      <View style={financialPlanScreenStyle.container}>
        <View style={screenWidthStyle.header}>
          <Pressable
            onPress={() => {
              navigation.navigate('MainScreen', {
                articleKey: ArticleKey.WELCOME
              })
            }}
          >
            <OneLineLogo />
          </Pressable>
        </View>
        {useMobileDesign ? (
          <ScrollView>
            <View style={financialPlanScreenStyle.mobileContainer}>
              <FinancialPlanIntro />
              <OrderNowButton setSurveyIsOpen={setSurveyIsOpen} />
              <FinancialPlanImage
                windowWidth={windowWidth}
                useMobileDesign={useMobileDesign}
              />
              <FinancialPlanExpandableCards
                setSurveyIsOpen={setSurveyIsOpen}
                utmSource={utmSource}
                utmCampaign={utmCampaign}
              />
            </View>
          </ScrollView>
        ) : (
          <View style={financialPlanScreenStyle.desktopContainer}>
            <FinancialPlanImage
              windowWidth={windowWidth}
              useMobileDesign={useMobileDesign}
            />
            <ScrollView>
              <View style={financialPlanScreenStyle.desktopContentContainer}>
                <FinancialPlanIntro />
                <AnchorButton
                  href={WAITLIST_FOR_FREE_FINANCIAL_PLAN}
                  text={'Get a plan'}
                  buttonStyle={[buttonStyle(THEME.color.onSurface).smallButton]}
                  textStyle={[
                    textStyle.largeText,
                    textStyle.boldText,
                    { color: THEME.color.background }
                  ]}
                />
                <FinancialPlanExpandableCards
                  utmSource={utmSource}
                  utmCampaign={utmCampaign}
                />
              </View>
            </ScrollView>
          </View>
        )}
      </View>
    </>
  )
}

function OrderNowButton (props: {
  setSurveyIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}): JSX.Element {
  return (
    <Pressable
      style={[
        buttonStyle(THEME.color.outline).inlineButton,
        financialPlanScreenStyle.button
      ]}
      onPress={() => {
        props.setSurveyIsOpen(true)
      }}
    >
      <Text
        style={[
          textStyle.largeText,
          textStyle.boldText,
          financialPlanScreenStyle.buttonText
        ]}
      >
        Get a plan
      </Text>
    </Pressable>
  )
}

function FinancialPlanIntro (): JSX.Element {
  return (
    <View style={financialPlanScreenStyle.textContainer}>
      <Text style={[textStyle.extraLargeText, textStyle.serifBold]}>
        Reach your financial goals with a plan from Walkthrough
      </Text>
      <Text style={[textStyle.regularText]}>
        {'\n'}
        {financialPlanIntroCopy}
      </Text>
    </View>
  )
}

function FinancialPlanExpandableCards (props: {
  utmSource?: string
  utmCampaign?: string
}): JSX.Element {
  return (
    <View>
      <ExpandableCard
        title={'What’s in a Walkthrough financial plan?'}
        content={
          <Text style={[textStyle.regularText]}>
            {whatIsIncludedInPlanCopy}
          </Text>
        }
        style={[financialPlanScreenStyle.expandableCard]}
      />
      <ExpandableCard
        title={'Why is Walkthrough giving away financial plans?'}
        content={
          <Text style={[textStyle.regularText]}>{whyFreePlansCopy}</Text>
        }
        style={[financialPlanScreenStyle.expandableCard]}
      />
      <ExpandableCard
        title={'Why trust Walkthrough?'}
        content={
          <Text style={[textStyle.regularText]}>{whyTrustWalkthroughCopy}</Text>
        }
        style={[financialPlanScreenStyle.expandableCard]}
      />
      <ExpandableCard
        title={'What should I expect if I’m ready to get a plan?'}
        content={
          <Text style={[textStyle.regularText]}>
            {whatShouldIExpectCopy}
          </Text>
        }
        style={[financialPlanScreenStyle.expandableCard]}
      />
    </View>
  )
}

// Image of a Walkthrough financial plan cover. On press links to the example financial plan.
function FinancialPlanImage (props: {
  windowWidth: number
  useMobileDesign: boolean
}): JSX.Element {
  return (
    <Pressable
      onPress={() =>
        navigateToHref('https://docsend.com/view/g4uw5kwgxfqwfrtf')
      }
    >
      <Image
        source={require('../../assets/financial-plan-cover.png')}
        // On mobile, we fill the available screen width (minus margins).
        // On desktop, we use a 400px width.
        // The aspect ratio of this particular photo is 0.647 (determined from the width &
        // height of the image).
        style={
          imageSizeStyle(
            props.useMobileDesign
              ? props.windowWidth - 2 * THEME.spacing.marginMedium
              : 380,
            /* aspect ratio = */ 0.647
          ).image
        }
      />
    </Pressable>
  )
}
